import { Method, send } from './MyHttp';

/**
 * 정보
 */
export const Get = (device_no, date, success, fail) => {
    let paramsT = {};

    send(Method.GET, `/deviceData/${device_no}/${date}`, paramsT, success, fail);
};

/**
 * 리스트
 */
export const GetList = (params, success, fail) => {
    let paramsT = { ...params };

    send(Method.GET, '/deviceData', paramsT, success, fail);
};

/**
 * 리스트 (마지막 데이터)
 */
export const GetListLast = (params, success, fail) => {
    let paramsT = { ...params };

    send(Method.GET, '/deviceData/last', paramsT, success, fail);
};
