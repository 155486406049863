import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Get, Delete } from '../lib/User';
import { formatPhoneNumber } from '../lib/Common';

const Popup = props => {
    const { t } = useTranslation();

    const [data, setData] = useState({});

    useEffect(() => {
        Get(props.member_no, res => {
            res.guard_phone = res.guard_phone.split(',')[0];
            setData(res);
        });
    }, [props.member_no]);

    return (
        <section className="popup_wrap warning_red_pop show">
            <div className="pop_base">
                <button type="button" className="btn_close" onClick={props.onClose}>
                    {t('닫기')}
                </button>
                <div className="icons alert"></div>
                <div className="in_box">
                    <p className="alert_tit">{t('비상')}</p>
                    <p className="tit">{t('이상 생체신호를 확인했습니다.')}</p>
                    <ul className="info_list">
                        <li>
                            {t('회원명')} : {data.name}
                        </li>
                        <li>
                            {t('연락처')} : {formatPhoneNumber(data.phone)}
                        </li>
                        <li>
                            {t('보호자 연락처')} : {formatPhoneNumber(data.guard_phone)}
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Popup;
