import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthToken, AuthLogin } from '../lib/MyHttp';

const Login = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const [saveId, setSaveId] = useState(false);
    const [savePwd, setSavePwd] = useState(false);

    const [ID, setID] = useState('');
    const [pwd, setPwd] = useState('');

    useEffect(() => {
        let token = searchParams.get('token');
        if (!!token) {
            localStorage.setItem('fb_token', token);
        }
    }, [searchParams]);

    useEffect(() => {
        loadInfo();
    }, []);

    const loadInfo = () => {
        const saveId = localStorage.getItem('saveId');
        const savePwd = localStorage.getItem('savePwd');

        if (saveId === 'true') {
            setSaveId(saveId);
            setID(localStorage.getItem('inputId'));
        }
        if (savePwd === 'true') {
            setSavePwd(savePwd);
            setPwd(localStorage.getItem('inputPwd'));
        }
    };

    const clickLogin = () => {
        localStorage.setItem('saveId', saveId ? 'true' : 'false');
        localStorage.setItem('savePwd', savePwd ? 'true' : 'false');
        localStorage.setItem('inputId', saveId ? ID : '');
        localStorage.setItem('inputPwd', savePwd ? pwd : '');

        AuthLogin(
            ID,
            pwd,
            res => {
                window.location.href = '/';
            },
            res => {
                alert(t('아이디 및 패스워드를 확인해주세요.'));
            },
        );
    };

    return (
        <main className="login_contents">
            <section className="login-wrapper">
                <div className="inner2">
                    <h2 className="logo">{t('효심 관리자 센터')}</h2>
                    <h3>
                        {t('관리자 계정으로')} <br />
                        {t('효심의 모니터링 서비스를 이용하세요')}
                    </h3>
                    <div className="mid_desc">
                        <p>{t('신규관리자인가요')}?</p>
                        <Link to="/join">{t('계정만들기')}</Link>
                    </div>
                    <form>
                        <p className="row">
                            <input type="text" className="text_nor" placeholder={t('아이디')} value={ID} onChange={e => setID(e.target.value)} />
                        </p>
                        <p className="row">
                            <input type="password" className="text_nor" placeholder={t('비밀번호')} value={pwd} onChange={e => setPwd(e.target.value)} />
                        </p>
                        <div className="checks">
                            <span>
                                <input
                                    type="checkbox"
                                    id="save_id"
                                    checked={saveId}
                                    onClick={() => {
                                        setSaveId(!saveId);
                                    }}
                                />
                                <label htmlFor="save_id">{t('아이디 저장')}</label>
                            </span>
                            <span>
                                <input
                                    type="checkbox"
                                    id="save_pwd"
                                    checked={savePwd}
                                    onClick={() => {
                                        setSavePwd(!savePwd);
                                    }}
                                />
                                <label htmlFor="save_pwd">{t('비밀번호 저장')}</label>
                            </span>
                        </div>
                        <div className="btns">
                            <button type="button" className="btn_login orange" onClick={clickLogin}>
                                {t('로그인')}
                            </button>
                            <button
                                type="button"
                                className="btn_login"
                                onClick={() => {
                                    navigate('/join');
                                }}
                            >
                                {t('회원가입')}
                            </button>
                        </div>
                        <div className="link">
                            <a href="/search/id">{t('아이디 찾기')}</a>
                            <a href="/search/password">{t('비밀번호 찾기')}</a>
                        </div>
                    </form>
                </div>
            </section>
        </main>
    );
};

export default Login;
