import { Method, send } from './MyHttp';

/**
 * 리스트
 */
export const GetList = (params, success, fail) => {
    let paramsT = { ...params };

    send(Method.GET, '/group', paramsT, success, fail);
};

/**
 * 등록
 */
export const Create = (name, success, fail) => {
    const paramsT = {
        name: name,
    };

    send(Method.POST, '/group', paramsT, success, fail);
};

/**
 * 업데이트
 */
export const Update = (data, success, fail) => {
    const paramsT = {
        group_no: data.group_no,
        name: data.name,
    };

    send(Method.PUT, '/group', paramsT, success, fail);
};

/**
 * 삭제
 */
export const Delete = (ids, success, fail) => {
    let paramsT = { ids };

    send(Method.DELETE, '/group', paramsT, success, fail);
};
