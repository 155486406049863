import React, { useState, useEffect } from 'react';
import { BsPerson, BsPhone, BsEnvelope, BsPersonVcard, BsLock, BsBuilding, BsGeoAlt } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { AuthMe } from '../lib/MyHttp';
import { Update } from '../lib/User';
import { useTranslation } from 'react-i18next';
import useWarnningStore from '../stores/warnning';

const MypageView = () => {
    const { t } = useTranslation();
    const { setShow } = useWarnningStore();

    const [data, setData] = useState({});

    useEffect(() => {
        AuthMe(res => {
            setData(res);
        });
    }, []);

    return (
        <main className="admin-contents">
            <h2 className="sub_title flex">
                {t('관리자 정보')}
                <div className="rb">
                    <button type="button" className="btn_nor md">
                        <Link to="/mypage/modify">{t('수정')}</Link>
                    </button>
                </div>
            </h2>

            <div className="table_box">
                <table className="tbl_join left">
                    <caption>{t('회원정보수정')}</caption>
                    <colgroup>
                        <col width="20%" />
                        <col width="80%" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>
                                <label>
                                    <BsPerson />
                                    {t('담당자')}
                                </label>
                            </th>
                            <td>{data.name}</td>
                        </tr>
                        <tr>
                            <th>
                                <label>
                                    <BsPhone />
                                    {t('연락처')}
                                </label>
                            </th>
                            <td>{data.phone}</td>
                        </tr>
                        <tr>
                            <th>
                                <label>
                                    <BsEnvelope />
                                    {t('이메일')}
                                </label>
                            </th>
                            <td>{data.email}</td>
                        </tr>
                        <tr>
                            <th>
                                <label>
                                    <BsPersonVcard />
                                    {t('아이디')}
                                </label>
                            </th>
                            <td>{data.id}</td>
                        </tr>
                        {/* <tr>
                            <th>
                                <label>
                                    <BsLock />
                                    {t('비밀번호')}
                                </label>
                            </th>
                            <td>**********</td>
                        </tr> */}
                        <tr>
                            <th>
                                <label>
                                    <BsBuilding />
                                    {t('기관명')}
                                </label>
                            </th>
                            <td>{data.inst_nm}</td>
                        </tr>
                        <tr>
                            <th>
                                <label>
                                    <BsGeoAlt />
                                    {t('주소')}
                                </label>
                            </th>
                            <td>{((data.addr1 ? data.addr1 : '') + ' ' + (data.addr2 ? data.addr2 : '')).trim()}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <ul className="mypage_infos_list">
                <li>
                    <p className="sbj">{t('푸쉬 관리')}</p>
                    <p className="cont">
                        <p class="switch">
                            <input
                                type="checkbox"
                                name="confirm"
                                class="switch_type"
                                id="confirm_switch_1_1"
                                checked={data.on_push === 'Y'}
                                onClick={() => {
                                    data.on_push = data.on_push === 'Y' ? 'N' : 'Y';
                                    setData({ ...data });
                                    setShow(data.on_push === 'Y' ? true : false);
                                    Update(data);
                                }}
                            />
                            <label htmlFor="confirm_switch_1_1"></label>
                        </p>
                    </p>
                </li>
                <li>
                    <p className="sbj">{t('사운드 관리')}</p>
                    <p className="cont">
                        <p class="switch">
                            <input
                                type="checkbox"
                                name="confirm"
                                class="switch_type"
                                id="confirm_switch_1_2"
                                checked={data.on_sound === 'Y'}
                                onClick={() => {
                                    data.on_sound = data.on_sound === 'Y' ? 'N' : 'Y';
                                    setData({ ...data });
                                    Update(data);
                                }}
                            />
                            <label htmlFor="confirm_switch_1_2"></label>
                        </p>
                    </p>
                </li>
                <li>
                    <p className="sbj">{t('정보처리 방침')}</p>
                    <p className="cont">
                        <button type="button" className="btn_underline">
                            <Link to="/policy">{t('상세보기')}</Link>
                        </button>
                    </p>
                </li>
                <li>
                    <p className="sbj">{t('서비스 이용 약관')}</p>
                    <p className="cont">
                        <button type="button" className="btn_underline">
                            <Link to="/service">{t('상세보기')}</Link>
                        </button>
                    </p>
                </li>
            </ul>
        </main>
    );
};

export default MypageView;
