import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Get, Delete } from '../lib/Device';
import { GetList as GetListHistory, Create as CreateHistory, Delete as DeleteHistory } from '../lib/DeviceHistory';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { typeSex, calculateAge } from '../lib/Common';

const EquipmentDetail = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { id } = useParams();
    const [data, setData] = useState({});
    const [items, setItems] = useState([]);
    const [history, setHistory] = useState({
        date: moment().format('YYYY-MM-DD'),
        as: '',
        manager: '',
        memo: '',
    });

    const [historySearch, sethistorySearch] = useState({
        page: 1,
        size: '30',
        sort: '-history_no',
        device_no: id,
    });

    useEffect(() => {
        Get(id, res => {
            setData(res);
            setHistory({ ...history, manager: res.manager_name });
        });

        GetListHistory(historySearch, res => {
            setItems(res.rows);
        });
    }, [id]);

    const clickDelete = () => {
        if (window.confirm(t('삭제 하시겠습니까?'))) {
            Delete([id], res => {
                navigate('/equipment/list');
            });
        }
    };

    const clickSaveHistory = () => {
        CreateHistory(id, history, res => {
            GetListHistory(historySearch, res => {
                setItems(res.rows);

                setHistory({
                    date: moment().format('YYYY-MM-DD'),
                    as: '',
                    manager: data?.manager_name,
                    memo: '',
                });
            });
            alert(t('등록을 완료했습니다.'));
        });
    };

    const clickDeleteHistory = item => {
        DeleteHistory([item.history_no], res => {
            GetListHistory(historySearch, res => {
                setItems(res.rows);
            });
            alert(t('삭제를 완료했습니다.'));
        });
    };
    return (
        <main className="admin-contents">
            <h2 className="sub_title">{t('장비 상세보기')}</h2>

            <section className="equipment-detail-top">
                <div className="pic">
                    <img src={data.image ? data.image : 'https://picsum.photos/200/300'} alt={t('프로필')} />
                </div>
                <div className="info_box">
                    <p className="sbj">{data?.device_sn}</p>
                    <div className="data">
                        {data?.user ? (
                            <span>
                                {data.user.name} [{calculateAge(data.user.birth)}
                                {t('세')}/{t(typeSex[data.user.sex])}] {data.user.addr1} {data.user.addr2}
                            </span>
                        ) : (
                            <span>{t('없음')}</span>
                        )}
                        <span>
                            <b>{t('회원그룹')}</b> 중흥동
                        </span>
                        <span>
                            <b>{t('설치일자')}</b> {data.device_dt ? moment(data.device_dt).format('YYYY / MM / DD') : ''}
                        </span>
                        <span>
                            <b>{t('설치 담당자')}</b> {data?.manager_name}
                        </span>
                    </div>
                    <div className="btns">
                        <button type="button" className="btn_nor sm" onClick={clickDelete}>
                            {t('삭제')}
                        </button>
                        <button type="button" className="btn_nor sm">
                            <Link to={`/equipment/upload/${id}`}>{t('수정')}</Link>
                        </button>
                    </div>
                </div>
            </section>

            <h2 className="sub_title">{t('A/S 내역')}</h2>
            <div className="table_as_box">
                <table className="tbl_asList">
                    <caption>{t('A/S 내역')}</caption>
                    <colgroup>
                        <col width="12%" />
                        <col width="30%" />
                        <col width="10%" />
                        <col width="38%" />
                        <col width="10%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t('AS일자')}</th>
                            <th>{t('AS내용')}</th>
                            <th>{t('담당자')}</th>
                            <th>{t('메모')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input
                                    type="date"
                                    className="text_nor md w100"
                                    value={history.date}
                                    onChange={e => {
                                        history.date = e.target.value;
                                        setHistory({ ...history });
                                    }}
                                />
                            </td>
                            <td>
                                <input
                                    value={history.as}
                                    className="text_nor md w100"
                                    onChange={e => {
                                        history.as = e.target.value;
                                        setHistory({ ...history });
                                    }}
                                />
                            </td>
                            <td>
                                <input
                                    value={history.manager}
                                    className="text_nor md w100"
                                    onChange={e => {
                                        history.manager = e.target.value;
                                        setHistory({ ...history });
                                    }}
                                />
                            </td>
                            <td>
                                <input
                                    value={history.memo}
                                    className="text_nor md w100"
                                    onChange={e => {
                                        history.memo = e.target.value;
                                        setHistory({ ...history });
                                    }}
                                />
                            </td>
                            <td>
                                <button type="button" className="btn_nor md line" onClick={clickSaveHistory}>
                                    {t('등록')}
                                </button>
                            </td>
                        </tr>

                        {items.map(x => {
                            return (
                                <tr>
                                    <td>{moment(x.date).format('YYYY/MM/DD')}</td>
                                    <td>{x.as}</td>
                                    <td>{x.manager}</td>
                                    <td>{x.memo}</td>
                                    <td>
                                        <button
                                            type="button"
                                            className="btn_nor md line"
                                            onClick={() => {
                                                clickDeleteHistory(x);
                                            }}
                                        >
                                            {t('삭제')}
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </main>
    );
};

export default EquipmentDetail;
