import axios from 'axios';
import jwt_decode from 'jwt-decode';
import moment from 'moment';

export const Method = {
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE',
};

export const upload = (file, success, fail) => {
    const proc = async () => {
        try {
            let headers = {};
            // headers["Content-Type"] = "application/x-www-form-urlencoded";
            headers['Content-Type'] = 'multipart/form-data';

            // let access_token = localStorage.getItem('access_token');
            // if (access_token !== undefined && access_token !== '') {
            //     headers['Authorization'] = 'Bearer ' + access_token;
            // }

            let contents = {
                method: Method.POST,
                url: process.env.REACT_APP_API_URL + '/upload/file/local',
                headers: headers,
            };

            const formData = new FormData();
            formData.append('file', file);
            // formData.append("useOriginalName", false);

            contents['data'] = formData;
            const response = await axios(contents);

            CommonResult(response.data);
            if (success !== undefined) {
                success(response.data);
            }
        } catch (e) {
            console.log('e : ' + e);
            if (fail !== undefined) {
                fail(e);
            } else {
                NetworkFail(e);
            }
        }
    };

    proc();
};

/**
 * 공통 통신 처리 부분
 */
export const send = (method, url, params, success, fail, headers) => {
    const proc = async () => {
        try {
            if (headers === undefined) {
                headers = {};
                headers['Content-Type'] = 'application/json';

                let access_token = localStorage.getItem('access_token');
                if (access_token !== undefined && access_token !== '' && !url.includes('/auth/token')) {
                    let token_type = localStorage.getItem('token_type');
                    headers['Authorization'] = token_type + ' ' + access_token;
                }
                console.log('access_token', access_token);
            }

            console.log('params1', params);
            console.log('params2', JSON.stringify(params));
            console.log('url', url);

            let contents = {
                method: method,
                url: process.env.REACT_APP_API_URL + url,
                headers: headers,
            };

            if (method === 'GET') {
                contents['params'] = params;
            } else {
                contents['data'] = params;
            }

            const response = await axios(contents);

            CommonResult(response.data);
            if (success !== undefined) {
                success(response.data);
            }
        } catch (e) {
            console.log('e : ' + e);
            console.log('url', url);
            if (fail !== undefined) {
                fail(e);
            } else {
                NetworkFail(e);
            }
        }
    };

    let access_token = localStorage.getItem('access_token');
    if (access_token !== null && access_token !== '' && !url.includes('/auth/token')) {
        var decoded = jwt_decode(access_token);
        if (moment().isAfter(moment.unix(decoded.exp).subtract(30, 'minutes'))) {
            AuthToken(
                'refresh_token',
                res => {
                    proc();
                },
                res => {
                    localStorage.setItem('access_token', '');
                    localStorage.setItem('refresh_token', '');
                    window.location.href = '/';
                },
            );
        } else {
            proc();
        }
    } else {
        proc();
    }
};

const CommonResult = response => {
    if (!!response.access_token) localStorage.setItem('access_token', response.access_token);
    if (!!response.refresh_token) localStorage.setItem('refresh_token', response.refresh_token);
    if (!!response.token_type) localStorage.setItem('token_type', response.token_type);
    if (!!response.expires_in) localStorage.setItem('expires_in', response.expires_in);
};

const NetworkFail = response => {
    if (response.message !== undefined && (response.message.indexOf('code 410') !== -1 || response.message.indexOf('code 401') !== -1)) {
        localStorage.setItem('access_token', '');
        localStorage.setItem('refresh_token', '');
        localStorage.setItem('token_type', '');
        localStorage.setItem('expires_in', '');
        window.location = window.location.origin;
    } else {
        alert(response.response.data.message);
    }
};

/**
 * 토큰 받기
 */
export const AuthToken = (grant_type, success, fail) => {
    var params = {
        client_id: '21232f297a57a5a743894a0e4a801fc3',
        secret_key: '3RUDRSCEijVC7lMvsjluobGfaWt27jTbOs2nOSp9',
        grant_type: grant_type,
        refresh_token: grant_type === 'refresh_token' ? localStorage.getItem('refresh_token') : '',
    };
    if (grant_type === 'client_credentials') {
        localStorage.setItem('access_token', '');
    }

    send(Method.POST, `/auth/token`, params, success, fail);
};

/**
 * 로그인 처리
 */
export const AuthLogin = (id, password, success, fail) => {
    var params = {
        id: id,
        password: password,
    };

    send(Method.POST, '/auth/login', params, success, fail);
};

/**
 * 회원가입 처리
 */
export const AuthJoin = (data, success, fail) => {
    var params = {
        id: data.id,
        pw: data.pw,
        name: data.name,
        name_eng: data.name_eng,
        sex: data.sex,
        birth: data.birth,
        email: data.email,
        phone: data.phone1 + data.phone2 + data.phone3,
        inst_nm: data.inst_nm,
        state: data.state,
        type: data.type,
        guard_phone: data.guard_phone,
        device_sn: data.device_sn,
        medical: data.medical,
        addr1: data.addr1,
        addr2: data.addr2,
        image: data.image,
        license: data.license,
    };

    send(Method.POST, '/auth/join', params, success, fail);
};

/**
 * 로그인 아웃
 */
export const AuthLogout = (success, fail) => {
    var params = new FormData();

    send(Method.POST, '/auth/logout', params, success, fail);
};

/**
 * 내정보
 */
export const AuthMe = (success, fail) => {
    var params = new FormData();

    send(Method.GET, '/auth/info', params, success, fail);
};

/**
 * 인증번호 요청
 */
export const RequestClient = (params, success, fail) => {
    var paramsT = {
        id: params.id,
        name: params.name,
        phone: params.phone,
        email: params.email,
    };
    send(Method.POST, '/auth/request/client', paramsT, success, fail);
};

/**
 * 아이디 찾기
 */
export const RequestSearchId = (params, success, fail) => {
    send(Method.POST, '/auth/search/id', params, success, fail);
};

/**
 * 패스워드 찾기
 */
export const RequestSearchPwd = (params, success, fail) => {
    send(Method.POST, '/auth/search/password', params, success, fail);
};
