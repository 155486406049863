import React, { Suspense, useEffect, useRef, useState } from 'react';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';
import mqtt from 'mqtt';
import i18n from './stores/i18n';

import Header from './components/header';
import Footer from './components/footer';
import PopupWarnning from './popups/warnning';

import About from './pages/about';
import Main from './pages/main';

import Login from './pages/login';
import SearchPassword from './pages/search_password';
import SearchId from './pages/search_id';
import Join from './pages/join';

import EquipmentList from './pages/equipment_list';
import EquipmentUpload from './pages/equipment_upload';
import EquipmentDetail from './pages/equipment_detail';

import MonitoringDetail from './pages/monitoring_detail';
import MonitoringRealtimeList from './pages/monitoring_realtime_list';
import MonitoringRealtimeSimpleList from './pages/monitoring_realtime_simple_list';

import MemberList from './pages/member_list';
import MemberDetail from './pages/member_detail';
import MemberUpdate from './pages/member_update';

import MypageModify from './pages/mypage_modify';
import MypageView from './pages/mypage_view';

import AlarmList from './pages/alarm_list';

import Service from './pages/service';
import Policy from './pages/policy';

import useClientStore from './stores/client';
import useWarnningStore from './stores/warnning';
import useThemeStore from './stores/theme';

const AdminLayout = props => {
    const { theme } = useThemeStore();

    return (
        <div className={'admin_wrap ' + theme}>
            <Header />
            <Outlet />
        </div>
    );
};

function App() {
    const access_token = localStorage.getItem('access_token');
    const { client, setClient, onMessage } = useClientStore();
    const { popups, addPopups, removePopups } = useWarnningStore();
    const onMessageRef = useRef(onMessage);

    useEffect(() => {}, []);

    useEffect(() => {
        onMessageRef.current = onMessage;
    }, [onMessage]);

    useEffect(() => {
        const url = process.env.REACT_APP_MQTT_URL;
        const client = mqtt.connect(url);

        client.on('connect', () => {
            setClient(client);
        });
        client.on('close', function () {
            setClient(null);
        });
        client.on('reconnect', () => {
            console.log('Reconnecting');
        });

        client.on('message', (topic, message) => {
            if (onMessageRef.current[topic]) {
                onMessageRef.current[topic](topic, message);
            }
        });

        client.on("error", (error) => {
            console.error("Connection failed:", error);
        });


        setClient(client);

        return () => {
            if (client) {
                client.end(() => {
                    console.log('disconnect');
                });
            }
        };
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                {!!access_token ? (
                    <Route path="/" element={<AdminLayout />}>
                        <Route index element={<Main />} />

                        <Route path="/member/list" element={<MemberList />} />
                        <Route path="/member/detail/:id" element={<MemberDetail />} />
                        <Route path="/member/update" element={<MemberUpdate />} />
                        <Route path="/member/update/:id" element={<MemberUpdate />} />

                        <Route path="/mypage/view" element={<MypageView />} />
                        <Route path="/mypage/modify" element={<MypageModify />} />

                        <Route path="/monitoring/detail/:id" element={<MonitoringDetail />} />
                        <Route path="/monitoring/realtimelist" element={<MonitoringRealtimeList />} />
                        <Route path="/monitoring/realtimelist/:name" element={<MonitoringRealtimeList />} />
                        <Route path="/monitoring/realtimesimplelist" element={<MonitoringRealtimeSimpleList />} />

                        <Route path="/about" element={<About />} />

                        <Route path="/equipment/list" element={<EquipmentList />} />
                        <Route path="/equipment/upload" element={<EquipmentUpload />} />
                        <Route path="/equipment/upload/:id" element={<EquipmentUpload />} />
                        <Route path="/equipment/detail/:id" element={<EquipmentDetail />} />

                        <Route path="/alarm/list" element={<AlarmList />} />

                        <Route path="/service" element={<Service />} />
                        <Route path="/policy" element={<Policy />} />
                    </Route>
                ) : (
                    [
                        <Route path="/" element={<Login />} />,
                        <Route path="/login" element={<Login />} />,
                        <Route path="/search/password" element={<SearchPassword />} />,
                        <Route path="/search/id" element={<SearchId />} />,
                        <Route path="/join" element={<Join />} />,
                        <Route path="/service" element={<Service />} />,
                        <Route path="/policy" element={<Policy />} />,
                    ]
                )}
            </Routes>
            <Footer />
            {popups.map(x => (
                <PopupWarnning
                    onClose={() => {
                        removePopups(x);
                    }}
                    member_no={x}
                />
            ))}
        </BrowserRouter>
    );
}

export default App;
