import React, { useState, useEffect } from 'react';
import { graphic } from 'echarts';
import * as echarts from 'echarts';
import ECharts, { EChartsReactProps } from 'echarts-for-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as mqtt from 'mqtt/dist/mqtt.min';
import useClientStore from '../stores/client';
import alarm from '../assets/sound/beep1.mp3';
import { useTranslation } from 'react-i18next';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../css/calendar.css';
import UseIcon from '../assets/goto_sleep.gif';
import UnUseIcon from '../assets/block.gif';


import { Get, Delete } from '../lib/Device';
import { GetListLast } from '../lib/DeviceData';
import { calculateAge, checkCanPlay, gapTime, typeSex } from '../lib/Common';
import moment from 'moment';
import MonitoringDetailBottom from './monitoring_detail_bottom';
import { AuthMe } from '../lib/MyHttp';
import { deviceState } from './monitoring_realtime_cell';
import { BsList } from 'react-icons/bs';
import { AiOutlineAppstore } from 'react-icons/ai';

const emptyData = {
    tooltip: {
        show: 'false',
    },
    xAxis: {
        show: false,
        boundaryGap: false,
        data: [],
    },
    yAxis: {
        show: false,
        type: 'value',
    },
    grid: {
        top: 10,
        left: 0,
        right: 0,
        bottom: 0,
    },
    series: [
        {
            name: 'BPM',
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
                color: '#7E7E7E',
                width: 3,
            },
            data: [],
        },
    ],
};

const MonitoringDetail = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { id } = useParams();
    const { client, addOnMessage, removeOnMessage } = useClientStore();
    const [audio] = useState(new Audio(alarm));
    const [canPlay, setCanPlay] = useState(false);
    const [myInfo, setMyInfo] = useState(false);

    const [data, setData] = useState();
    // const [client, setClient] = useState();
    const [showType, setShowType] = useState('cell');

    // 심박수
    const [values1, setValues1] = useState([]);
    const [label1, setLabel1] = useState([]);
    const [value1, setValue1] = useState(0);

    // 호흡수
    const [values2, setValues2] = useState([]);
    const [label2, setLabel2] = useState([]);
    const [value2, setValue2] = useState(0);

    // 산소불포화
    const [values3, setValues3] = useState([]);
    const [label3, setLabel3] = useState([]);
    const [value3, setValue3] = useState(0);

    // 압력값
    const [values5, setValues5] = useState([]);
    const [label5, setLabel5] = useState([]);
    const [value5, setValue5] = useState(0);
    const [value6, setMotionDetected] = useState(0);

    const [values4_1, setValues4_1] = useState([]);
    const [values4_2, setValues4_2] = useState([]);
    const [values4_3, setValues4_3] = useState([]);
    const [values4_4, setValues4_4] = useState([]);
    const [values4_5, setValues4_5] = useState([]);
    const [label4, setLabel4] = useState([]);
    var tempValue = [0, 0, 0, 0, 0];

    let cTopic = '';
    const onMessage = (topic, message) => {
        if (cTopic !== topic) {
            return;
        }

        const msg = JSON.parse(message);
        if (msg.e) {
            procData(data, msg.e);
        }
    };

    useEffect(() => {
        if (data) {
            cTopic = `SmartMat/${data.device_sn}/Autowells-server/pub_device_data`;

            if (client) {
                client.subscribe(cTopic, error => {
                    if (error) {
                        console.log('Subscribe to topics error', error);
                        return;
                    } else {
                        console.log('success subscribe');
                    }
                });
            }

            addOnMessage(cTopic, onMessage);
        }
    }, [data]);

    useEffect(() => {
        loadLastData();

        checkCanPlay().then(result => {
            if (result !== false) {
                setCanPlay(true);
            }
        });
        AuthMe(res => {
            setMyInfo(res);
        });

        Get(id, res => {
            const data = res;

            setData(res);

            cTopic = `SmartMat/${data.device_sn}/Autowells-server/pub_device_data`;

            // if (client) {
            //     client.subscribe(cTopic, error => {
            //         if (error) {
            //             console.log('Subscribe to topics error', error);
            //             return;
            //         } else {
            //             console.log('success subscribe');
            //         }
            //     });
            // }

            // addOnMessage(cTopic, onMessage);
        });

        return () => {
            if (client) {
                client.unsubscribe(cTopic, error => {
                    if (error) {
                        console.log('Unsubscribe error', error);
                        return;
                    }
                });
            }

            if (audio) audio.pause();

            removeOnMessage(cTopic);
        };
    }, []);

    const loadLastData = () => {
        // GetListLast({ id: id }, res => {
        //     if (res.length > 0) {
        //         const times = res.map(x => moment(x.time).format('mm:ss'));
        //         setValues1(res.map(x => x.heart));
        //         setValues2(res.map(x => x.resp));
        //         setValues3(res.map(x => x.apnea));
        //         setLabel1(times);
        //         setLabel2(times);
        //         setLabel3(times);
        //         setValue1(res[res.length - 1].heart);
        //         setValue2(res[res.length - 1].resp);
        //         setValue3(res[res.length - 1].apnea);
        //     }
        // });
    };

    useEffect(() => {}, []);

    const procData = (data, recieveData) => {
        var heart = recieveData[0];
        var resp = recieveData[1];
        var apnea = recieveData[2];
        var state = recieveData[3];
        var fsr_sum = recieveData[4];
        var motion_detected = 0;


        if (data) {
            if(recieveData.length === 12){
                motion_detected = recieveData[11];
                // console.log("motion_detected:" + motion_detected);
                data.motion_detected = motion_detected;
                setMotionDetected(motion_detected);
            }
            if (!data.resp_range_from) {
                data.heart_range_from = 60;
                data.heart_range_to = 100;
                data.resp_range_from = 10;
                data.resp_range_to = 30;
            }

            if (!data.online) {
                data.online = true;
                data.connectTime = moment();

                const heart_ranges = data.heart_range?.split('~');
                if (heart_ranges?.length >= 2) {
                    data.heart_range_from = heart_ranges[0];
                    data.heart_range_to = heart_ranges[1];
                }

                const resp_ranges = data.resp_range?.split('~');
                if (resp_ranges?.length >= 2) {
                    data.resp_range_from = resp_ranges[0];
                    data.resp_range_to = resp_ranges[1];
                }

                setData({ ...data });
            }

            const isUse = fsr_sum >= 300;
            if (isUse) {
                if (canPlay && myInfo?.on_sound === 'Y' && audio) {
                    if (heart < data.heart_range_from || heart > data.heart_range_to || resp < data.resp_range_from || resp > data.resp_range_to) {
                        audio.loop = true;
                        audio.play();
                    } else {
                        audio.pause();
                    }
                }
            } else {
                if (canPlay && myInfo?.on_sound === 'Y' && audio) {
                    audio.pause();
                }
                heart = 0;
                resp = 0;
                apnea = 0;
                state = 0;
                // fsr_sum = 0;
            }
        }

        if(motion_detected === 1) {
            heart = 0;
            resp = 0;
            apnea = 0;
            state = 0;
        }

        data.warnning_heart = heart < data.heart_range_from || heart > data.heart_range_to;
        data.warnning_resp = resp < data.resp_range_from || resp > data.resp_range_to;
        data.recieveTime = moment();

        if (heart >= 90) tempValue[0] += 1;
        else if (heart >= 80) tempValue[1] += 1;
        else if (heart >= 70) tempValue[2] += 1;
        else if (heart >= 60) tempValue[3] += 1;
        else tempValue[4] += 1;

        const time = moment().format('mm:ss');

        const sum = tempValue[0] + tempValue[1] + tempValue[2] + tempValue[3] + tempValue[4];
        if (sum >= 10) {
            values4_1.push(tempValue[0]);
            values4_2.push(tempValue[1]);
            values4_3.push(tempValue[2]);
            values4_4.push(tempValue[3]);
            values4_5.push(tempValue[4]);
            setValues4_1([...values4_1.slice(-20)]);
            setValues4_2([...values4_2.slice(-20)]);
            setValues4_3([...values4_3.slice(-20)]);
            setValues4_4([...values4_4.slice(-20)]);
            setValues4_5([...values4_5.slice(-20)]);
            tempValue = [0, 0, 0, 0, 0];

            label4.push(time);
            setLabel4([...label4.slice(-20)]);
        }

        if (values1.length > 1) {
            const hrv = getHRV(values1.length > 1 ? value1 : undefined, heart);
            console.log('hrv', hrv);
            setValue3(hrv);
            values3.push(hrv);
            setValues3([...values3.slice(-60)]);

            label3.push(time);
            setLabel3([...label3.slice(-60)]);
        }

        setValue1(heart);
        setValue2(resp);
        setValue5(fsr_sum);
        // values1.push(heart);
        values1.push([time, heart, motion_detected]);
        values2.push(resp);
        values5.push(fsr_sum);
        setValues1([...values1.slice(-60)]);
        setValues2([...values2.slice(-60)]);
        setValues5([...values5.slice(-60)]);

        label1.push(time);
        label2.push(time);
        label5.push(time);
        setLabel1([...label1.slice(-60)]);
        setLabel2([...label2.slice(-60)]);
        setLabel5([...label5.slice(-60)]);
    };

    const getHRV = (val1, val2) => {
        if (val1) {
            return Math.abs(val2 - val1);
        }
        return 0;
    };

    // 심박수 컬러
    const heartColor = '#F75C5D';

    // 호흡수 컬러
    const breathColor = '#3889ff';

    // 산소탈포화지수 컬러
    const oxygenColor = '#38ac1f';

    // 압력값 컬러
    const fsrColor = '#3fd3d4';

    const getOption1 = show => {
        return {
            tooltip: {
                show,
                trigger: 'axis',
                axisPointer: {
                    lineStyle: {
                        color: heartColor,
                        width: 2,
                    },
                },
                formatter: `{c} BPM`,
                textStyle: {
                    color: 'black',
                    fontWeight: 'bold',
                    fontFamily: 'Pretendard',
                    fontSize: 13,
                },
            },
            xAxis: {
                show,
                axisLine: {
                    lineStyle: {
                        width: 1,
                        color: '#dddddd',
                    },
                },
                axisLabel: {
                    color: '#777777',
                    fontWeight: 'bold',
                    fontSize: 12,
                    fontFamily: 'Pretendard',
                },
                boundaryGap: false,
                data: label1,
            },
            yAxis: {
                show,
                type: 'value',
                axisLabel: {
                    color: '#777777',
                    fontWeight: 'bold',
                    fontSize: 12,
                    fontFamily: 'Pretendard',
                },
                min: 0,
                max: 120,
            },
            grid: {
                top: 30,
                left: 50,
                right: 60,
                bottom: 40,
            },
            visualMap: [
                {
                    // type: 'continuous',
                    show: false,
                    dimension: 1,
                    min: 0,
                    max: 1,
                    inRange: {
                        color: ['#CCCCCC', 'red']
                    }
                },
                // {
                //     type: 'continuous',
                //     dimension: 1,
                //     gt: 0,
                //     inRange: {
                //         color: ['red']
                //     }
                // },
            ],
            series: [
                {
                    name: 'BPM',
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    itemStyle: {
                        // color: heartColor,
                        borderWidth: 20,
                        borderType: 'solid',
                        opacity: 0.9,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(255, 91, 69, 1)',
                            },
                            {
                                offset: 0.4,
                                color: 'rgba(239, 218, 17, 1)',
                            },
                            {
                                offset: 0.7,
                                color: 'rgba(174, 250, 90, 1)',
                            },
                            {
                                offset: 1,
                                color: 'rgba(255, 255, 255, 0)',
                            },
                        ]),
                    },
                    // lineStyle: {
                    //     color: heartColor,
                    //     width: 4,
                    // },
                    markLine: {
                        symbol: 'none',
                        data: show
                            ? [
                                  {
                                      name: t('정상'),
                                      yAxis: data?.heart_range_from ? (data?.heart_range_from + data?.heart_range_to) / 2 : 80,
                                      label: {
                                          color: '#3989FF',
                                      },
                                      lineStyle: {
                                          color: '#3989FF',
                                      },
                                  },
                                  {
                                      name: t('경고'),
                                      yAxis: data?.heart_range_from ? data?.heart_range_from : 60,
                                      label: {
                                          color: '#FC9D3C',
                                      },
                                      lineStyle: {
                                          color: '#FC9D3C',
                                      },
                                  },
                                  {
                                      name: t('위험'),
                                      yAxis: data?.heart_range_to ? data?.heart_range_to : 100,
                                      label: {
                                          color: '#F75C5D',
                                      },
                                      lineStyle: {
                                          color: '#F75C5D',
                                      },
                                  },
                              ]
                            : [],
                        label: {
                            formatter: `{b}`,
                            fontWeight: 700,
                            fontFamily: 'Pretendard',
                        },
                        lineStyle: {
                            type: 'solid',
                        },
                    },
                    data: values1,


                    // renderItem: function(params, api) {
                    //     var valueType = api.value(2);
                    //     var color = valueType === 0 ? "red": "green";

                    //     let coord1 = api.coord([api.value(0, params.dataIndexInside), api.value(1, params.dataIndexInside)]),
                    //         coord2 = api.coord([api.value(0, params.dataIndexInside+1), api.value(1, params.dataIndexInside+1)]);

                    //     return {
                    //         type: "line",
                    //         shape: {
                    //             x1: coord1[0],
                    //             y1: coord1[1],
                    //             x2: coord2[0],
                    //             y2: coord2[1]
                    //         },
                    //         style: api.style({stroke:color, lineWidth: 4, smooth: true}),
                    //         smooth: true,
                    //         itemStyle: {
                    //             renderPoints: false
                    //         }
                    //     }
                    // }, 
                },
            ],
        };
    };

    const getOption2 = show => {
        return {
            tooltip: {
                show,
                trigger: 'axis',
                axisPointer: {
                    lineStyle: {
                        color: breathColor,
                        width: 2,
                    },
                },
                formatter: `{c} ${t('회')}`,
                textStyle: {
                    color: 'black',
                    fontWeight: 'bold',
                    fontFamily: 'Pretendard',
                    fontSize: 13,
                },
            },
            xAxis: {
                show,
                axisLine: {
                    lineStyle: {
                        width: 1,
                        color: '#dddddd',
                    },
                },
                axisLabel: {
                    color: '#777777',
                    fontWeight: 'bold',
                    fontSize: 12,
                    fontFamily: 'Pretendard',
                },
                boundaryGap: false,
                data: label2,
            },
            yAxis: {
                show,
                type: 'value',
                axisLabel: {
                    color: '#777777',
                    fontWeight: 'bold',
                    fontSize: 12,
                    fontFamily: 'Pretendard',
                },
                min: 0,
                max: 40,
            },
            grid: {
                top: 30,
                left: 50,
                right: 60,
                bottom: 40,
            },
            series: [
                {
                    name: 'BPM',
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    itemStyle: {
                        color: breathColor,
                        borderWidth: 20,
                        borderType: 'solid',
                        opacity: 0.9,
                    },
                    lineStyle: {
                        color: breathColor,
                        width: 4,
                    },
                    markLine: {
                        symbol: 'none',
                        data: show
                            ? [
                                  {
                                      name: t('정상'),
                                      yAxis: data?.resp_range_from ? (data?.resp_range_from + data?.resp_range_to) / 2 : 15,
                                      label: {
                                          color: '#3989FF',
                                      },
                                      lineStyle: {
                                          color: '#3989FF',
                                      },
                                  },
                                  {
                                      name: t('경고'),
                                      yAxis: data?.resp_range_from ? data?.resp_range_from : 10,
                                      label: {
                                          color: '#FC9D3C',
                                      },
                                      lineStyle: {
                                          color: '#FC9D3C',
                                      },
                                  },
                                  {
                                      name: t('위험'),
                                      yAxis: data?.resp_range_to ? data?.resp_range_to : 20,
                                      label: {
                                          color: '#F75C5D',
                                      },
                                      lineStyle: {
                                          color: '#F75C5D',
                                      },
                                  },
                              ]
                            : [],
                        label: {
                            formatter: `{b}`,
                            fontWeight: 700,
                            fontFamily: 'Pretendard',
                        },
                        lineStyle: {
                            type: 'solid',
                        },
                    },
                    data: values2,
                },
            ],
        };
    };

    const getOption3 = show => {
        return {
            tooltip: {
                show,
                trigger: 'axis',
                axisPointer: {
                    lineStyle: {
                        color: oxygenColor,
                        width: 2,
                    },
                },
                formatter: `{c} ${t('회')}`,
                textStyle: {
                    color: 'black',
                    fontWeight: 'bold',
                    fontFamily: 'Pretendard',
                    fontSize: 13,
                },
            },
            xAxis: {
                show,
                axisLine: {
                    lineStyle: {
                        width: 1,
                        color: '#dddddd',
                    },
                },
                axisLabel: {
                    color: '#777777',
                    fontWeight: 'bold',
                    fontSize: 12,
                    fontFamily: 'Pretendard',
                },
                boundaryGap: false,
                data: label3,
            },
            yAxis: {
                show,
                type: 'value',
                axisLabel: {
                    color: '#777777',
                    fontWeight: 'bold',
                    fontSize: 12,
                    fontFamily: 'Pretendard',
                },
                // min: 75,
                // max: 100,
            },
            grid: {
                top: 30,
                left: 50,
                right: 60,
                bottom: 40,
            },
            series: [
                {
                    name: 'BPM',
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    itemStyle: {
                        // color: oxygenColor,
                        borderWidth: 20,
                        borderType: 'solid',
                        opacity: 0.9,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(255, 91, 69, 1)',
                            },
                            {
                                offset: 0.4,
                                color: 'rgba(239, 218, 17, 1)',
                            },
                            {
                                offset: 0.7,
                                color: 'rgba(174, 250, 90, 1)',
                            },
                            {
                                offset: 1,
                                color: 'rgba(255, 255, 255, 0)',
                            },
                        ]),
                    },
                    lineStyle: {
                        color: oxygenColor,
                        width: 4,
                    },
                    // markLine: {
                    //     symbol: 'none',
                    //     data: [
                    //         {
                    //             name: t('정상'),
                    //             yAxis: 100,
                    //             label: {
                    //                 color: '#3989FF',
                    //             },
                    //             lineStyle: {
                    //                 color: '#3989FF',
                    //             },
                    //         },
                    //         {
                    //             name: t('경고'),
                    //             yAxis: 90,
                    //             label: {
                    //                 color: '#FC9D3C',
                    //             },
                    //             lineStyle: {
                    //                 color: '#FC9D3C',
                    //             },
                    //         },
                    //         {
                    //             name: t('위험'),
                    //             yAxis: 80,
                    //             label: {
                    //                 color: '#F75C5D',
                    //             },
                    //             lineStyle: {
                    //                 color: '#F75C5D',
                    //             },
                    //         },
                    //     ],
                    //     label: {
                    //         formatter: `{b}`,
                    //         fontWeight: 700,
                    //         fontFamily: 'Pretendard',
                    //     },
                    //     lineStyle: {
                    //         type: 'solid',
                    //     },
                    // },
                    data: values3,
                },
            ],
        };
    };

    const getOption4 = show => {
        return {
            tooltip: {
                show,
                trigger: 'axis',
                axisPointer: {
                    lineStyle: {
                        color: heartColor,
                        width: 2,
                    },
                },
                formatter: `>=90  {c0} RHR<br>>=80  {c1} RHR<br>>=70  {c2} RHR<br>>=60 {c3} RHR<br><60 {c6} RHR`,
                textStyle: {
                    color: 'black',
                    fontWeight: 'bold',
                    fontFamily: 'Pretendard',
                    fontSize: 13,
                },
            },
            xAxis: {
                show,
                axisLine: {
                    lineStyle: {
                        width: 1,
                        color: '#dddddd',
                    },
                },
                axisLabel: {
                    color: '#777777',
                    fontWeight: 'bold',
                    fontSize: 12,
                    fontFamily: 'Pretendard',
                },
                // boundaryGap: false,
                data: label4,
            },
            yAxis: {
                show,
                type: 'value',
                axisLabel: {
                    color: '#777777',
                    fontWeight: 'bold',
                    fontSize: 12,
                    fontFamily: 'Pretendard',
                },
            },
            grid: {
                top: 30,
                left: 50,
                right: 60,
                bottom: 40,
            },
            series: [
                {
                    name: '>=90',
                    type: 'bar',
                    barGap: 0,
                    label: label4,
                    data: values4_1,
                    emphasis: {
                        focus: 'series',
                    },
                    itemStyle: {
                        color: '#ff8787',
                    },
                },
                {
                    name: '80~90',
                    type: 'bar',
                    label: label4,
                    data: values4_2,
                    emphasis: {
                        focus: 'series',
                    },
                    itemStyle: {
                        color: '#f8c4b4',
                    },
                },
                {
                    name: '70~80',
                    type: 'bar',
                    label: label4,
                    data: values4_3,
                    emphasis: {
                        focus: 'series',
                    },
                    itemStyle: {
                        color: '#e5ebb2',
                    },
                },
                {
                    name: '60~70',
                    type: 'bar',
                    label: label4,
                    data: values4_4,
                    emphasis: {
                        focus: 'series',
                    },
                    itemStyle: {
                        color: '#bce29e',
                    },
                },
                {
                    name: '<60',
                    type: 'bar',
                    label: label4,
                    data: values4_5,
                    emphasis: {
                        focus: 'series',
                    },
                    itemStyle: {
                        color: '#c2dedc',
                    },
                },
            ],
        };
    };

    const getOption5 = show => {
        return {
            tooltip: {
                show,
                trigger: 'axis',
                axisPointer: {
                    lineStyle: {
                        color: oxygenColor,
                        width: 2,
                    },
                },
                formatter: `{c} ${t('회')}`,
                textStyle: {
                    color: 'black',
                    fontWeight: 'bold',
                    fontFamily: 'Pretendard',
                    fontSize: 13,
                },
            },
            xAxis: {
                show,
                axisLine: {
                    lineStyle: {
                        width: 1,
                        color: '#dddddd',
                    },
                },
                axisLabel: {
                    color: '#777777',
                    fontWeight: 'bold',
                    fontSize: 12,
                    fontFamily: 'Pretendard',
                },
                boundaryGap: false,
                data: label3,
            },
            yAxis: {
                show,
                type: 'value',
                axisLabel: {
                    color: '#777777',
                    fontWeight: 'bold',
                    fontSize: 12,
                    fontFamily: 'Pretendard',
                },
                // min: 75,
                // max: 100,
            },
            grid: {
                top: 30,
                left: 50,
                right: 60,
                bottom: 40,
            },
            series: [
                {
                    name: 'FSR',
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    itemStyle: {
                        // color: oxygenColor,
                        borderWidth: 20,
                        borderType: 'solid',
                        opacity: 0.9,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(255, 91, 69, 1)',
                            },
                            {
                                offset: 0.4,
                                color: 'rgba(239, 218, 17, 1)',
                            },
                            {
                                offset: 0.7,
                                color: 'rgba(174, 250, 90, 1)',
                            },
                            {
                                offset: 1,
                                color: 'rgba(255, 255, 255, 0)',
                            },
                        ]),
                    },
                    lineStyle: {
                        color: fsrColor,
                        width: 4,
                    },
                    data: values5,
                },
            ],
        };
    };

    const isUse = value5 >= 300;
    const motion_detected = value6;
    const heartClassName = motion_detected === 1 ? 'h-motion-detected' : ('heart' + (isUse && data?.warnning_heart ? ' on' : ''));
    const breathClassName = motion_detected === 1 ? 'r-motion-detected' : ('breath' + (isUse && data?.warnning_heart ? ' on' : ''));
    return (
        <main className="admin-contents">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h2 className="sub_title" style={{ flex: 1 }}>
                    {t('사용자 상세 보기')}
                </h2>
                <div className="pc-menu">
                    <button
                        type="button"
                        className="btn_nor md"
                        style={{ display: 'flex', alignItems: 'center', marginRight: 4, ...(showType === 'cell' ? { backgroundColor: '#FC9E3C' } : {}) }}
                        onClick={e => {
                            setShowType('cell');
                        }}
                    >
                        <AiOutlineAppstore style={{ width: 20, height: 20 }} />
                        {t('보기')}
                    </button>
                    <button
                        type="button"
                        className="btn_nor md"
                        style={{ display: 'flex', alignItems: 'center', ...(showType === 'list' ? { backgroundColor: '#FC9E3C' } : {}) }}
                        onClick={e => {
                            setShowType('list');
                        }}
                    >
                        <BsList style={{ width: 20, height: 20 }} />
                        {t('보기')}
                    </button>
                </div>
            </div>

            <section className="monitoring_person_info_box">
                <div className="data">
                    <p>
                        <b>{data?.device_no}</b>
                    </p>
                    <p>
                        <b>{data?.device_sn}</b>
                    </p>
                    <p>
                        <span className="state">{data?.online ? 'ON' : 'OFF'}</span>
                    </p>
                    <p>{data?.online ? <span className="time">{gapTime(data?.connectTime)}</span> : <span className="alert">{t('없음')}</span>}</p>
                </div>
                <div className="per_info">
                    <p className="pic">
                        <img src="https://picsum.photos/200/300" alt={t('프로필')} />
                    </p>

                    <p className="name">{data?.user?.name}</p>
                    <p className="age">
                        {calculateAge(data?.user?.birth)}
                        {t('세')}
                    </p>
                    <p className="gender">{t(typeSex[data?.user?.sex])}</p>
                    <p className="addr">
                        {data?.user?.addr1} {data?.user?.addr2}
                    </p>
                </div>
            </section>

            {showType === 'list' && [
                <section className="monitoring_signal_list">
                    <ul>
                        <li>
                            <div className="graph_wrap">
                                <article className="graph_arti" style={{ height: 140, display: 'flex', flexDirection: 'row' }}>
                                    <div className="graph_box" style={{ height: 140, flex: 1 }}>
                                        <ECharts option={getOption1(false)} opts={{ renderer: 'svg', width: 'auto' }} style={{ height: 140 }} />
                                    </div>
                                    <ul style={{ width: 180 }}>
                                        <li
                                            // className={'heart' + (isUse && data?.warnning_heart ? ' on' : '')  }
                                            // className={ motion_detected === 1? 'motion-detected': 'heart' +(isUse && data?.warnning_heart ? ' on' : '')  }
                                            className={heartClassName}
                                            // className={getHeartClassName(isUse, data, motion_detected) }
                                            style={{ borderColor: '#aaaaaa', borderWidth: 1, borderStyle: 'solid', padding: 20 }}
                                        >
                                            <p className="title">
                                                {/* <b>{t('심박수')}</b> */}
                                                <b>HR</b>
                                            </p>
                                            <p className="data">
                                                <strong>{isUse ? value1 : 0}</strong>
                                            </p>
                                        </li>
                                    </ul>
                                </article>

                                <article className="graph_arti" style={{ height: 140, display: 'flex', flexDirection: 'row', marginTop: 4 }}>
                                    <div className="graph_box" style={{ height: 140, flex: 1 }}>
                                        <ECharts option={getOption2(false)} opts={{ renderer: 'svg', width: 'auto' }} style={{ height: 140 }} />
                                    </div>
                                    <ul style={{ width: 180 }}>
                                        <li
                                            className={breathClassName}
                                            style={{ borderColor: '#aaaaaa', borderWidth: 1, borderStyle: 'solid', padding: 20 }}
                                        >
                                            <p className="title">
                                                {/* <b>{t('호흡수')}</b> */}
                                                <b>RR</b>
                                            </p>
                                            <p className="data">
                                                <strong>{isUse ? value2 : 0}</strong>
                                            </p>
                                        </li>
                                    </ul>
                                </article>

                                <article className="graph_arti" style={{ height: 140, display: 'flex', flexDirection: 'row', marginTop: 4 }}>
                                    <div className="graph_box" style={{ height: 140, flex: 1 }}>
                                        <ECharts option={getOption5(false)} opts={{ renderer: 'svg', width: 'auto' }} style={{ height: 140 }} />
                                    </div>
                                    <ul style={{ width: 180 }}>
                                        <li
                                            className={'fsr' + (isUse && data?.warnning_fsr ? ' on' : '')}
                                            style={{ borderColor: '#aaaaaa', borderWidth: 1, borderStyle: 'solid', padding: 20 }}
                                        >
                                            <p className="title">
                                                <b>FSR</b>
                                            </p>
                                            <p className="data">
                                                <strong>{value5}</strong>
                                            </p>
                                        </li>
                                    </ul>
                                </article>
                            </div>
                        </li>
                    </ul>
                </section>,
                <section className="monitoring_time_graph">
                    <article className="graph_arti rhr_graph" style={{ height: 320 }}>
                        <div className="top" style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ flex: 1, textAlign: 'left' }}>
                                <span>
                                    <em style={{ width: 10, backgroundColor: '#ff0000' }}></em> {'> 90bpm'}
                                </span>
                                <span>
                                    <em style={{ width: 10, backgroundColor: '#00ff00' }}></em> {'80~89bpm'}
                                </span>
                                <span>
                                    <em style={{ width: 10, backgroundColor: '#0000ff' }}></em> {'70~79bpm'}
                                </span>
                                <span>
                                    <em style={{ width: 10, backgroundColor: '#00ffff' }}></em> {'60~69bpm'}
                                </span>
                                <span>
                                    <em style={{ width: 10, backgroundColor: '#ffff00' }}></em> {'< 60bpm'}
                                </span>
                            </div>
                            <span>
                                <em></em> {t('평시심박수')}(RHR)
                            </span>
                        </div>
                        <div className="graph_box" style={{ height: 280 }}>
                            <ECharts option={getOption4(true)} opts={{ renderer: 'svg', width: 'auto' }} style={{ height: 280 }} />
                        </div>
                    </article>
                </section>,
            ]}

            {showType === 'cell' && [
                <section className="monitoring_signal_list">
                    <ul>
                        {/* <li className={'heart' + (isUse && data?.warnning_heart ? ' on' : '')}> */}
                        <li className={heartClassName}>
                            <p className="title">
                                <b>{t('심박수')}</b>(BPM)
                            </p>
                            <p className="data">
                                <strong>{isUse ? value1 : 0}</strong> BPM
                            </p>
                        </li>
                        <li className={breathClassName}>
                            <p className="title">
                                <b>{t('호흡수')}</b>(BPM)
                            </p>
                            <p className="data">
                                <strong>{isUse ? value2 : 0}</strong> BPM
                            </p>
                        </li>
                        {/* <li className="oxygen">
                        <p className="title">
                            <b>{t('산소탈포화지수')}</b>(ODI)
                        </p>
                        <p className="data">
                            <strong>{value3}</strong> {t('회')}
                        </p>
                    </li> */}
                        <li className="fsr">
                            <p className="title">
                                <b>{t('사용 여부')}</b>
                            </p>
                            <p className="data" style={{ display: 'flex', alignItems: 'center' }}>
                                <strong>
					<div class="icon_act">
					  <img src={isUse ? UseIcon : UnUseIcon}  class="icon_act_fsr" />
		    			</div>
		    		{isUse ? motion_detected === 1? t('움직임 감지') :t('사용 중') : t('미사용')}
		    		</strong>
                                <b
                                    style={{
                                        textAlign: 'right',
                                        paddingLeft: 0,
					flex:1,
                                    }}
                                >{`${t('압력값')} : ${value5}`}</b>


                            </p>
                        </li>
                    </ul>
                </section>,

                <section className="monitoring_time_graph">
                    <h4>REAL TIME GRAPH</h4>

                    <div className="graph_wrap">
                        <article className="graph_arti heart_graph" style={{ height: 356 }}>
                            <div className="top">
                                <span>
                                    <em></em> {t('심박수')}
                                </span>
                            </div>
                            <div className="graph_box" style={{ height: 280 }}>
                                <ECharts option={getOption1(true)} opts={{ renderer: 'svg', width: 'auto' }} style={{ height: 280 }} />
                            </div>
                            <div className="bottom">
                                <span>
                                    {t('정상 심박수 범위')} : {data?.heart_range_from ? data?.heart_range_from : 60}-
                                    {data?.heart_range_to ? data?.heart_range_to : 100} BPM
                                </span>
                            </div>
                        </article>

                        {/* <article className="graph_arti heart_graph" style={{ height: 320 }}>
                        <div className="top">
                            <span>{t('심박변이도')}(HRV)</span> */}
                        {/* <span>정상 심박수 범위 : 60-100 BPM</span> */}
                        {/* </div>
                        <div className="graph_box" style={{ height: 280 }}>
                            <ECharts option={getOption4()} opts={{ renderer: 'svg', width: 'auto' }} style={{ height: 280 }} />
                        </div>
                    </article> */}

                        {/* <article className="graph_arti oxygen_graph" style={{ height: 356 }}>
                            <div className="top">
                                <span>
                                    <em></em> {t('심박변이도')}(HRV)
                                </span>
                            </div>
                            <div className="graph_box" style={{ height: 280 }}>
                                <ECharts option={getOption3(true)} opts={{ renderer: 'svg', width: 'auto' }} style={{ height: 280 }} />
                            </div>
                            <div className="bottom">
                                <span></span>
                            </div>
                        </article> */}

                        <article className="graph_arti breath_graph" style={{ height: 356 }}>
                            <div className="top">
                                <span>
                                    <em></em> {t('호흡수')}
                                </span>
                            </div>
                            <div className="graph_box" style={{ height: 280 }}>
                                <ECharts option={getOption2(true)} opts={{ renderer: 'svg', width: 'auto' }} style={{ height: 280 }} />
                            </div>
                            <div className="bottom">
                                <span>
                                    {t('정상 호흡수 범위')} : {data?.resp_range_from ? data?.resp_range_from : 10}~
                                    {data?.resp_range_to ? data?.resp_range_to : 30} BPM
                                </span>
                            </div>
                        </article>

                        {/* <article className="graph_arti oxygen_graph" style={{ height: 320 }}>
                        <div className="top">
                            <span>
                                <em></em> {t('산소탈포화지수')}
                            </span>
                        </div>
                        <div className="graph_box" style={{ height: 280 }}>
                            <ECharts option={getOption3()} opts={{ renderer: 'svg', width: 'auto' }} style={{ height: 280 }} />
                        </div>
                    </article> */}

                        <article className="graph_arti rhr_graph" style={{ height: 320 }}>
                            <div className="top" style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, textAlign: 'left' }}>
                                    <span>
                                        <em style={{ width: 10, backgroundColor: '#ff8787' }}></em> {'> 90bpm'}
                                    </span>
                                    <span>
                                        <em style={{ width: 10, backgroundColor: '#f8c4b4' }}></em> {'80~89bpm'}
                                    </span>
                                    <span>
                                        <em style={{ width: 10, backgroundColor: '#e5ebb2' }}></em> {'70~79bpm'}
                                    </span>
                                    <span>
                                        <em style={{ width: 10, backgroundColor: '#bce29e' }}></em> {'60~69bpm'}
                                    </span>
                                    <span>
                                        <em style={{ width: 10, backgroundColor: '#c2dedc' }}></em> {'< 60bpm'}
                                    </span>
                                </div>
                                <span>
                                    <em></em> {t('평시심박수')}(RHR)
                                </span>
                            </div>
                            <div className="graph_box" style={{ height: 280 }}>
                                <ECharts option={getOption4(true)} opts={{ renderer: 'svg', width: 'auto' }} style={{ height: 280 }} />
                            </div>
                        </article>
                    </div>
                </section>,
            ]}

            <MonitoringDetailBottom data={data} />
        </main>
    );
};

export default MonitoringDetail;
