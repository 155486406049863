import React from 'react';
import { useTranslation } from 'react-i18next';

const Popup = props => {
    const { t } = useTranslation();

    return (
        <section className="popup_wrap show">
            <div className="pop_base">
                <div className="icons none"></div>
                <p className="tit">{t('사용할 수 없는 아이디 입니다.')}</p>
                <div className="sub">{t('아이디를 다시 입력해주세요.')}</div>
                <button type="button" className="btn_nor md" onClick={props.onClose}>
                    {t('확인')}
                </button>
            </div>
        </section>
    );
};

export default Popup;
