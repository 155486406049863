import React, { useState, useEffect } from 'react';
import ECharts, { EChartsReactProps } from 'echarts-for-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GetList, Delete } from '../lib/Device';
import { GetList as GetListGroup } from '../lib/Group';
import alarm from '../assets/sound/beep1.mp3';

import Cell, { deviceState } from './monitoring_realtime_cell';
import { checkCanPlay } from '../lib/Common';
import { AuthMe } from '../lib/MyHttp';

const MonitoringRealtimeList = () => {
    const navigate = useNavigate();
    const { name } = useParams();
    const { t } = useTranslation();

    const [audio] = useState(new Audio(alarm));

    const [checkStateNormal, setCheckStateNormal] = useState(false);
    const [checkStateWarnning, setCheckStateWarnning] = useState(false);
    const [checkStateDanger, setCheckStateDanger] = useState(false);
    const [checkOnline, setCheckOnline] = useState(false);
    const [checkOffline, setCheckOffline] = useState(false);
    const [checkMan, setCheckMan] = useState(false);
    const [checkWoman, setCheckWoman] = useState(false);
    const [checkGroupAll, setCheckGroupAll] = useState(true);
    const [groups, setGroups] = useState([]);
    const [canPlay, setCanPlay] = useState(false);
    const [myInfo, setMyInfo] = useState(false);

    const [data, setData] = useState({
        page: 1,
        size: '100',
        sort: '-device_no',
        name: name,
    });

    const [items, setItems] = useState([]);
    const [range, setRange] = useState({ size: 10, start: 0, end: 29, total: 0 });

    useEffect(() => {
        data.name = name;
        setData({ ...data });
    }, [name]);

    useEffect(() => {
        refreshData();
    }, [data]);

    useEffect(() => {
        refreshGroup();
        checkCanPlay().then(result => {
            if (result !== false) {
                setTimeout(() => {
                    setCanPlay(true);
                }, 1000);
            }
        });
        AuthMe(res => {
            setMyInfo(res);
        });

        return () => {
            if (audio) audio.pause();
        };
    }, []);

    useEffect(() => {
        let play = false;
        for (var i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.warnning_heart) {
                play = true;
                break;
            }
            if (item.warnning_resp) {
                play = true;
                break;
            }
        }

        if (canPlay && myInfo?.on_sound === 'Y' && audio) {
            if (play) {
                if (audio.paused) {
                    audio.loop = true;
                    audio.play();
                }
            } else {
                audio.pause();
            }
        }
    }, [items]);

    const refreshGroup = () => {
        GetListGroup({}, res => {
            setGroups(res);
        });
    };

    const refreshData = () => {
        GetList(data, res => {
            setItems(res.rows);
            setRange(res.range);
        });
    };

    const [colorShow, setColorShow] = useState('');

    function colorClassSet() {
        if (!colorShow) {
            setColorShow('show');
        } else {
            setColorShow('');
        }
    }

    useEffect(() => {}, []);

    const clickRow = item => {
        navigate('/monitoring/detail/' + item.device_no);
    };

    const filterItems = items
        .filter(x => {
            if (checkStateNormal && checkStateWarnning && checkStateDanger) {
                return true;
            }
            if (!checkStateNormal && !checkStateWarnning && !checkStateDanger) {
                return true;
            }
            if (checkStateNormal && x.state === 'normal') return true;
            if (checkStateWarnning && x.state === 'warnning') return true;
            if (checkStateWarnning && x.state === 'danger') return true;
        })
        .filter(x => {
            if (checkOnline && checkOffline) {
                return true;
            }
            if (!checkOnline && !checkOffline) {
                return true;
            }
            if (checkOnline && x.online) return true;
            if (checkOffline && !x.online) return true;
        })
        .filter(x => {
            if (checkMan && checkWoman) {
                return true;
            }
            if (!checkMan && !checkWoman) {
                return true;
            }
            if (checkMan && x.user?.sex === 'male') return true;
            if (checkWoman && x.user?.sex === 'female') return true;
        })
        .filter(x => {
            if (checkGroupAll) return true;

            const gs = groups.filter(x => x.checked);
            const group_no = gs.map(x => x.group_no);

            if (x.user) {
                if (group_no.includes(x.user.group_no)) return true;
            }
            return false;
        })
        .sort((a, b) => {
            if (a.online && b.online) {
                if (a.state !== deviceState.OFF && b.state === deviceState.OFF) {
                    return -1;
                } else if (a.state === deviceState.OFF && b.state !== deviceState.OFF) {
                    return 1;
                }
                return 0;
            } else if (a.online && !b.online) {
                return -1;
            } else if (!a.online && b.online) {
                return 1;
            }
            return 0;
        });

    return (
        <main className="admin-contents">
            <div className="monitoring_realtime_list_wrapper">
                <section className="realtime_view">
                    <div className="title">
                        <h2 className="sub_title">{t('실시간 간편 보기')}</h2>
                        <button type="button" className="btn_underline">
                            <Link to="/monitoring/realtimesimplelist">{t('전체보기')}</Link>
                        </button>
                    </div>

                    <ul className="realtime_graph_list">
                        {filterItems.map(x => {
                            return (
                                <Cell
                                    data={x}
                                    onClick={data => {
                                        clickRow(x);
                                    }}
                                    onChange={data => {
                                        setItems([...items]);
                                    }}
                                />
                            );
                        })}
                    </ul>
                </section>

                <aside className="aside_info">
                    {/* <article className="box">
                        <div className="title">
                            <h2>사용자 상태별 색상 안내</h2>
                            <button type="button" className={`btn_nor sm arrow ${colorShow}`} onClick={colorClassSet}>
                                색상안내 보기
                            </button>
                        </div>
                        <div className={`cont-box slide ${colorShow}`}>
                            <div className="state_color_wrap">
                                <dl>
                                    <dt>생체 모니터링 수치에 대한 상태</dt>
                                    <dd>
                                        <p>
                                            정상 <span className="green"></span>
                                        </p>
                                        <p>
                                            주의 <span className="yellow"></span>
                                        </p>
                                        <p>
                                            위험 <span className="red"></span>
                                        </p>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>기기 사용여부에 따른 상태 안내</dt>
                                    <dd>
                                        <p>
                                            휴식중 <span className="gray"></span>
                                        </p>
                                        <p>
                                            전원 OFF <span className="grayDeep"></span>
                                        </p>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </article> */}

                    <article className="box">
                        <div className="title">
                            <h2>{t('오토웰즈 사용자 리스트')}</h2>
                        </div>
                        <div className="cont-box">
                            <div className="check_box">
                                <dl>
                                    <dt>{t('사용자 상태')}</dt>
                                    <dd>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={checkStateNormal}
                                                onClick={() => {
                                                    setCheckStateNormal(!checkStateNormal);
                                                }}
                                            />{' '}
                                            {t('정상')}
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={checkStateWarnning}
                                                onClick={() => {
                                                    setCheckStateWarnning(!checkStateWarnning);
                                                }}
                                            />{' '}
                                            {t('주의')}
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={checkStateDanger}
                                                onClick={() => {
                                                    setCheckStateDanger(!checkStateDanger);
                                                }}
                                            />{' '}
                                            {t('위험')}
                                        </label>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>{t('기기상태')}</dt>
                                    <dd>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={checkOnline}
                                                onClick={() => {
                                                    setCheckOnline(!checkOnline);
                                                }}
                                            />{' '}
                                            {t('사용중')}
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={checkOffline}
                                                onClick={() => {
                                                    setCheckOffline(!checkOffline);
                                                }}
                                            />{' '}
                                            {t('미사용')}
                                        </label>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>{t('성별')}</dt>
                                    <dd>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={checkMan}
                                                onClick={() => {
                                                    setCheckMan(!checkMan);
                                                }}
                                            />{' '}
                                            {t('남자')}
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={checkWoman}
                                                onClick={() => {
                                                    setCheckWoman(!checkWoman);
                                                }}
                                            />{' '}
                                            {t('여자')}
                                        </label>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>{t('회원그룹')}</dt>
                                    <dd>
                                        <label className="wide">
                                            <input
                                                type="checkbox"
                                                checked={checkGroupAll}
                                                onClick={() => {
                                                    groups.map(x => (x.checked = !checkGroupAll));

                                                    setCheckGroupAll(!checkGroupAll);
                                                    setGroups([...groups]);
                                                }}
                                            />{' '}
                                            {t('전체보기')}
                                        </label>
                                        {groups.map(x => {
                                            return (
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={x.checked}
                                                        onClick={() => {
                                                            x.checked = x.checked ? false : true;
                                                            setGroups([...groups]);
                                                            setCheckGroupAll(false);
                                                        }}
                                                    />{' '}
                                                    {x.name}
                                                </label>
                                            );
                                        })}
                                    </dd>
                                </dl>
                            </div>

                            <div className="state_color_wrap">
                                <dl>
                                    <dt>{t('생체 모니터링 수치에 대한 상태')}</dt>
                                    <dd>
                                        <p>
                                            {t('정상')} <span className="green"></span>
                                        </p>
                                        <p>
                                            {t('주의')} <span className="yellow"></span>
                                        </p>
                                        <p>
                                            {t('위험')} <span className="red"></span>
                                        </p>
                                    </dd>
                                </dl>
                            </div>

                            <ul className="moni_user_list">
                                {filterItems.map(x => {
                                    return (
                                        <li
                                            className={
                                                x.online
                                                    ? x.state === deviceState.NORMAL
                                                        ? 'green'
                                                        : x.state === deviceState.WARNNING
                                                        ? 'yellow'
                                                        : x.state === deviceState.DANGER
                                                        ? 'red'
                                                        : ''
                                                    : ''
                                            }
                                            onClick={() => {
                                                // const temps = items.filter(item => item !== x);
                                                // setItems([{ ...x }, ...temps]);
                                            }}
                                        >
                                            <span className="addr">{x.user?.group ? `[${x.user?.group.name}]` : ''}</span>
                                            <span className="name">{x.user ? x.user.name : t('이름없음')}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </article>
                </aside>
            </div>
        </main>
    );
};

export default MonitoringRealtimeList;
