import { Method, send } from './MyHttp';

/**
 * 정보
 */
export const Get = (member_no, success, fail) => {
    let paramsT = {};

    send(Method.GET, '/user/' + member_no, paramsT, success, fail);
};

/**
 * 리스트
 */
export const GetList = (params, success, fail) => {
    let paramsT = { ...params };

    send(Method.GET, '/user', paramsT, success, fail);
};

/**
 * 등록
 */
export const Create = (data, success, fail) => {
    const paramsT = {
        group_no: data.group_no,
        name: data.name,
        id: data.id,
        pw: data.pw,
        phone: data.phone,
        email: data.email,
        inst_nm: data.inst_nm,
        addr1: data.addr1,
        addr2: data.addr2,
        on_push: data.on_push,
        on_sound: data.on_sound,
        image: data.image,

        sex: data.sex,
        birth: data.birth,
        guard_name: data.guard_name,
        guard_phone: data.guard_phone,
        set_internet: data.set_internet,
        device_sn: data.device_sn,
        medical: data.medical,
    };

    send(Method.POST, '/user', paramsT, success, fail);
};

/**
 * 업데이트
 */
export const Update = (data, success, fail) => {
    const paramsT = {
        group_no: data.group_no,
        member_no: data.member_no,
        name: !!data.name ? data.name : '',
        pw: !!data.pw ? data.pw : '',
        phone: !!data.phone ? data.phone : '',
        email: !!data.email ? data.email : '',
        inst_nm: !!data.inst_nm ? data.inst_nm : '',
        addr1: !!data.addr1 ? data.addr1 : '',
        addr2: !!data.addr2 ? data.addr2 : '',
        on_push: !!data.on_push ? data.on_push : '',
        on_sound: !!data.on_sound ? data.on_sound : '',
        sex: !!data.sex ? data.sex : '',
        birth: !!data.birth ? data.birth : '',
        guard_name: !!data.guard_name ? data.guard_name : '',
        guard_phone: !!data.guard_phone ? data.guard_phone : '',
        set_internet: !!data.set_internet ? data.set_internet : '',
        device_sn: !!data.device_sn ? data.device_sn : '',
        medical: !!data.medical ? data.medical : '',
        fb_token: !!data.fb_token ? data.fb_token : '',
        image: data.image,
    };

    send(Method.PUT, '/user', paramsT, success, fail);
};

/**
 * 삭제
 */
export const Delete = (ids, success, fail) => {
    let paramsT = { ids };

    send(Method.DELETE, '/user', paramsT, success, fail);
};

/**
 * 아이디 검색
 */
export const SearchId = (id, success, fail) => {
    let paramsT = { id };

    send(Method.GET, '/user/searchId', paramsT, success, fail);
};
