import React, { useState, useEffect } from 'react';
import imgExample from '../assets/img_manage_main_top.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GetList, Delete } from '../lib/Device';
import { typeSex, calculateAge } from '../lib/Common';
import moment from 'moment';
import PageBar from '../components/pagebar';

const EquipmentList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [data, setData] = useState({
        page: 1,
        size: '10',
        sort: '-device_no',
    });

    const [showMenu, setShowMenu] = useState(false);
    const [items, setItems] = useState([]);
    const [range, setRange] = useState({ size: 10, start: 0, end: 29, total: 0 });

    useEffect(() => {
        refreshData();
    }, [data]);

    const refreshData = () => {
        GetList(data, res => {
            setItems(res.rows);
            setRange(res.range);
        });
    };

    const clickRow = item => {
        navigate('/equipment/detail/' + item.device_no);
    };

    const clickCreateAs = () => {
        const device = items.find(x => x.checked);
        navigate('/equipment/detail/' + device.device_no);
    };

    const clickEdit = () => {
        const device = items.find(x => x.checked);
        navigate('/equipment/upload/' + device.device_no);
    };

    const clickDelete = () => {
        const devices = items.filter(x => x.checked);

        if (window.confirm(t('삭제 하시겠습니까?'))) {
            Delete(
                devices.map(x => x.device_no),
                res => {
                    refreshData();
                },
            );
        }
    };

    const checkCount = items.filter(x => x.checked).length;

    return (
        <main className="admin-contents">
            <h2 className="sub_title">
                {t('장비목록')}({range.total})
            </h2>
            <div className="equipment_list_top">
                <div className="left_box">
                    <button type="button" className="btn_nor md">
                        <Link to="/equipment/upload">{t('장비등록')}</Link>
                    </button>
                    {checkCount === 1 && (
                        <button type="button" className="btn_nor md" onClick={clickCreateAs}>
                            {t('AS 등록')}
                        </button>
                    )}
                    {checkCount === 1 && (
                        <button type="button" className="btn_nor md" onClick={clickEdit}>
                            {t('수정')}
                        </button>
                    )}
                    <button type="button" className="btn_nor md" onClick={clickDelete}>
                        {t('선택삭제')}
                    </button>
                </div>
                <div className="right_box">
                    <select
                        className="select_nor md"
                        value={data.size}
                        onChange={e => {
                            data.size = e.target.value;
                            data.page = 1;
                            setData({ ...data });
                        }}
                    >
                        <option value="10">{t('10건')}</option>
                        <option value="20">{t('20건')}</option>
                        <option value="30">{t('30건')}</option>
                        <option value="50">{t('50건')}</option>
                    </select>
                    <select
                        className="select_nor md"
                        value={data.sort}
                        onChange={e => {
                            data.sort = e.target.value;
                            setData({ ...data });
                        }}
                    >
                        <option value="-device_sn">{t('내림차순으로 정렬')}</option>
                        <option value="device_sn">{t('오름차순으로 정렬')}</option>
                        <option value="-device_no">{t('최근 등록순으로 정렬')}</option>
                    </select>
                </div>
            </div>

            <section className="equipment_list_wrapper">
                <ul className="equipment_list">
                    {items.map(x => {
                        return (
                            <li>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={x.checked}
                                        onClick={() => {
                                            x.checked = !x.checked;
                                            setItems([...items]);
                                        }}
                                    />
                                    <span>{x.device_sn}</span>
                                </label>
                                <div className="pic">
                                    <img src={x.image ? x.image : imgExample} alt="" />
                                </div>
                                <a href="javascript:;" className="sbj" onClick={() => clickRow(x)}>
                                    {x.user
                                        ? `${x.user.name} [${calculateAge(x.user.birth)}${t('세')}/${t(typeSex[x.user.sex])}] ${x.user.addr1} ${x.user.addr2}`
                                        : t('없음')}
                                </a>
                                <dl className="info">
                                    <dt>{t('설치일자')}</dt>
                                    <dd>{x.device_dt ? moment(x.device_dt).format('YYYY / MM / DD') : ''}</dd>
                                </dl>
                                <dl className="info">
                                    <dt>{t('설치 담당자')}</dt>
                                    <dd>{x.manager_name}</dd>
                                </dl>
                                <div className="btns">
                                    <button type="button" className="btn_nor md sm" onClick={() => clickRow(x)}>
                                        {t('AS 내역')}
                                    </button>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </section>

            <PageBar
                nowPage={Number(data.page)}
                totalPage={Math.ceil(Number(range.total) / Number(range.size))}
                reload={page => {
                    data.page = page;
                    setData({ ...data });
                }}
            />
        </main>
    );
};

export default EquipmentList;
