import React, { useState, useRef } from 'react';
import mainImg from '../assets/img_manage_main_top.jpg';
import mainLogo from '../assets/img_manage_main_logo.png';
import mainInfo01 from '../assets/img_manage_main_info01.jpg';
import mainInfo02 from '../assets/img_manage_main_info02.jpg';
import { useTranslation } from 'react-i18next';

// import imgSlide01 from '../assets/img_main_slide_01.jpg';
// import imgSlide02 from '../assets/img_main_slide_02.jpeg';
import imgSystem01 from '../assets/img_main_system_01.jpg';
import imgSystem02 from '../assets/img_main_system_02.jpg';
import imgSystem03 from '../assets/img_main_system_03.jpg';
import imgPoint01 from '../assets/img_main_point_01.jpg';
import imgPoint02 from '../assets/img_main_point_02.jpg';
import imgPoint03 from '../assets/img_main_point_03.jpg';

const Main = () => {
    const { t } = useTranslation();

    return (
        <main className="admin-contents">
            <p className="mobile_admin_welcome">{t('오토웰즈님, 환영습니다.')}</p>

            <div className="admin_main_top">
                <img src={mainImg} alt={t('메인 이미지')} />
            </div>

            {/* <div className="admin_main_title">
                <div className="cont">
                    <h2>{t('사용자의 건강 모니터링을 한눈에!')}</h2>
                    <h3>
                        {t('무구속, 무자각 방식')}
                        <br />
                        {t('독거노인 고독사 예방 매트리스')}
                    </h3>
                </div>
                <div className="logo">
                    <img src={mainLogo} alt={t('효심 로고')} />
                </div>
            </div> */}

            {/* <div className="admin_main_explain">
                <div className="pic">
                    <img src={mainInfo01} alt="x6 Channel" />
                </div>

                <div className="explain">
                    <dl>
                        <dt>{t('생체신호(심박수/호흡수) 측정 전용 매트를 통한 생체정보 측정')}</dt>
                        <dd>
                            {t('생체신호 측정용 압전 센서를 매트리스에 내장하여 외부 오염 및 손상 방지 ')}
                            <br />
                            {t('매트리스 내부에 부착하여 수면 중 움직임으로부터 센서 이탈 방지 ')}
                            <br />
                            {t('사용자들에세 동일한 매트리스 제공으로 생체신호 측정 환경 표준화')}
                        </dd>
                    </dl>
                    <dl>
                        <dt>{t('전원만 연결하면 자동 통신이 가능한 NB-IoT 기술 적용')}</dt>
                        <dd>
                            {t('인터넷 wi-fi 공유기 설치가 필요 없어 인터넷 연결을 어려워 하시는 노인분들께 효과적')} <br />
                            {t('월 3~4만원대의 인터넷 비용 대비 월 5천원대 비용으로 경제적 부담 대폭 절감')}
                        </dd>
                    </dl>
                    <dl>
                        <dt>{t('이상생체 신호 감지 시 관할 119 구조요원 핸드폰 문자로 실시간 알람')}</dt>
                        <dd>
                            {t('생체신호 즉정 대상자의 연락처를 시스텐 저장 및 이상생체신호 감지 시 관할 119 안전센터')} <br />
                            {t('구조 요원의 핸드폰 문자로 실시간 알람(문자, 진동)을 제공하여 신속한 응급 출동 지원')}
                        </dd>
                    </dl>
                </div>
            </div> */}

            {/* <div className="admin_main_wide_content">
                <div className="pics">
                    <img src={mainInfo02} alt={t('이미지')} />
                </div>
                <p className="normal">
                    {t('2개의 센서로 이루어진 센서부 시스템으로 심장 박동으로 혈관이 수축ㆍ팽창할 때 발생하는 인체의 미세 진동을 증폭하여 생체신호 측정')}
                </p>
                <p className="desc">
                    <span>ㆍ{t('FSR 센서 : 움직임에 대한 노이즈 (모션 아티팩트) 완화와 재실감지 기능 부여')}</span>
                    <span>ㆍ{t('Piezo 센서 : 심박수 및 호흡수 측정')}</span>
                </p>
                <p className="normal">
                    {t(
                        '정자세 혹은 좌측, 우측 등 매트리스 위에서 다양한 자세에서도 실시간으로 최적의 신호를 선택하여 심박 및 호흡 등 생체정보 측정과 자세 감지 가능',
                    )}
                    <br />
                    <br />
                    {t('측정된 생체신호로부터 심박 변이도 추출과 데이터 기반 학습을 통해 인공지능(AI) 수면 분석 및 수면 단계 예측 가능')}
                </p>
            </div> */}

            <section className="sec-information">
                <h2>{t('사용자의 건강 모니터링을 한눈에!')}</h2>
                <h3>{t('비대면 복지 솔루션, 건강 모니터링 시스템을 제공합니다.')}</h3>

                <ul className="system_list">
                    <li>
                        <div className="img_box">
                            <img src={imgSystem01} alt="시스템이미지01" />
                        </div>
                        <p className="bold">{t('센서부 시스템')}</p>
                        <p className="txt">
                            {t('심장 박동으로 혈관이 수축ㆍ팽창할 때')} <br />
                            {t('발생하는 인체의 미세 진동을 증폭하여 측정')}
                        </p>
                    </li>
                    <li>
                        <div className="img_box">
                            <img src={imgSystem02} alt="시스템이미지02" />
                        </div>
                        <p className="bold">{t('독거노인 및 환자 생체신호 측정')}</p>
                        <p className="txt">
                            {t('무구속, 무자각 방식으로 간섭없이')} <br />
                            {t('전용 매트를 통해 생체정보 측정')}
                        </p>
                    </li>
                    <li>
                        <div className="img_box">
                            <img src={imgSystem03} alt="시스템이미지03" />
                        </div>
                        <p className="bold">{t('수면 분석 및 단계 예측')}</p>
                        <p className="txt">
                            {t('측정된 생체신호로부터 심박 변이도 추출과 데이터 기반')} <br />
                            {t('학습을 통해 인공지능 (AI) 수면 분석 및 예측')}
                        </p>
                    </li>
                </ul>

                <ul className="point_list">
                    <li>
                        <h4>POINT 01</h4>
                        <div className="img_box pd">
                            <img src={imgPoint01} alt="포인트이미지01" />
                        </div>
                        <p className="txt">
                            {t('2개의 센서로 이루어진 센서부 시스템으로 심장 박동으로')}
                            <br />
                            {t('혈관이 수축ㆍ팽창할 때 발생하는 인체의 미세 진동을')}
                            <br />
                            {t('증폭하여 생체신호 측정')}
                        </p>
                        <p className="ex">
                            <span>ㆍ{t('FSR 센서 : 움직임에 대한 노이즈 (모션 아티팩트) 완화와 재실감지 기능 부여')}</span>
                            <span>ㆍ{t('Piezo 센서 : 심박수 및 호흡수 측정')}</span>
                        </p>
                    </li>
                    <li>
                        <h4>POINT 02</h4>
                        <div className="img_box">
                            <img src={imgPoint02} alt="포인트이미지02" />
                        </div>
                        <p className="txt">
                            {t('정자세 혹은 좌측, 우측 등 매트리스 위에서 다양한')} <br />
                            {t('자세에서도 실시간으로 최적의 신호를 선택하여')} <br />
                            {t('심박 및 호흡 등 생체정보 측정과 자세 감지 가능')}
                        </p>
                    </li>
                    <li>
                        <h4>POINT 03</h4>
                        <div className="img_box">
                            <img src={imgPoint03} alt="포인트이미지03" />
                        </div>
                        <p className="txt">
                            {t('측정된 생체신호로부터 심박 변이도 추출과')} <br />
                            {t('데이터 기반 학습을 통해 인공지능(AI)')} <br />
                            {t('수면 분석 및 수면 단계 예측 가능')}
                        </p>
                    </li>
                </ul>
            </section>
        </main>
    );
};

export default Main;
