import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: require('./locales/en.json'),
        },
        ko: {
            translation: require('./locales/ko.json'),
        },
        cn: {
            translation: require('./locales/cn.json'),
        },
        th: {
            translation: require('./locales/th.json'),
        },
        vi: {
            translation: require('./locales/vi.json'),
        },
    },
    lng: 'ko',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
