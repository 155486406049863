import { Method, send } from './MyHttp';

/**
 * 리스트
 */
export const GetList = (params, success, fail) => {
    let paramsT = { ...params };

    send(Method.GET, '/deviceHistory', paramsT, success, fail);
};

/**
 * 등록
 */
export const Create = (device_no, data, success, fail) => {
    const paramsT = {
        device_no: device_no,
        date: data.date,
        as: data.as,
        manager: data.manager,
        memo: data.memo,
    };

    send(Method.POST, '/deviceHistory', paramsT, success, fail);
};

/**
 * 삭제
 */
export const Delete = (ids, success, fail) => {
    let paramsT = { ids };

    send(Method.DELETE, '/deviceHistory', paramsT, success, fail);
};
