import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    useEffect(() => {}, []);
    const { t } = useTranslation();

    return (
        <footer className="footer-wrapper">
            <div className="inner">
                <h2 className="ft_logo">aw</h2>
                <ul className="ft_menu">
                    <li>
                        <Link to="/service">{t('이용약관')}</Link>
                    </li>
                    <li className="policy">
                        <Link to="/policy">{t('개인정보처리방침')}</Link>
                    </li>
                </ul>
                <address className="t_addr">{t('광주광역시 북구 용봉로 77 (산학협력공학단 602호)')}</address>
                <address className="b_addr">
                    <span>{t('(주)오토웰즈')}</span>
                    <span>{'T. 062 . 372 . 3731'}</span>
                    <span>{'F. 062 . 372 . 3735'}</span>
                    <span>{t('E. andrew.kwak.2050@gmail.com')}</span>
                    <span>All rights reserved.</span>
                </address>
            </div>
        </footer>
    );
};

export default Footer;
