import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestClient, RequestSearchPwd } from '../lib/MyHttp';

const SearchPassword = () => {
    const { t } = useTranslation();
    const [selectType, setSelectType] = useState('phone');
    const [data, setDate] = useState({ id: '', phone: '', email: '', number: '' });

    const clickRequestNumber = () => {
        if (selectType === 'phone') {
            data.email = '';
        } else {
            data.phone = '';
        }

        RequestClient(
            data,
            res => {
                alert(t('요청이 완료 되었습니다.'));
            },
            res => {
                alert(t('정보를 찾을 수 없습니다.'));
            },
        );
    };

    const clickSearch = () => {
        if (selectType === 'phone') {
            data.email = '';
        } else {
            data.phone = '';
        }

        RequestSearchPwd(
            data,
            res => {
                alert(`${t('검색하신 패스워드는')} : ${res.password}`);
                window.location.href = '/';
            },
            res => {
                alert(t('코드값이 일치 하지 않습니다.'));
            },
        );
    };

    return (
        <main className="login_contents">
            <section className="sub-top-section">
                <div className="inBox">
                    <h1 className="logo">
                        <a href="/">{t('효심 로고')}</a>
                    </h1>
                </div>
            </section>

            <div className="inner">
                <section className="search_id_wrapper">
                    <h2>{t('비밀번호 찾기')}</h2>

                    <form>
                        <label>{t('인증방식')}</label>
                        <div className="f-row gap-sm">
                            <button
                                type="button"
                                className={selectType === 'phone' ? 'btn_nor lg black f-grow' : 'btn_nor lg line f-grow'}
                                onClick={() => {
                                    setSelectType('phone');
                                }}
                            >
                                {t('휴대번호')}
                            </button>
                            <button
                                type="button"
                                className={selectType === 'email' ? 'btn_nor lg black f-grow' : 'btn_nor lg line f-grow'}
                                onClick={() => {
                                    setSelectType('email');
                                }}
                            >
                                {t('이메일')}
                            </button>
                        </div>

                        <label>{t('아이디')}</label>
                        <div className="f-row gap-sm">
                            <input
                                type="text"
                                className="text_line"
                                placeholder={t('아이디를 입력하세요.')}
                                id="phone_num"
                                value={data.id}
                                onChange={e => {
                                    data.id = e.target.value;
                                    setDate({ ...data });
                                }}
                            />
                        </div>

                        {selectType === 'phone' && [
                            <label htmlFor="phone_num">{t('휴대폰 번호')}</label>,
                            <div className="f-row gap-sm">
                                <input
                                    type="text"
                                    className="text_line"
                                    placeholder={t('숫자만 입력하세요')}
                                    id="phone_num"
                                    value={data.phone}
                                    onChange={e => {
                                        data.phone = e.target.value;
                                        setDate({ ...data });
                                    }}
                                />
                                <button type="button" className="btn_nor line" onClick={clickRequestNumber}>
                                    {t('인증번호 전송')}
                                </button>
                            </div>,
                        ]}

                        {selectType === 'email' && [
                            <label htmlFor="phone_num">{t('이메일')}</label>,
                            <div className="f-row gap-sm">
                                <input
                                    type="text"
                                    className="text_line"
                                    placeholder={t('이메일을 입력하세요')}
                                    id="phone_num"
                                    value={data.email}
                                    onChange={e => {
                                        data.email = e.target.value;
                                        setDate({ ...data });
                                    }}
                                />
                                <button type="button" className="btn_nor line" onClick={clickRequestNumber}>
                                    {t('인증번호 전송')}
                                </button>
                            </div>,
                        ]}

                        <label htmlFor="auth_num">{t('인증번호 입력')}</label>
                        <div className="f-row gap-sm">
                            <input
                                type="number"
                                className="text_line"
                                placeholder={t('인증번호를 입력하세요.')}
                                id="auth_num"
                                value={data.number}
                                onChange={e => {
                                    data.number = e.target.value;
                                    setDate({ ...data });
                                }}
                            />
                        </div>

                        <div className="btn_bott">
                            <button type="button" className="btn_nor lg orange" onClick={clickSearch}>
                                {t('비밀번호 찾기')}
                            </button>
                        </div>
                        <div className="line_btn_box">
                            <a href="/search/id" className="a_link">
                                {t('아이디를 찾으시나요?')}
                            </a>
                        </div>
                    </form>
                </section>
            </div>
        </main>
    );
};

export default SearchPassword;
