import create from 'zustand';

const useClientStore = create(set => ({
    client: '',
    setClient: client => set({ client: client }),
    onMessage: [],
    addOnMessage: (topic, proc) => {
        set(state => ({
            onMessage: {
                ...state.onMessage,
                [topic]: proc,
            },
        }));
    },
    removeOnMessage: topic => {
        set(state => {
            const onMessage = { ...state.onMessage };
            delete onMessage[topic];
            return { onMessage };
        });
    },
}));

export default useClientStore;
