import moment from 'moment';

/**
 * 타입 성별
 */
export const typeSex = {
    male: '남',
    female: '여',
};

/**
 * 나이계산
 */
export const calculateAge = birthDate => {
    const currentDate = new Date();
    const inputDate = new Date(birthDate);

    if (isNaN(inputDate)) {
        return '00';
    }

    const yearsDifference = currentDate.getFullYear() - inputDate.getFullYear();

    if (currentDate.getMonth() < inputDate.getMonth() || (currentDate.getMonth() === inputDate.getMonth() && currentDate.getDate() < inputDate.getDate())) {
        return yearsDifference - 1;
    } else {
        return yearsDifference;
    }
};

export const formatPhoneNumber = phoneNumber => {
    return phoneNumber ? phoneNumber.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3') : '';
};

export const checkCanPlay = async () => {
    if (navigator.mediaDevices) {
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(() => {
                // const AudioContext = window.AudioContext || window.webkitAudioContext;
                // const audioContext = new AudioContext();
                return true;
            })
            .catch(e => {
                console.log(`Audio permissions denied: ${e}`);
                return false;
            });
    }
};

export const gapTime = time => {
    const sec = moment.duration(moment().diff(time)).asSeconds();

    const date = moment('2020-01-01').add(sec, 'second');

    if (sec < 3600) {
        return date.format('mm:ss');
    } else if (sec < 86400) {
        return date.format('HH:mm:ss');
    } else {
        return (sec / 86400).toFixed() + ' ' + date.format('HH:mm:ss');
    }
};

export const secToString = sec => {
    const date = moment('2020-01-01').add(sec, 'second');

    if (sec < 3600) {
        return date.format('mm:ss');
    } else if (sec < 86400) {
        return date.format('HH:mm:ss');
    } else {
        return (sec / 86400).toFixed() + ' ' + date.format('HH:mm:ss');
    }
};

export const dateLang = {
    Mon: '월',
    Tue: '화',
    Wed: '수',
    Thu: '목',
    Fri: '금',
    Sat: '토',
    Sun: '일',
};
