import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const DaumAddressSearch = ({ onSelect }) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [onSelect]);

    const execDaumPostcode = () => {
        var element_layer = document.getElementById('layer');
        new window.daum.Postcode({
            width: 200,
            oncomplete: data => {
                if (onSelect) {
                    onSelect(data);
                    setShow(false);
                }
            },
        }).embed(element_layer);
        setShow(true);
    };

    return (
        <div>
            <button id="addressButton" type="button" className="btn_nor md line" onClick={execDaumPostcode}>
                {t('우편번호')}
            </button>
            <section className={show ? 'popup_wrap show' : 'popup_wrap'}>
                <div className="pop_base">
                    <div id="layer"></div>
                </div>
            </section>
        </div>
    );
};

export default DaumAddressSearch;
