import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthJoin, upload } from '../lib/MyHttp';
import { SearchId } from '../lib/User';

import CheckidOk from '../popups/checkid_ok';
import CheckidFail from '../popups/checkid_fail';
import JoinOk from '../popups/join_ok';

const years = [];
const month = [];
const days = [];
const nowYYYY = moment().format('YYYY');

for (let i = 1900; i <= nowYYYY; i++) years.push(i);
for (let i = 1; i <= 12; i++) month.push(i);
for (let i = 1; i <= 31; i++) days.push(i);

const Join = () => {
    const { t } = useTranslation();
    const [searchId, setSearchId] = useState('');
    const fileForm = useRef();
    const [showCheckIdOK, setShowCheckIdOK] = useState(false);
    const [showCheckIdFail, setShowCheckIdFail] = useState(false);
    const [showShowJoinOk, setShowJoinOk] = useState(false);

    const [data, setData] = useState({
        id: '',
        pw: '',
        pw2: '',
        name: '',
        name_eng: '',
        sex: 'male',
        birth: moment().subtract(40, 'year').format('YYYY-MM-DD'),
        email: '',
        phone1: '',
        phone2: '',
        phone3: '',
        inst_nm: '',
        state: 'approve',
        type: 'institute user',
        guard_phone: '',
        fb_token: '',
        device_sn: '',
        otp: '',
        otp_dt: '',
        email_otp: '',
        email_dt: '',
        addr1: '',
        addr2: '',
        medical: '',
        agree1: 'N',
        agree2: 'N',
        push: 'N',
    });

    useEffect(() => {}, []);

    // 중복확인
    const clickSearchId = () => {
        if (data.id === '') return;

        SearchId(data.id, res => {
            if (!res.search) {
                setShowCheckIdOK(true);
                // alert(t('사용가능한 아이디 입니다.'));
                setSearchId(data.id);
            } else {
                setShowCheckIdFail(true);
                // alert(t('등록이 불가능한 아이디 입니다.'));
                data.id = '';
                setData({ ...data });
            }
        });
    };

    const onChangeFile = async e => {
        const file = e.target.files[0];
        if (file === undefined) return;

        upload(file, file => {
            data.license = file.location;
            setData({ ...data });
        });
    };

    // 회원가입
    const clickJoin = () => {
        if (data.agree1 !== 'Y' || data.agree2 !== 'Y') {
            alert(t('약관에 동의 해주세요.'));
            return;
        }
        if (data.id === '') {
            alert(t('아이디를 확인 해주세요.'));
            return;
        }
        if (isSafe && (data.pw === '' || data.pw !== data.pw2)) {
            alert(t('패스워드를 확인 해주세요.'));
            return;
        }
        if (data.name === '') {
            alert(t('이름을 확인 해주세요.'));
            return;
        }

        if (data.name_eng === '') {
            alert(t('영문 이름을 확인 해주세요.'));
            return;
        }

        if (data.email === '') {
            alert(t('이메일을 확인 해주세요.'));
            return;
        }
        if (data.phone1 === '' || data.phone2 === '' || data.phone3 === '') {
            alert(t('연락처를 확인 해주세요.'));
            return;
        }

        if (data.inst_nm === '') {
            alert(t('기관명 확인 해주세요.'));
            return;
        }

        if (data.push === 'N') {
            alert(t('푸쉬알림 설정을 확인 해주세요.'));
            return;
        }

        AuthJoin(data, res => {
            // alert(t('회원가입에 성공하였습니다.'));
            setShowJoinOk(true);
        });
    };

    const checkPasswordStrength = password => {
        var lowercaseRegex = /[a-z]/;
        var uppercaseRegex = /[A-Z]/;
        var numberRegex = /\d/;
        var specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

        var hasLowercase = lowercaseRegex.test(password);
        var hasUppercase = uppercaseRegex.test(password);
        var hasNumber = numberRegex.test(password);
        var hasSpecialChar = specialCharRegex.test(password);

        if (hasLowercase && hasUppercase && (hasNumber || hasSpecialChar) && password.length >= 8 && password.length <= 10) {
            return true;
        } else {
            return false;
        }
    };

    const isSafe = checkPasswordStrength(data.pw);
    const disabled = searchId === '' || searchId !== data.id;
    return (
        <main className="login_contents">
            <section className="sub-top-section">
                <div className="inBox">
                    <h1 className="logo">
                        <a href="javascript:;">{t('효심 로고')}</a>
                    </h1>
                </div>
            </section>

            <div className="inner">
                <p className="icon_login">{t('로그인 아이콘')}</p>

                <h2 className="login_title">{t('기관 회원정보 입력')}</h2>

                <h3 className="login_sub_title">
                    {t('회원 정보를 입력해주세요.')} {t('기관은 사업자등록증 등 필요서류를 첨부해주세요')}
                </h3>

                <div className="tbl_top_box join">
                    <p className="ex">
                        <b>*</b> {t('표기된 항목은 필수 입력 항목입니다.')}
                    </p>
                </div>
                <div className="table_box">
                    <table className="tbl_join">
                        <caption>{t('기관 회원가입')}</caption>
                        <colgroup>
                            <col width="30%" />
                            <col width="70%" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>
                                    <label htmlFor="join_name">
                                        {t('이름')} <b>*</b>
                                    </label>
                                </th>
                                <td>
                                    <div className="form-box">
                                        <input
                                            type="text"
                                            className="text_nor md"
                                            id="join_name"
                                            value={data.name}
                                            onChange={e => {
                                                data.name = e.target.value;
                                                setData({ ...data });
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label htmlFor="join_name">
                                        {t('영문 이름')} <b>*</b>
                                    </label>
                                </th>
                                <td>
                                    <div className="form-box">
                                        <input
                                            type="text"
                                            className="text_nor md"
                                            id="join_name"
                                            value={data.name_eng}
                                            onChange={e => {
                                                data.name_eng = e.target.value;
                                                setData({ ...data });
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                            {/* <tr>
                                <th>
                                    <label htmlFor="join_birth">
                                        {t('생년월일')} <b>*</b>
                                    </label>
                                </th>
                                <td>
                                    <div className="form-box birth">
                                        <select
                                            className="select_nor md"
                                            value={moment(data.birth).format('YYYY')}
                                            onChange={e => {
                                                data.birth = moment(data.birth).set('year', e.target.value).format('YYYY-MM-DD');
                                                setData({ ...data });
                                            }}
                                        >
                                            {years.map(x => (
                                                <option value={x}>{x}</option>
                                            ))}
                                        </select>
                                        <span>{t('년')} </span>
                                        <select
                                            className="select_nor md"
                                            value={moment(data.birth).format('M')}
                                            onChange={e => {
                                                data.birth = moment(data.birth)
                                                    .set('month', e.target.value - 1)
                                                    .format('YYYY-MM-DD');
                                                setData({ ...data });
                                            }}
                                        >
                                            {month.map(x => (
                                                <option value={x}>{x}</option>
                                            ))}
                                        </select>
                                        <span>{t('월')} </span>
                                        <select
                                            className="select_nor md"
                                            value={moment(data.birth).format('D')}
                                            onChange={e => {
                                                data.birth = moment(data.birth).set('date', e.target.value).format('YYYY-MM-DD');
                                                setData({ ...data });
                                            }}
                                        >
                                            {days.map(x => (
                                                <option value={x}>{x}</option>
                                            ))}
                                        </select>
                                        <span>{t('일')} </span>
                                    </div>
                                </td>
                            </tr> */}
                            <tr>
                                <th>
                                    <label htmlFor="join_id">
                                        {t('아이디')} <b>*</b>
                                    </label>
                                </th>
                                <td>
                                    <div className="form-box nowrap">
                                        <input
                                            type="text"
                                            className="text_nor md grow"
                                            id="join_id"
                                            value={data.id}
                                            onChange={e => {
                                                data.id = e.target.value;
                                                setData({ ...data });
                                            }}
                                        />
                                        <button type="button" className="btn_nor md line z-sh" onClick={clickSearchId}>
                                            {t('중복확인')}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label htmlFor="join_pwd">
                                        {t('비밀번호')} <b>*</b>
                                    </label>
                                </th>
                                <td>
                                    <div className="form-box">
                                        <input
                                            type="password"
                                            className="text_nor md"
                                            id="join_pwd"
                                            value={data.pw}
                                            maxLength={10}
                                            onChange={e => {
                                                data.pw = e.target.value;
                                                setData({ ...data });
                                            }}
                                        />
                                        {isSafe && <p className="join_alert_text yellow">{t('안전합니다.')}</p>}
                                    </div>
                                    <p className="explain">※ {t('영문 대소문자, 숫자, 특수문자 중 2가지 이상 조합하여 설정해주세요. (8~10자리)')}</p>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label htmlFor="join_pwd2">
                                        {t('비밀번호 확인')} <b>*</b>
                                    </label>
                                </th>
                                <td>
                                    <div className="form-box">
                                        {/* 틀릴경우 alt 클래스 추가 */}
                                        <input
                                            type="password"
                                            className={data.pw !== data.pw2 && data.pw2 !== '' ? 'text_nor md alt' : 'text_nor md'}
                                            id="join_pwd2"
                                            value={data.pw2}
                                            onChange={e => {
                                                data.pw2 = e.target.value;
                                                setData({ ...data });
                                            }}
                                        />
                                        {data.pw !== data.pw2 && data.pw2 !== '' && (
                                            <p className="join_alert_text gray">{t('비밀번호가 일치하지 않습니다.')}</p>
                                        )}
                                    </div>
                                    {/* {data.pw !== data.pw2 && data.pw2 !== '' && (
                                        <p className="explain red">※ {t('비밀번호를 다시 한번 확인해 주시기 바랍니다.')}</p>
                                    )} */}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label>
                                        {t('휴대전화 번호')} <b>*</b>
                                    </label>
                                </th>
                                <td>
                                    <div className="form-box">
                                        <input
                                            className="text_nor md phone"
                                            maxLength={4}
                                            value={data.phone1}
                                            onChange={e => {
                                                data.phone1 = e.target.value;
                                                setData({ ...data });
                                            }}
                                        />
                                        <span>-</span>
                                        <input
                                            className="text_nor md phone"
                                            maxLength={4}
                                            value={data.phone2}
                                            onChange={e => {
                                                data.phone2 = e.target.value;
                                                setData({ ...data });
                                            }}
                                        />
                                        <span>-</span>
                                        <input
                                            className="text_nor md phone"
                                            maxLength={4}
                                            value={data.phone3}
                                            onChange={e => {
                                                data.phone3 = e.target.value;
                                                setData({ ...data });
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label htmlFor="join_email">
                                        {t('이메일')} <b>*</b>
                                    </label>
                                </th>
                                <td>
                                    <div className="form-box">
                                        <input
                                            type="text"
                                            className="text_nor md em_01"
                                            id="join_email"
                                            value={data.email.split('@')[0]}
                                            onChange={e => {
                                                data.email = e.target.value + '@' + (data.email.split('@')[1] || '');
                                                setData({ ...data });
                                            }}
                                        />
                                        <span>@</span>
                                        <input
                                            type="text"
                                            className="text_nor md em_02"
                                            value={data.email.split('@')[1]}
                                            onChange={e => {
                                                data.email = data.email.split('@')[0] + '@' + e.target.value;
                                                setData({ ...data });
                                            }}
                                        />
                                        <select className="select_nor md">
                                            <option value="">{t('직접입력')}</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label htmlFor="join_addr">
                                        {t('주소')} <b>*</b>
                                    </label>
                                </th>
                                <td>
                                    <div className="form-box">
                                        {/* <input type="text" className="text_nor md addr" id="join_addr" /> */}
                                        <input
                                            type="text"
                                            className="text_nor md w100"
                                            value={data.addr1}
                                            onChange={e => {
                                                data.addr1 = e.target.value;
                                                setData({ ...data });
                                            }}
                                        />
                                        <input
                                            type="text"
                                            className="text_nor md w100"
                                            placeholder={t('상세주소를 입력하세요')}
                                            value={data.addr2}
                                            onChange={e => {
                                                data.addr2 = e.target.value;
                                                setData({ ...data });
                                            }}
                                        />
                                    </div>
                                    <p className="explain">※ {t('기기를 사용하고 계시는 곳의 주소를 입력해주세요.')}</p>
                                    <p className="explain">※ {t('주소를 입력하지 않으시면 위급 상황시 긴급 알람이 전송되었더라도 제약을 받을 수 있습니다.')}</p>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label htmlFor="join_push">
                                        {t('푸쉬알림 설정')} <b>*</b>
                                    </label>
                                </th>
                                <td>
                                    <p class="switch">
                                        <input
                                            type="checkbox"
                                            name="confirm"
                                            class="switch_type"
                                            id="join_push"
                                            checked={data.push === 'Y'}
                                            onClick={() => {
                                                data.push = data.push === 'Y' ? 'N' : 'Y';
                                                setData({ ...data });
                                            }}
                                        />
                                        <label htmlFor="join_push"></label>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="tbl_top_box join">
                    <h4 className="tit">{t('기관정보 등록')}</h4>
                    <p className="txt">{t('기관명과 사업자등록증을 첨부해주세요')}</p>
                </div>
                <div className="table_box">
                    <table className="tbl_join">
                        <caption>{t('기관정보 등록')}</caption>
                        <colgroup>
                            <col width="30%" />
                            <col width="70%" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>
                                    <label htmlFor="join_busi">
                                        {t('기관명')} <b>*</b>
                                    </label>
                                </th>
                                <td>
                                    <div className="form-box">
                                        <input
                                            type="text"
                                            className="text_nor md"
                                            id="join_busi"
                                            value={data.inst_nm}
                                            onChange={e => {
                                                data.inst_nm = e.target.value;
                                                setData({ ...data });
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label htmlFor="join_file1">{t('사업자등록증')}</label>
                                </th>
                                <td>
                                    <div className="form-box">
                                        <input type="text" className="text_nor md" value={data.license?.split('/').pop()} disabled={true} />
                                        <input
                                            type="file"
                                            className="text_nor md"
                                            id="join_file1"
                                            onChange={onChangeFile}
                                            ref={fileForm}
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                        />
                                        <button
                                            type="button"
                                            className="btn_nor md line"
                                            onClick={() => {
                                                fileForm.current.click();
                                            }}
                                        >
                                            {t('등록')}
                                        </button>
                                        {data.license && (
                                            <button
                                                type="button"
                                                className="btn_nor md line"
                                                onClick={() => {
                                                    data.license = '';
                                                    setData({ ...data });
                                                }}
                                            >
                                                {t('삭제')}
                                            </button>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="tbl_top_box join">
                    <h4 className="tit">{t('약관 동의 안내')}</h4>
                </div>
                <div className="table_box">
                    <table className="tbl_join">
                        <caption>{t('약관동의안내')}</caption>
                        <colgroup>
                            <col width="30%" />
                            <col width="70%" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>
                                    <label>
                                        {t('약관 동의')} <b>*</b>
                                    </label>
                                </th>
                                <td>
                                    <p className="confirm_row">
                                        <span className="check">
                                            <input
                                                type="checkbox"
                                                id="join_check"
                                                onClick={() => {
                                                    if (data.agree1 === 'Y' && data.agree2 === 'Y') {
                                                        data.agree1 = 'N';
                                                        data.agree2 = 'N';
                                                    } else {
                                                        data.agree1 = 'Y';
                                                        data.agree2 = 'Y';
                                                    }
                                                    setData({ ...data });
                                                }}
                                                checked={data.agree1 === 'Y' && data.agree2 === 'Y'}
                                            />
                                            <label htmlFor="join_check">{t('전체 약관 동의')}</label>
                                        </span>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <th className="blank"></th>
                                <td>
                                    <p className="confirm_row">
                                        <span className="check">
                                            <input
                                                type="checkbox"
                                                id="join_check2"
                                                onClick={() => {
                                                    data.agree1 = data.agree1 === 'Y' ? 'N' : 'Y';
                                                    setData({ ...data });
                                                }}
                                                checked={data.agree1 === 'Y'}
                                            />
                                            <label htmlFor="join_check2">
                                                [{t('필수')}] {t('서비스 이용 약관')}
                                            </label>
                                        </span>
                                        <a href="javascript:;" className="link">
                                            {t('내용보기')}
                                        </a>
                                    </p>
                                    <p className="confirm_row">
                                        <span className="check">
                                            <input
                                                type="checkbox"
                                                id="join_check3"
                                                onClick={() => {
                                                    data.agree2 = data.agree2 === 'Y' ? 'N' : 'Y';
                                                    setData({ ...data });
                                                }}
                                                checked={data.agree2 === 'Y'}
                                            />
                                            <label htmlFor="join_check3">
                                                [{t('필수')}] {t('개인정보 수집 이용 동의')}
                                            </label>
                                        </span>
                                        <a href="javascript:;" className="link">
                                            {t('내용보기')}
                                        </a>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="btn_bott_box" style={{ marginBottom: 20 }}>
                    <Link to="/login" className="btn_nor line minW">
                        {t('돌아가기')}
                    </Link>
                    <button className="btn_nor orange minW" onClick={clickJoin} disabled={disabled}>
                        {t('회원가입')}
                    </button>
                </div>
            </div>

            {showCheckIdOK && (
                <CheckidOk
                    onClose={() => {
                        setShowCheckIdOK(false);
                    }}
                />
            )}
            {showCheckIdFail && (
                <CheckidFail
                    onClose={() => {
                        setShowCheckIdFail(false);
                    }}
                />
            )}
            {showShowJoinOk && (
                <JoinOk
                    onClose={() => {
                        setShowJoinOk(false);
                        window.location.href = '/';
                    }}
                />
            )}
        </main>
    );
};

export default Join;
