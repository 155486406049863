import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const PageBar = props => {
    const { t } = useTranslation();

    /**
     * 페이지 클릭
     * @param {*} page
     */
    const clickNum = page => {
        if (props.reload !== undefined) {
            props.reload(page);
        }
    };

    let items = [];
    let nowPage = props.nowPage;

    let start = nowPage - Math.floor(props.maxSize / 2);
    if (start < 1) start = 1;

    let end = start + props.maxSize;
    if (end > props.totalPage) {
        end = props.totalPage;
        start = end - props.maxSize;
        if (start < 1) start = 1;
    }

    let prev = nowPage - 1;
    if (prev < 1) prev = 1;

    let next = nowPage + 1;
    if (next > props.totalPage) next = props.totalPage;

    for (let number = start; number <= end; number++) {
        const act = number === nowPage ? true : false;

        items.push(
            <a href="javascript:;" className={act ? 'active' : ''} onClick={e => clickNum(number)}>
                {number}
            </a>,
        );
    }

    return (
        <div className="paging">
            <a href="javascript:;" className="btn prevAll" onClick={e => clickNum(1)}>
                {t('맨이전')}
            </a>
            <a href="javascript:;" className="btn prev" onClick={e => clickNum(prev)}>
                {t('이전')}
            </a>
            {items}
            <a href="javascript:;" className="btn next" onClick={e => clickNum(next)}>
                {t('다음')}
            </a>
            <a href="javascript:;" className="btn nextAll" onClick={e => clickNum(props.totalPage)}>
                {t('맨끝')}
            </a>
        </div>
    );
};

PageBar.defaultProps = {
    nowPage: 10,
    totalPage: 10,
    maxSize: 10,
    href: '',
    params: '',
};

PageBar.propTypes = {
    nowPage: PropTypes.number,
    totalPage: PropTypes.number,
    maxSize: PropTypes.number,
    href: PropTypes.string,
    params: PropTypes.string,
};

export default PageBar;
