import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
    BsSearch,
    BsBell,
    BsHouse,
    BsClipboardPulse,
    BsCollection,
    BsPerson,
    BsPersonCheck,
    BsBoxArrowRight,
    BsBrightnessHigh,
    BsXLg,
    BsArrowLeft,
    BsList,
} from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
// import { WiDaySunny } from "react-icons/wi";
// import * as mqtt from 'mqtt/dist/mqtt.min';
import useThemeStore from '../stores/theme';

import { Count } from '../lib/Push';
import { AuthMe } from '../lib/MyHttp';
import { Update } from '../lib/User';

// 로고 이미지
import logoImg from '../assets/ic_logo_admin.png';

// 국기 이미지
import img_flag_kr from '../assets/img_flag_kr.svg';
import img_flag_en from '../assets/img_flag_en.svg';
import img_flag_cn from '../assets/img_flag_cn.svg';
import img_flag_vi from '../assets/img_flag_vi.png';
import img_flag_th from '../assets/img_flag_th.png';
import useWarnningStore from '../stores/warnning';

const languageImage = {
    ko: img_flag_kr,
    en: img_flag_en,
    cn: img_flag_cn,
    vi: img_flag_vi,
    th: img_flag_th,
};

const languageText = {
    ko: 'KOREA',
    en: 'ENGLISH',
    cn: 'CHINESE',
    vi: 'VIETNAM',
    th: 'THAILAND',
};

const Header = () => {
    const navigate = useNavigate();
    const { changeTheme } = useThemeStore();
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    const { setShow } = useWarnningStore();

    const [menuShow, setMenuShow] = useState('');
    const [searchShow, setSearchShow] = useState('');
    const [searchName, setSearchName] = useState('');
    const [langShow, setLangShow] = useState('');
    const [count, setCount] = useState(0);

    const [client, setClient] = useState();

    const [data, setData] = useState({ name: '홍길동', email: 'test@hyosim.co.kr' });
    const [searchParams] = useSearchParams();

    const [searchItem, setSearchItem] = useState([]);
    const [isSearch, setIsSearch] = useState(0);

    useEffect(() => {
        let token = searchParams.get('token');
        if (!!token) {
            localStorage.setItem('fb_token', token);
        } else {
            token = localStorage.getItem('fb_token');
        }

        if (!!token && data) {
            data.fb_token = token;
            Update(
                data,
                res => {
                    localStorage.setItem('fb_token', '');
                },
                res => {},
            );
        }
    }, [searchParams, data]);

    useEffect(() => {
        // mobileMenuHide();
        setTimeout(() => {
            Count(res => {
                console.log('res', res);
                setCount(res.num);
            });
        }, 100);
    }, [pathname]);

    useEffect(() => {
        const tempSearchItem = localStorage.getItem('searchItem');
        if (tempSearchItem) {
            try {
                const datas = JSON.parse(tempSearchItem);
                if (datas) {
                    setSearchItem(datas);
                }
            } catch (error) {}
        }

        AuthMe(res => {
            setShow(res.on_push === 'Y' ? true : false);
            setData(res);
        });

        return () => {};
    }, []);

    function mobileMenuShow() {
        setMenuShow('show');
    }

    function mobileMenuHide() {
        setMenuShow('');
    }

    function mobileSearchShow() {
        setSearchShow('show');
    }

    function mobileSearchHide() {
        setSearchShow('');
    }

    function langMenuToggle() {
        setLangShow(!langShow);
    }

    // const mouseOver = (e) => {
    //     e.currentTarget.classList.add("show");
    // }

    // const mouseOut = (e) =>{
    //     e.currentTarget.classList.remove("show");
    // }

    // useEffect(() => {
    //     if (location.pathname === "/") {
    //         setHeaderClass("main");
    //     } else {
    //         setHeaderClass("sub");
    //     }
    // }, [location]);

    // useEffect( () => {
    //     const rootWrapElem = document.getElementById('root');
    //     rootWrapElem.classList.add('admin_wrap')
    // }, [])

    const clickLogout = () => {
        localStorage.setItem('access_token', '');
        window.location.href = '/';
        // localStorage.setItem("access_token", response["access_token"])
    };

    const clickSearch = () => {
        saveSearchText();
        setSearchName('');
        navigate('/monitoring/realtimelist/' + searchName);
    };

    useEffect(() => {
        if (isSearch > 0) {
            clickSearch();
        }
    }, [isSearch]);

    const saveSearchText = () => {
        if (searchName === '') return;

        const items = searchItem.filter(x => x !== searchName);
        items.unshift(searchName);
        setSearchItem(items.slice(0, 20));

        localStorage.setItem('searchItem', JSON.stringify(items));
    };

    const findNextKey = currentKey => {
        const keys = Object.keys(languageImage);
        const currentIndex = keys.indexOf(currentKey);

        if (currentIndex !== -1) {
            const nextIndex = (currentIndex + 1) % keys.length;
            return keys[nextIndex];
        }
        return keys[0];
    };

    return (
        <>
            {/* 헤더 */}
            <header className="header-wrapper">
                <h1 className="logo">
                    <a href="/">
                        <img src={logoImg} alt="효심 관리자 로고 이미지" />
                    </a>
                </h1>
                <div className="cont">
                    <form className="top_search_form">
                        {/* <select className="search_select">
                            <option value="">회원명</option>
                            <option value="">제목</option>
                        </select> */}
                        <div className="input_box">
                            <input
                                type="text"
                                list="top-search-list"
                                className="search_txt"
                                placeholder={t('회원명을 입력해주세요')}
                                value={searchName}
                                onChange={e => {
                                    setSearchName(e.target.value);
                                    if (!e.nativeEvent.inputType) {
                                        setIsSearch(isSearch + 1);
                                    }
                                }}
                            />
                            {/* 자동완성 - 기존 html 이용*/}
                            <datalist id="top-search-list">
                                {searchItem.map((x, idx) => (
                                    <option value={x}></option>
                                ))}
                            </datalist>
                            <button className="search_btn" title={t('검색')} onClick={clickSearch}>
                                <BsSearch />
                            </button>
                        </div>
                    </form>
                    <div className="profil_box">
                        <p className="alarm">
                            <Link to="/alarm/list">
                                {count > 0 && <span className="number">{count}</span>}
                                <BsBell />
                            </Link>
                        </p>
                        <p className="weather">
                            <a href="javascript:;" onClick={() => changeTheme()}>
                                <BsBrightnessHigh />
                            </a>
                        </p>
                        {/* <p className="language">
                            <a
                                href="javascript:;"
                                onClick={() => {
                                    i18n.changeLanguage(findNextKey(i18n.language));
                                }}
                            >
                                <img src={languageImage[i18n.language]} alt="" />
                            </a>
                        </p> */}
                        <div className="language_02">
                            <div className="language_top_wrapper">
                                {/* show 클래스 추가 시 밑에 언어 리스트 보임 */}
                                <Link className={`language_top_select ${langShow ? 'show' : ''}`} onClick={langMenuToggle}>
                                    {languageText[i18n.language]}
                                </Link>
                                <ul className="language_top_list">
                                    {Object.keys(languageText).map(key => {
                                        if (i18n.language !== key) {
                                            return (
                                                <li
                                                    onClick={() => {
                                                        i18n.changeLanguage(key);
                                                        langMenuToggle();
                                                    }}
                                                >
                                                    <Link>{languageText[key]}</Link>
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                            </div>
                        </div>
                        <p className="name">
                            <span>{data.name}</span>
                            <span>{data.email}</span>
                        </p>
                        <div className="pics">
                            <img src={data.image ? data.image : 'https://picsum.photos/200/300'} alt="프로필" />
                        </div>
                    </div>
                </div>
                <div className="mobile_buttons">
                    {window.history.state.idx > 0 && (
                        <button
                            type="button"
                            title={t('뒤로가기')}
                            onClick={() => {
                                window.history.back();
                            }}
                        >
                            <BsArrowLeft />
                        </button>
                    )}
                    <div className="language_02">
                        <div className="language_top_wrapper">
                            {/* show 클래스 추가 시 밑에 언어 리스트 보임 */}
                            <Link className={`language_top_select ${langShow ? 'show' : ''}`} onClick={langMenuToggle}>
                                {languageText[i18n.language]}
                            </Link>
                            <ul className="language_top_list">
                                {Object.keys(languageText).map(key => {
                                    if (i18n.language !== key) {
                                        return (
                                            <li
                                                onClick={() => {
                                                    i18n.changeLanguage(key);
                                                    langMenuToggle();
                                                }}
                                            >
                                                <Link>{languageText[key]}</Link>
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                    <button type="button" title={t('모바일 메뉴')} onClick={mobileMenuShow}>
                        <BsList />
                    </button>
                </div>
            </header>

            {/* 
                메뉴 
                모바일에서 클래스 show를 붙이면 표시
            */}
            <nav className={`nav-wrapper ${menuShow}`}>
                <div className="mobile top_btns">
                    <button type="button" title={t('검색')} onClick={mobileSearchShow}>
                        <BsSearch />
                    </button>
                    <button type="button" title={t('닫기')} onClick={mobileMenuHide}>
                        <BsXLg />
                    </button>
                </div>
                <ul className="gnb">
                    {/* 메인메뉴 선택되면 li에 클래스 active 추가 */}
                    <li>
                        <Link to="/" onClick={mobileMenuHide}>
                            <span className="icon">
                                <BsHouse />
                            </span>
                            <span className="text">{t('효심')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/monitoring/realtimelist" onClick={mobileMenuHide}>
                            <span className="icon">
                                <BsClipboardPulse />
                            </span>
                            <span className="text">{t('사용자 모니터링')}</span>
                        </Link>
                        {/* 서브메뉴 선택되면 li에 클래스 active 추가 */}
                        {/* <ul className="sub_menu">
                            <li className="">
                                <Link to="/monitoring/realtimelist">사용자 리스트</Link>
                            </li>
                            <li>
                                <Link to="/monitoring/detail">실시간 보기</Link>
                            </li>
                        </ul> */}
                    </li>
                    <li>
                        <Link to="/equipment/list">
                            <span className="icon">
                                <BsCollection />
                            </span>
                            <span className="text">{t('장비관리')}</span>
                        </Link>
                        <ul className="sub_menu">
                            <li>
                                <Link to="/equipment/list" onClick={mobileMenuHide}>
                                    {t('장비목록')}
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/member/list">
                            <span className="icon">
                                <BsPersonCheck />
                            </span>
                            <span className="text">{t('회원관리')}</span>
                        </Link>
                        <ul className="sub_menu">
                            <li>
                                <Link to="/member/list" onClick={mobileMenuHide}>
                                    {t('회원목록')}
                                </Link>
                            </li>
                            <li>
                                <Link to="/member/update" onClick={mobileMenuHide}>
                                    {t('회원등록')}
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/mypage/view" onClick={mobileMenuHide}>
                            <span className="icon">
                                <BsPerson />
                            </span>
                            <span className="text">{t('마이페이지')}</span>
                        </Link>
                    </li>
                    <li className="logout">
                        <Link to="/" onClick={clickLogout}>
                            <span className="icon">
                                <BsBoxArrowRight />
                            </span>
                            <span className="text">{t('로그아웃')}</span>
                        </Link>
                    </li>
                    <li className="mobile">
                        <Link to="/alarm/list" onClick={mobileMenuHide}>
                            <span className="icon">
                                <BsBell />
                            </span>
                            <span className="text">{t('알림센터')}</span>
                        </Link>
                    </li>
                </ul>
                <div className="mobile bott_btns">
                    {/* <button type="button" className="btn_mo_login orange">
                        {t('로그인')}
                    </button>
                    <p className="text">{t('효심이 처음이신가요')}?</p>
                    <button type="button" className="btn_mo_login line">
                        {t('회원가입')}
                    </button> */}
                    <button type="button" className="btn_mo_login orange" onClick={clickLogout}>
                        {t('로그아웃')}
                    </button>
                </div>
            </nav>

            {/* 
                모바일 검색 팝업 
                모바일 검색 버튼을 클릭하면 - 클래스 show추가
            */}
            <section className={`pops-mobile-top-search ${searchShow}`}>
                <header>
                    <h2>{t('검색')}</h2>
                    <button type="button" className="btn_close" title={t('닫기')} onClick={mobileSearchHide}>
                        <BsXLg />
                    </button>
                </header>
                <div className="contents">
                    <form className="moTopSearchForm">
                        {/* <div className="select_box">
                            <select>
                                <option>{t('회원명')}</option>
                            </select>
                        </div> */}
                        <div className="input_box">
                            <input
                                type="text"
                                className="search_txt"
                                placeholder={t('회원명을 입력해주세요')}
                                value={searchName}
                                onChange={e => {
                                    setSearchName(e.target.value);
                                }}
                            />
                            <button type="submit" title={t('검색')} onClick={clickSearch}>
                                <BsSearch />
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
};

export default Header;
