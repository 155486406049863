import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Get, Update, Create, SearchId } from '../lib/User';
import { SearchDevice } from '../lib/Device';
import { GetList as GetListGroup } from '../lib/Group';
import moment from 'moment';
import Group from '../components/group';
import { upload } from '../lib/MyHttp';

const years = [];
const month = [];
const days = [];

const nowYYYY = moment().format('YYYY');

for (let i = 1900; i <= nowYYYY; i++) years.push(i);
for (let i = 1; i <= 12; i++) month.push(i);
for (let i = 1; i <= 31; i++) days.push(i);

const MemberUpdate = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams();
    const fileForm = useRef();

    const [data, setData] = useState({});
    const [searchId, setSearchId] = useState('');
    const [searchDeviceSn, setSearchDeviceSn] = useState('');
    const [showGroup, setShowGroup] = useState(false);

    const [groups, setGroups] = useState([]);

    useEffect(() => {
        refreshGroup();
    }, []);

    const refreshGroup = () => {
        GetListGroup({}, res => {
            setGroups(res);
        });
    };

    useEffect(() => {
        if (id) {
            Get(id, res => {
                if (res.phone?.length >= 0) res.phone1 = res.phone.substr(0, 3);
                if (res.phone?.length >= 4) res.phone2 = res.phone.substr(3, 4);
                if (res.phone?.length >= 9) res.phone3 = res.phone.substr(7, 4);
                setData(res);
            });
        }
    }, [id]);

    const onChangeFile = async e => {
        const file = e.target.files[0];
        if (file === undefined) return;

        upload(file, file => {
            data.image = file.location;
            setData({ ...data });
        });
    };

    const clickSave = () => {
        data.phone = data.phone1 + data.phone2 + data.phone3;
        if (id) {
            if (data.pw !== '' && data.pw !== data.pw2) {
                alert(t('패스워드를 확인 해주세요.'));
                return;
            }
            if (data.phone1 === '') {
                alert(t('연락처를 확인 해주세요.'));
                return;
            }

            Update(data, res => {
                alert(t('저장을 완료 하였습니다.'));
                navigate('/member/list');
            });
        } else {
            if (data.id === '') {
                alert(t('아이디를 확인 해주세요.'));
                return;
            }
            if (data.pw === '' || data.pw !== data.pw2) {
                alert(t('패스워드를 확인 해주세요.'));
                return;
            }
            if (data.phone1 === '') {
                alert(t('연락처를 확인 해주세요.'));
                return;
            }

            Create(data, res => {
                alert(t('저장을 완료 하였습니다.'));
                navigate('/member/list');
            });
        }
    };

    const clickSearchId = () => {
        SearchId(data.id, res => {
            if (!res.search) {
                alert(t('사용가능한 아이디 입니다.'));
                setSearchId(data.id);
            } else {
                alert(t('등록이 불가능한 아이디 입니다.'));
                data.id = '';
                setData({ ...data });
            }
        });
    };

    const clickSearch = () => {
        SearchDevice(
            data.device_sn,
            res => {
                if (!res.search) {
                    alert(t('등록되지 않는 장비 입니다.'));
                    data.device_sn = '';
                    setData({ ...data });
                } else {
                    if (res.regUser) {
                        alert(t('사용중인 장비 입니다.'));
                        data.device_sn = '';
                        setData({ ...data });
                    } else {
                        setSearchDeviceSn(data.device_sn);
                        alert(t('등록 가능한 장비 입니다.'));
                    }
                }
            },
            res => {
                alert(t('등록이 불가능한 장비입니다.'));
            },
        );
    };

    const disabled = !id && (searchId === '' || searchId !== data.id);
    return (
        <main className="admin-contents">
            <h2 className="sub_title">{t('회원등록')}</h2>

            <div className="table_box">
                <table className="tbl_join">
                    <caption>{t('회원정보수정')}</caption>
                    <colgroup>
                        <col width="25%" />
                        <col width="85%" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>
                                <label htmlFor="member_group">{t('회원그룹')}</label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    {/* <input type="text" className="text_nor md" id="member_group" placeholder="{t('그룹을 선택하세요')}" /> */}
                                    <select
                                        className="select_nor md"
                                        style={{ flex: 1 }}
                                        value={data.group_no}
                                        onChange={e => {
                                            data.group_no = e.target.value;
                                            setData({ ...data });
                                        }}
                                    >
                                        <option value="">{t('그룹없음')}</option>
                                        {groups.map(x => {
                                            return <option value={x.group_no}>{x.name}</option>;
                                        })}
                                    </select>

                                    <div className="pops_puts">
                                        <button type="button" className="btn_nor md line" onClick={() => setShowGroup(!showGroup)}>
                                            {t('그룹설정')}
                                        </button>
                                        {showGroup && (
                                            <Group
                                                right={true}
                                                onClose={() => {
                                                    setShowGroup(false);
                                                }}
                                                onRefresh={() => {
                                                    refreshGroup();
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label htmlFor="member_name">{t('아이디')}</label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input
                                        type="text"
                                        className="text_nor md"
                                        id="member_name"
                                        placeholder={t('아이디를 입력해주세요')}
                                        value={data.id}
                                        onChange={e => {
                                            data.id = e.target.value;
                                            setData({ ...data });
                                        }}
                                        disabled={id ? true : false}
                                    />
                                    {!id && (
                                        <button type="button" className="btn_nor md line" onClick={clickSearchId}>
                                            {t('검색')}
                                        </button>
                                    )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label htmlFor="member_name">{t('비밀번호')}</label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input
                                        type="password"
                                        className="text_nor md"
                                        id="member_name"
                                        placeholder={t('비밀번호 입력해주세요')}
                                        value={data.pw}
                                        onChange={e => {
                                            data.pw = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label htmlFor="member_name">{t('비밀번호 확인')}</label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input
                                        type="password"
                                        className="text_nor md"
                                        id="member_name"
                                        placeholder={t('비밀번호 입력해주세요')}
                                        value={data.pw2}
                                        onChange={e => {
                                            data.pw2 = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label htmlFor="member_name">{t('회원명')}</label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input
                                        type="text"
                                        className="text_nor md"
                                        id="member_name"
                                        placeholder={t('이름을 입력해주세요')}
                                        value={data.name}
                                        onChange={e => {
                                            data.name = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label htmlFor="member_name">{t('사진')}</label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input type="text" className="text_nor md" value={data.image?.split('/').pop()} disabled={true} />
                                    <input
                                        type="file"
                                        className="text_nor md"
                                        id="member_name"
                                        onChange={onChangeFile}
                                        ref={fileForm}
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                    />
                                    <button
                                        type="button"
                                        className="btn_nor md line"
                                        onClick={() => {
                                            fileForm.current.click();
                                        }}
                                    >
                                        {t('등록')}
                                    </button>
                                    {data.image && (
                                        <button
                                            type="button"
                                            className="btn_nor md line"
                                            onClick={() => {
                                                data.image = '';
                                                setData({ ...data });
                                            }}
                                        >
                                            {t('삭제')}
                                        </button>
                                    )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label htmlFor="member_birth">{t('생년월일')}</label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <select
                                        className="select_nor md"
                                        value={moment(data.birth).format('YYYY')}
                                        onChange={e => {
                                            data.birth = moment(data.birth).set('year', e.target.value).format('YYYY-MM-DD');
                                            setData({ ...data });
                                        }}
                                    >
                                        {years.map(x => (
                                            <option value={x}>{x}</option>
                                        ))}
                                    </select>
                                    <span>{t('년')} </span>
                                    <select
                                        className="select_nor md"
                                        value={moment(data.birth).format('M')}
                                        onChange={e => {
                                            data.birth = moment(data.birth)
                                                .set('month', e.target.value - 1)
                                                .format('YYYY-MM-DD');
                                            setData({ ...data });
                                        }}
                                    >
                                        {month.map(x => (
                                            <option value={x}>{x}</option>
                                        ))}
                                    </select>
                                    <span>{t('월')} </span>
                                    <select
                                        className="select_nor md"
                                        value={moment(data.birth).format('D')}
                                        onChange={e => {
                                            data.birth = moment(data.birth).set('date', e.target.value).format('YYYY-MM-DD');
                                            setData({ ...data });
                                        }}
                                    >
                                        {days.map(x => (
                                            <option value={x}>{x}</option>
                                        ))}
                                    </select>
                                    <span>{t('일')} </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>{t('성별')}</label>
                            </th>
                            <td>
                                <div className="form-box gap-lg text">
                                    <span>
                                        <label>
                                            <input
                                                type="radio"
                                                name="gender"
                                                className="radio1"
                                                checked={data.sex === 'male'}
                                                onClick={() => {
                                                    data.sex = 'male';
                                                    setData({ ...data });
                                                }}
                                            />{' '}
                                            {t('남')}
                                        </label>
                                    </span>
                                    <span>
                                        <label>
                                            <input
                                                type="radio"
                                                name="gender"
                                                className="radio1"
                                                checked={data.sex === 'female'}
                                                onClick={() => {
                                                    data.sex = 'female';
                                                    setData({ ...data });
                                                }}
                                            />{' '}
                                            {t('여')}
                                        </label>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>{t('휴대전화 번호')}</label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input
                                        className="text_nor md phone"
                                        maxLength={4}
                                        value={data.phone1}
                                        onChange={e => {
                                            data.phone1 = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                    <span>-</span>
                                    <input
                                        className="text_nor md phone"
                                        maxLength={4}
                                        value={data.phone2}
                                        onChange={e => {
                                            data.phone2 = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                    <span>-</span>
                                    <input
                                        className="text_nor md phone"
                                        maxLength={4}
                                        value={data.phone3}
                                        onChange={e => {
                                            data.phone3 = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label htmlFor="member_addr">{t('주소')}</label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input
                                        type="text"
                                        className="text_nor md w100"
                                        value={data.addr1}
                                        onChange={e => {
                                            data.addr1 = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                    <input
                                        type="text"
                                        className="text_nor md w100"
                                        placeholder={t('상세주소를 입력하세요')}
                                        value={data.addr2}
                                        onChange={e => {
                                            data.addr2 = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label htmlFor="member_partner">{t('보호자')}</label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input
                                        type="text"
                                        className="text_nor md w100"
                                        id="member_partner"
                                        placeholder={t('이름을 입력해주세요.')}
                                        value={data.guard_name}
                                        onChange={e => {
                                            data.guard_name = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                    <input
                                        type="text"
                                        className="text_nor md w100"
                                        id="member_partner"
                                        placeholder={t('휴대전화 번호를 입력해주세요.')}
                                        value={data.guard_phone}
                                        onChange={e => {
                                            data.guard_phone = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                        {/* <tr>
                            <th>
                                <label>{t('인터넷 설치 여부')}</label>
                            </th>
                            <td>
                                <div className="form-box gap-lg text">
                                    <span>
                                        <label>
                                            <input
                                                type="radio"
                                                name="internet"
                                                className="radio1"
                                                checked={data.set_internet === 'Y'}
                                                onClick={() => {
                                                    data.set_internet = 'Y';
                                                    setData({ ...data });
                                                }}
                                            />{' '}
                                            {t('설치 됨')}
                                        </label>
                                    </span>
                                    <span>
                                        <label>
                                            <input
                                                type="radio"
                                                name="internet"
                                                className="radio1"
                                                checked={data.set_internet === 'N'}
                                                onClick={() => {
                                                    data.set_internet = 'N';
                                                    setData({ ...data });
                                                }}
                                            />{' '}
                                            {t('안됨')}
                                        </label>
                                    </span>
                                </div>
                            </td>
                        </tr> */}
                        {/* <tr>
                            <th>
                                <label>{t('주거형태')}</label>
                            </th>
                            <td>
                                <div className="form-box gap-lg text">
                                    <span>
                                        <label>
                                            <input type="radio" name="home" className="radio1" checked /> {t('주택')}
                                        </label>
                                    </span>
                                    <span>
                                        <label>
                                            <input type="radio" name="home" className="radio1" /> {t('아파트')}
                                        </label>
                                    </span>
                                </div>
                                <div className="form-box maxW">
                                    <input type="text" className="text_nor md" placeholder={t('거주형태를 입력하세요.')} />
                                    <button type="button" className="btn_nor md line">
                                        {t('검색')}
                                    </button>
                                </div>
                            </td>
                        </tr> */}
                        <tr>
                            <th>
                                <label htmlFor="member_deviceId">{t('장비명')}(ID)</label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input
                                        type="text"
                                        className="text_nor md"
                                        id="member_deviceId"
                                        placeholder={t('장비명')}
                                        value={data.device_sn}
                                        onChange={e => {
                                            data.device_sn = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                    <button type="button" className="btn_nor md line" onClick={clickSearch}>
                                        {t('검색')}
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label htmlFor="member_memo">{t('특이사항')}</label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <textarea
                                        className="textarea_nor w100"
                                        placeholder={t('내용을 입력하세요')}
                                        id="member_memo"
                                        value={data.medical}
                                        onChange={e => {
                                            data.medical = e.target.value;
                                            setData({ ...data });
                                        }}
                                    ></textarea>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="btn_bott_box">
                <a href="/member/list" className="btn_nor line minW">
                    {t('취소하기')}
                </a>
                <a href="javascript:;" className={disabled ? 'btn_nor gray minW' : 'btn_nor black minW'} onClick={clickSave} disabled={disabled}>
                    {t('등록하기')}
                </a>
            </div>
        </main>
    );
};

export default MemberUpdate;
