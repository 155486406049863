import React, { useState, useEffect } from 'react';

const Policy = () => {
    useEffect(() => {}, []);

    return (
        <main className="admin-contents">
            <h2 className="sub_title">(주) 오토웰즈 개인정보 처리방침</h2>

            <div className="policy_contents_wrap">
                <p className="text">
                    ㈜오토웰즈는 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게
                    관리하고 있습니다. 이에 「개인정보 보호법」 제30조에 따라 보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을
                    신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
                </p>
                <h4 className="sub_tit">제1조 개인 정보의 처리 목적</h4>
                <p className="text">
                    (주)오토웰즈는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
                    변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                </p>
                <p className="text">
                    1. 회원 가입 및 관리
                    <br />
                    회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보 처리 시
                    법정대리인의 동의여부 확인, 각종 고지 · 통지, 고충처리 목적으로개인정보를 처리합니다.
                    <br />
                    <br />
                    2. 재화 또는 서비스 제공
                    <br />
                    물품배송, 서비스 제공, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증의 목적으로 개인정보를 처리합니다.
                    <br />
                    <br />
                    3. 생체 정보
                    <br />
                    개인의 생체 정보(심박수, 호흡수, 재실 감지 값)를 이상 판별 감지 시스템 연구 목적으로 개인정보를 처리합니다.
                </p>
                <h4 className="sub_tit">제2조 개인정보의 처리 및 보유 기간</h4>
                <p className="text">
                    ① ㈜오토웰즈는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서
                    개인정보를 처리·보유합니다.
                    <br />② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
                </p>
                <p className="sm_text">
                    1. 홈페이지 회원 가입 및 관리 : 사업자/단체 홈페이지 탈퇴 시까지 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
                    <br />
                    1) 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까지
                    <br />
                    <br />
                    2. 재화 또는 서비스 제공 : 재화·서비스 공급완료 및 요금결제·정산 완료시까지 다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지
                    <br />
                    1) 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시·광고, 계약내용 및 이행 등 거래에 관한 기록
                    <br />
                    - 표시·광고에 관한 기록 : 6개월
                    <br />
                    - 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년 <br />
                    - 소비자 불만 또는 분쟁처리에 관한 기록 : 3년
                    <br />
                    <br />
                    2) 「통신비밀보호법」에 따른 통신사실확인자료 보관
                    <br />
                    - 가입자 전기통신일시, 개시·종료시간, 상대방 가입자번호, 사용도수, 발신기지국 위치추적자료 : 1년 <br />
                    - 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월
                    <br />
                    <br />
                    3) 「생체인식 특징정보」 민감정보인 특징정보는 개인정보 보호법 제23조 민감정보의 처리
                    <br />
                    <br />
                    3. 생체 정보: 회원 탈퇴 시까지 <br />
                    1) 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까지
                </p>
                <h4 className="sub_tit">제3조 처리하는 개인정보의 항목</h4>
                <p className="text">㈜오토웰즈는 다음의 개인정보 항목을 처리하고 있습니다.</p>
                <p className="text">
                    1. 회원 가입 및 관리 - 성명, 생년월일, 주소, 전화번호, 이메일주소
                    <br />
                    2. 재화 또는 서비스 제공 - 성명, 생년월일, 주소, 전화번호, 이메일주소
                    <br />
                    3. 생체 정보 - 개인의 생체 정보(심박수, 호흡수, 재실 감지 값)
                </p>
                <div className="table_box">
                    <table className="tbl_policy">
                        <colgroup>
                            <col width="15%" />
                            <col width="auto" />
                            <col width="20%" />
                            <col width="20%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>구분</th>
                                <th>수집목적</th>
                                <th>수집항목</th>
                                <th>보유 및 이용기간</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>회원 가입 및 관리</td>
                                <td>
                                    회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별‧인증, 회원자격 유지‧관리, 서비스 부정이용 방지, 만 14세 미만 아동의
                                    개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지‧통지, 고충처리 목적
                                </td>
                                <td>성명, 생년월일, 주소, 전화번호, 이메일 주소</td>
                                <td>
                                    회원 탈퇴 시까지 <br />※ 단, 관계 법령 위반에 따른 수사, 조사 등이 진행 중인 경우에는 해당 수사, 조사 종료시 까지
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    재화 또는 <br />
                                    서비스 제공
                                </td>
                                <td>물품배송, 서비스 제공, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증의 목적으로 개인정보를 처리합니다.</td>
                                <td>성명, 생년월일, 주소, 전화번호, 이메일 주소</td>
                                <td>
                                    재화 · 서비스 공급완료 및 요금결제 · 정산 완료시까지 <br />※ 단, 관계 법령에 따라 파기하지 않고 보존하여야 하는 경우에는
                                    해당 기간까지
                                </td>
                            </tr>
                            <tr>
                                <td>생체 정보</td>
                                <td>이상 판별 감지 시스템 연구</td>
                                <td>심박수, 호흡수, 재실감지 값</td>
                                <td>
                                    회원 탈퇴 시까지 <br />※ 단, 관계 법령 위반에 따른 수사, 조사 등이 진행 중인 경우에는 해당 수사, 조사 종료시 까지
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h4 className="sub_tit">제4조 개인정보의 제3자 제공에 관한 사항</h4>
                <p className="text">
                    ① ㈜오토웰즈는 정보주체의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서 만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등
                    「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지
                    않습니다. <br />② ㈜오토웰즈는 원활한 서비스 제공을 위해 다음의 경우 정보주체의 동의를 얻어 필요 최소한의 범위로만 제공합니다.
                </p>
                <div className="table_box">
                    <table className="tbl_policy">
                        <colgroup>
                            <col width="15%" />
                            <col width="15%" />
                            <col width="auto" />
                            <col width="40%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>구분</th>
                                <th>수집목적</th>
                                <th>수집항목</th>
                                <th>보유 및 이용기간</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>국가 기관</td>
                                <td>특정정보 제공</td>
                                <td>성명, 생년월일, 주소, 전화번호, 이메일 주소, 생체정보 </td>
                                <td>
                                    회원 탈퇴 시까지 <br />※ 단, 관계 법령 위반에 따른 수사, 조사 등이 진행 중인 경우에는 해당 수사, 조사 종료시 까지
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p className="text">
                    ③ ㈜오토웰즈는 다음과 같이 재난, 감염병, 급박한 생명·신체 위험을 초래하는 사건· 사고, 급박한 재산 손실 등의 긴급상황이 발생하는 경우 정보
                    주체의 동의 없이 관계기관에 개인 정보를 제공할 수 있습니다.
                </p>
                <div className="table_box">
                    <table className="tbl_policy">
                        <colgroup>
                            <col width="15%" />
                            <col width="15%" />
                            <col width="15%" />
                            <col width="auto" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>구분</th>
                                <th>근거법령</th>
                                <th>제공 기관</th>
                                <th>제공되는 개인정보</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>자살위험자 보호</td>
                                <td>자살예방법</td>
                                <td>
                                    경찰관서 <br />
                                    소방관서
                                </td>
                                <td>
                                    • (정보통신서비스 제공자인 경우 기재) 긴급구조대상자의 성명, 주민등록번호(주민등록번호가 없는 경우 생년월 일), 주소,
                                    전화번호, 아이디, 전자우편주소 및 개인위치정보
                                </td>
                            </tr>
                            <tr>
                                <td>긴급 구조요청 등 대응</td>
                                <td>위치정보법</td>
                                <td>소방관서</td>
                                <td>• 개인위치정보</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h4 className="sub_tit">제5조 개인정보의 파기 절차 및 방법에 관한 사항</h4>
                <p className="text">
                    ① ㈜오토웰즈는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. <br />②
                    정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는
                    경우에는, 해당 개인정보를 별도의 데이터 베이스(DB)로 옮기거나보관장소를 달리하여 보존합니다.
                    <br />③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
                </p>
                <p className="text">
                    1. 파기절차
                    <br />
                    ㈜오토웰즈는 파기 사유가 발생한 개인정보를 선정하고, '개인정보처리자명'의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
                    <br />
                    <br />
                    2. 파기방법
                    <br />
                    ㈜오토웰즈는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록· 저장된 개인정보는 분쇄기로
                    분쇄하거나 소각하여 파기합니다.
                </p>
                <p className="sub_tit">제6조 정보주체와 법정대리인의 권리‧의무 및 행사방법에 관한 사항</p>
                <p className="text">
                    ① 정보주체는 ㈜오토웰즈에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
                    <br />
                    ※ 만 14세 미만 아동에 관한 개인정보의 열람등 요구는 법정대리인이 직접 해야 하며, 만 14세 이상의 미성년자인 정보주체는 정보주체의 개인정보에
                    관하여 미성년자 본인이 권리를 행사하거나 법정대리인을 통하여 권리를 행사할 수도 있습니다.
                    <br />
                    ② 권리 행사는 ㈜오토웰즈에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며,
                    ㈜오토웰즈는 이에 대해 지체없이 조치하겠습니다.
                    <br />
                    ③ 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다. 이 경우 “개인정보 처리 방법에 관한
                    고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
                    <br />
                    ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
                    <br />
                    ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
                    <br />⑥ ㈜오토웰즈는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
                    대리인인지를 확인합니다.
                </p>
                <h4 className="sub_tit">제7조 개인정보의 안전성 확보조치에 관한 사항</h4>
                <p className="text">
                    1. 관리적 조치 : 내부관리계획 수립·시행, 전담조직 운영, 정기적 직원 교육
                    <br />
                    2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 개인정보의 암호화, 보안프로그램 설치 및 갱신
                    <br />
                    3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제
                </p>
                <h4 className="sub_tit">제8조 개인정보를 자동으로 수집하는 장치의 설치‧운영 및 그 거부에 관한 사항 – 해당시</h4>
                <p className="text">&lt;개인정보처리자명&gt;은 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.</p>
                <h4 className="sub_tit">제9조 개인정보 보호책임자에 관한 사항</h4>
                <p className="text">
                    ① ㈜오토웰즈는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이
                    개인정보 보호책임자를 지정 하고 있습니다.
                </p>
                <p className="sm_text">
                    ‣ 개인정보 보호책임자 <br />
                    성명 : 곽건호
                    <br />
                    직책 : 대표이사
                    <br />
                    연락처 : 010-3287-3222
                    <br />
                    전화번호 : 062-372-3731
                    <br />
                    이메일 : andrew.kwak.2025@gmail.com
                    <br />
                    팩스번호 : 062-372-3735
                    <br />※ 개인정보보호 담당부서로 연결됩니다.
                </p>
                <h4 className="sub_tit">제10조 개인정보의 열람청구를 접수 ‧ 처리하는 부서</h4>
                <p className="text">
                    정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. <br />
                    ㈜오토웰즈는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
                </p>
                <p className="sm_text">
                    ‣ 개인정보 열람청구 접수·처리 부서 <br />
                    부서명 : 임원실 <br />
                    담당자 : 곽건호 <br />
                    연락처 : 010-3287-3222, 사무실 전화번호 : 062-372-3731 <br />
                    이메일 : andrew.kwak.2025@gmail.com <br />
                    팩스번호 : 062-372-3735
                </p>
                <h4 className="sub_tit">제11조 정보주체의 권익침해에 대한 구제방법</h4>
                <p className="text">
                    ① 정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고 센터센터 등에 분쟁해결이나
                    상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보 침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
                </p>
                <p className="sm_text">
                    1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
                    <br />
                    2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
                    <br />
                    3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
                    <br />
                    4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
                </p>
                <p className="text">
                    ② ㈜오토웰즈는 정보주체의 개인정보자기결정권을 보장하고, 개인정보침해로 인한 상담 및 피해 구제를 위해 노력하고 있으며, 신고나 상담이 필요한
                    경우 아래의 담당부서로 연락해 주시기 바랍니다.
                </p>
                <p className="sm_text">
                    ‣ 개인정보보호 관련 고객 상담 및 신고 <br />
                    부서명 : 임원실
                    <br />
                    담당자 : 곽건호
                    <br />
                    연락처 : 010-3287-3222, 사무실 전화번호 : 062-372-3731 <br />
                    이메일 : andrew.kwak.2025@gmail.com
                    <br />
                    팩스번호 : 062-372-3735
                </p>
                <p className="text">
                    ③ 「개인정보 보호법」 제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여
                    공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수
                    있습니다.{' '}
                </p>
                <p className="sm_text"> ‣ 중앙행정심판위원회 : (국번없이) 110 (www.simpan.go.kr)</p>
                <h4 className="sub_tit">제12조 개인정보 처리방침의 변경에 관한 사항</h4>
                <p className="text">
                    ① 이 개인정보 처리방침은 2022. 11. 15부터 적용됩니다. <br />② 추후 법령∙정책 또는 보안기술의 변경에 따라 내용의 추가∙삭제 및 수정이 있을
                    시에는 회사의 홈페이지를 통해 변경 사유 및 내용 등을 공지하도록 하겠습니다.
                </p>
            </div>

            <div className="btn_bott_box">
                <a href="/" className="btn_nor line minW">
                    돌아가기
                </a>
            </div>
        </main>
    );
};

export default Policy;
