import { Method, send } from './MyHttp';

/**
 * 리스트
 */
export const GetList = (params, success, fail) => {
    let paramsT = { ...params };

    send(Method.GET, '/push', paramsT, success, fail);
};

export const GetListRead = (params, success, fail) => {
    let paramsT = { ...params };

    send(Method.GET, '/push/read', paramsT, success, fail);
};

export const Count = (success, fail) => {
    send(Method.GET, '/push/count', {}, success, fail);
};
