import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GetListRead } from '../lib/Push';
import moment from 'moment';
import PageBar from '../components/pagebar';

const pushImage = {
    HEART: 'heart',
    RESP: 'breath',
    FSR: 'oxygen',
    HEARTE: 'heart_dise',
    RESPE: 'breath_dise',
    SLEEP: 'sleep_dise',
};

const AlarmList = () => {
    const { t } = useTranslation();
    const [items, setItems] = useState([]);

    const [range, setRange] = useState({ size: 10, start: 0, end: 29, total: 0 });
    const [data, setData] = useState({
        page: 1,
        size: '10',
        sort: '-push_read_no',
        group_no: [],
    });

    useEffect(() => {
        GetListRead(data, res => {
            setItems(res.rows);
            setRange(res.range);
        });
    }, [data]);

    return (
        <main className="admin-contents">
            <h2 className="sub_title flex">
                {t('알림센터')}
                <div className="rb">
                    <select
                        className="select_nor md"
                        onChange={e => {
                            data.size = e.target.value;
                            setData({ ...data });
                        }}
                    >
                        <option value="10">{t('10건')}</option>
                        <option value="20">{t('20건')}</option>
                        <option value="30">{t('30건')}</option>
                    </select>
                    <button type="button" className="btn_nor md">
                        {t('삭제')}
                    </button>
                </div>
            </h2>

            <div className="table_box">
                <table className="tbl_emergency">
                    <caption>{t('긴급 알람 이력')}</caption>
                    <colgroup>
                        <col width="5%" />
                        <col width="8%" />
                        <col width="10%" />
                        <col width="auto" />
                        <col width="20%" />
                    </colgroup>
                    <tbody>
                        {items.map(x => {
                            return (
                                <tr>
                                    <td className="ch">
                                        <input type="checkbox" />
                                    </td>
                                    <td className="icon">
                                        <span className={`icons circle ${pushImage[x.type]}`}></span>
                                    </td>
                                    <td className="cate">
                                        <p className="sbj">{t(x.title)}</p>
                                    </td>
                                    <td className="cont">{t(x.message)}</td>
                                    <td className="date">{moment(x.time).format('YYYY. MM. DD')}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <PageBar
                nowPage={Number(data.page)}
                totalPage={Math.ceil(Number(range.total) / Number(range.size))}
                reload={page => {
                    data.page = page;
                    setData({ ...data });
                }}
            />
        </main>
    );
};

export default AlarmList;
