import React, { useState, useEffect } from 'react';
import { graphic } from 'echarts';
import ECharts, { EChartsReactProps } from 'echarts-for-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../css/calendar.css';

import { Get } from '../lib/DeviceData';
import { GetList as GetListPush } from '../lib/Push';
import { calculateAge, gapTime, secToString, dateLang } from '../lib/Common';
import moment from 'moment';

const pushImage = {
    HEART: 'heart',
    RESP: 'breath',
    FSR: 'oxygen',
    HEARTE: 'heart_dise',
    RESPE: 'breath_dise',
    SLEEP: 'sleep_dise',
};

const MonitoringDetailBottom = props => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [data, setData] = useState();
    const [date, setDate] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD'));
    const [items, setItems] = useState([]);

    useEffect(() => {
        const device_no = props.data?.device_no;
        if (device_no) {
            Get(device_no, date, res => {
                setData(res);
            });

            GetListPush({ device_no, date }, res => {
                setItems(res);
            });
        }
    }, [props.data, date]);

    return (
        <section className="monitoring_calendar_box">
            <h4>{t('달력')}</h4>

            <div className="information_box">
                <div className="calendar_box">
                    <Calendar
                        formatDay={(locale, date) => date.toLocaleString('en', { day: 'numeric' })}
                        showNeighboringMonth={false}
                        // maxDate={moment().subtract(1, 'day').toDate()}
                        maxDate={moment().toDate()}
                        onChange={date => {
                            setDate(moment(date).format('YYYY-MM-DD'));
                        }}
                        value={moment(date).toDate()}
                    />
                </div>

                <div className="data_box">
                    <dl>
                        <dt>{t('선택날짜')}</dt>
                        <dd>
                            <span className="date">
                                <b>{moment(data?.reg_date ? data?.reg_date : date).format('MM.DD')}</b> {t(dateLang[moment(date).locale('kr').format('ddd')])}
                            </span>
                        </dd>
                    </dl>
                    <dl>
                        <dt>{t('생체지표')}</dt>
                        <dd>
                            <div className="de_info">
                                <span className="heart">
                                    <b>{data?.heart_avg ? parseFloat(data.heart_avg).toFixed(1) : 0}</b> BPM
                                </span>
                                <span className="breath">
                                    <b>{data?.resp_avg ? parseFloat(data.resp_avg).toFixed(1) : 0}</b> BPM
                                </span>
                                <span className="oxygen">
                                    <b>{data?.apnea_avg ? parseFloat(data.apnea_avg).toFixed(1) : 0}</b> %
                                </span>
                                <span className="time">
                                    <b>{data?.countDay ? secToString(data?.countDay) : ''}</b>
                                </span>
                            </div>
                        </dd>
                    </dl>
                    <dl>
                        <dt>{t('긴급알람 내용')}</dt>
                        <dd>
                            <ul className="alrm_list">
                                {items.map(x => {
                                    return (
                                        <li>
                                            <p className="icon">
                                                <span className={`icons circle ${pushImage[x.type]}`}></span>
                                            </p>
                                            <p className="tit">{t(x.title)}</p>
                                            <p className="decs">{t(x.message)}</p>
                                            <p className="data">{moment().format('YYYY. MM. DD')}</p>
                                        </li>
                                    );
                                })}

                                {items.length === 0 && (
                                    <li>
                                        <p className="tit">{t('없음')}</p>
                                    </li>
                                )}
                            </ul>
                        </dd>
                    </dl>
                    <dl className="hori_dl">
                        <dt>
                            {moment(data?.reg_date ? data?.reg_date : date).format('MM')}
                            {' ' + t('월 효심 총 사용 시간')}
                        </dt>
                        <dd>{data?.countMonth ? secToString(data?.countMonth) : ''}</dd>
                    </dl>
                </div>
            </div>
        </section>
    );
};

export default MonitoringDetailBottom;
