import moment from 'moment';
import create from 'zustand';

const useWarnningStore = create(set => ({
    show: false,
    check: {},
    popups: [],
    setShow: show => {
        set(state => {
            return { ...state, show };
        });
    },
    addPopups: userId => {
        set(state => {
            if (state.check[userId] && !moment().isAfter(moment(state.check[userId]).add(1, 'minute'))) {
                return state;
            }

            state.check[userId] = moment();
            const p = state.popups.filter(x => x !== userId);
            return { ...state, check: state.check, popups: [...p, userId] };
        });
    },
    removePopups: userId => {
        set(state => {
            return { ...state, popups: state.popups.filter(x => x !== userId) };
        });
    },
}));

export default useWarnningStore;
