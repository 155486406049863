import React, { useState, useEffect } from 'react';
import ECharts, { EChartsReactProps } from 'echarts-for-react';
import { useTranslation } from 'react-i18next';

import { Get, Delete } from '../lib/User';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import { GetList } from '../lib/DeviceData';
import { dateLang, formatPhoneNumber, typeSex } from '../lib/Common';

const MemberDetail = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { id } = useParams();
    const [data, setData] = useState({});
    const [selectType, setSelectType] = useState('week');
    const [items, setItems] = useState([]);
    const [xAxisData, setXAxisData] = useState([t('월'), t('화'), t('수'), t('목'), t('금'), t('토'), t('일')]);
    const [range1, setRange1] = useState([0, 0]);
    const [range2, setRange2] = useState([0, 0]);
    const [range3, setRange3] = useState([0, 0]);

    const [search, setSaerch] = useState({
        member_no: id,
        from: moment().subtract(7, 'day').format('YYYY-MM-DD'),
        to: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    });

    const clickDelete = () => {
        if (window.confirm(t('삭제 하시겠습니까?'))) {
            Delete([id], res => {
                navigate('/member/list/');
            });
        }
    };

    useEffect(() => {
        Get(id, res => {
            setData(res);
        });

        GetList(search, res => {
            const w = [];
            const array = [];

            if (res.length > 0) {
                range1[0] = Math.min.apply(
                    null,
                    res.map(x => x.heart_min).filter(x => x !== null),
                );
                range1[1] = Math.max.apply(
                    null,
                    res.map(x => x.heart_max),
                );
                range2[0] = Math.min.apply(
                    null,
                    res.map(x => x.resp_min).filter(x => x !== null),
                );
                range2[1] = Math.max.apply(
                    null,
                    res.map(x => x.resp_max),
                );

                range3[0] = Math.min.apply(
                    null,
                    res.map(x => x.apnea_min).filter(x => x !== null),
                );
                range3[1] = Math.max.apply(
                    null,
                    res.map(x => x.apnea_max),
                );

                if (!isNaN(range1[0])) range1[0] = 0;
                if (!isNaN(range1[1])) range1[1] = 0;
                if (!isNaN(range2[0])) range2[0] = 0;
                if (!isNaN(range2[1])) range2[1] = 0;
                if (!isNaN(range3[0])) range3[0] = 0;
                if (!isNaN(range3[1])) range3[1] = 0;
            } else {
                setRange1([0, 0]);
                setRange2([0, 0]);
                setRange3([0, 0]);
            }

            let indexData = moment(search.from);
            while (indexData.isSameOrBefore(moment(search.to))) {
                w.push(selectType === 'week' ? t(dateLang[indexData.format('ddd')]) : indexData.format('M/DD'));
                const d = res.find(x => x.reg_date === indexData.format('YYYY/MM/DD'));
                array.push(d ? d : {});
                indexData = indexData.add(1, 'day');
            }

            setXAxisData(w);
            setItems(array);
        });
    }, [search]);

    // 심박수 컬러
    const heartColor = '#F75C5D';

    // 호흡수 컬러
    const breathColor = '#3889ff';

    // 산소탈포화지수 컬러
    const oxygenColor = '#3fd3d4';

    const getOption1 = () => {
        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    lineStyle: {
                        color: '#dddddd',
                        width: 1,
                    },
                },
                animation: true,
                formatter: function (params) {
                    if (params[0].data) {
                        return `${t('최대')} ${(
                            parseFloat(params[0].data) +
                            parseFloat(params[1].data) +
                            parseFloat(params[2].data) +
                            parseFloat(params[3].data)
                        ).toFixed()} BPM<br />${t('최소')} ${params[0].data} BPM`;
                    }
                    return '';
                },
                textStyle: {
                    color: 'black',
                    fontWeight: 'bold',
                    fontFamily: 'Pretendard',
                    fontSize: 13,
                },
            },
            xAxis: {
                splitLine: {
                    show: true,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: '#dddddd',
                    },
                },
                axisLabel: {
                    color: '#333333',
                    fontWeight: 'bolder',
                    fontSize: 13,
                    fontFamily: 'Pretendard',
                },
                data: xAxisData,
            },
            yAxis: {
                type: 'value',
                // splitNumber: 12,
                axisLabel: {
                    color: '#333333',
                    fontWeight: 'bolder',
                    fontSize: 13,
                    fontFamily: 'Pretendard',
                },
                min: 0,
                max: 150,
            },
            grid: {
                top: 20,
                left: 50,
                right: 10,
                bottom: 25,
            },
            series: [
                {
                    name: 'Placeholder',
                    type: 'bar',
                    stack: 'Total',
                    silent: true,
                    itemStyle: {
                        borderColor: 'transparent',
                        color: 'transparent',
                    },
                    data: items.map(x => x.heart_min),
                },
                {
                    name: 'Value',
                    type: 'bar',
                    stack: 'Total',
                    itemStyle: {
                        color: heartColor,
                        borderRadius: 15,
                    },
                    barWidth: selectType === 'week' ? 20 : '50%',
                    data: items.map(x => parseInt(x.heart_avg) - 1 - x.heart_min),
                },
                {
                    name: 'Placeholder-Average',
                    type: 'bar',
                    stack: 'Total',
                    itemStyle: {
                        borderColor: 'transparent',
                        color: 'transparent',
                    },
                    data: items.map(x => 2),
                },
                {
                    name: 'Value',
                    type: 'bar',
                    stack: 'Total',
                    itemStyle: {
                        color: heartColor,
                        borderRadius: 15,
                    },
                    barWidth: selectType === 'week' ? 20 : '50%',
                    data: items.map(x => x.heart_max - (parseInt(x.heart_avg) + 1)),
                },
            ],
        };
    };

    const getOption2 = () => {
        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    lineStyle: {
                        color: '#dddddd',
                        width: 1,
                    },
                },
                animation: true,
                formatter: function (params) {
                    if (params[0].data) {
                        return `${t('최대')} ${(
                            parseFloat(params[0].data) +
                            parseFloat(params[1].data) +
                            parseFloat(params[2].data) +
                            parseFloat(params[3].data)
                        ).toFixed()} ${t('회')}/${t('분')}<br />${t('최소')} ${params[0].data} ${t('회')}/${t('분')}`;
                    }
                    return '';
                },
                textStyle: {
                    color: 'black',
                    fontWeight: 'bold',
                    fontFamily: 'Pretendard',
                    fontSize: 13,
                },
            },
            xAxis: {
                splitLine: {
                    show: true,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: '#dddddd',
                    },
                },
                axisLabel: {
                    color: '#333333',
                    fontWeight: 'bolder',
                    fontSize: 13,
                    fontFamily: 'Pretendard',
                },
                data: xAxisData,
            },
            yAxis: {
                type: 'value',
                // splitNumber: 12,
                axisLabel: {
                    color: '#333333',
                    fontWeight: 'bolder',
                    fontSize: 13,
                    fontFamily: 'Pretendard',
                },
                min: 0,
                max: 40,
            },
            grid: {
                top: 20,
                left: 50,
                right: 10,
                bottom: 25,
            },
            series: [
                {
                    name: 'Placeholder',
                    type: 'bar',
                    stack: 'Total',
                    silent: true,
                    itemStyle: {
                        borderColor: 'transparent',
                        color: 'transparent',
                    },
                    data: items.map(x => x.resp_min),
                },
                {
                    name: 'Value',
                    type: 'bar',
                    stack: 'Total',
                    itemStyle: {
                        color: breathColor,
                        borderRadius: 20,
                    },
                    barWidth: selectType === 'week' ? 20 : '50%',
                    data: items.map(x => parseInt(x.resp_avg) - 1 - x.resp_min),
                },
                {
                    name: 'Placeholder-Average',
                    type: 'bar',
                    stack: 'Total',
                    itemStyle: {
                        borderColor: 'transparent',
                        color: 'transparent',
                    },
                    data: items.map(x => 2),
                },
                {
                    name: 'Value',
                    type: 'bar',
                    stack: 'Total',
                    itemStyle: {
                        color: breathColor,
                        borderRadius: 20,
                    },
                    barWidth: selectType === 'week' ? 20 : '50%',
                    data: items.map(x => x.resp_max - (parseInt(x.resp_avg) + 1)),
                },
            ],
        };
    };

    const getOption3 = () => {
        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    lineStyle: {
                        color: '#dddddd',
                        width: 1,
                    },
                },
                animation: true,
                formatter: function (params) {
                    if (params[0].data) {
                        return `${t('최대')} ${(
                            parseFloat(params[0].data) +
                            parseFloat(params[1].data) +
                            parseFloat(params[2].data) +
                            parseFloat(params[3].data)
                        ).toFixed()} %<br />${t('최소')} ${params[0].data} %`;
                    }
                    return '';
                },
                textStyle: {
                    color: 'black',
                    fontWeight: 'bold',
                    fontFamily: 'Pretendard',
                    fontSize: 13,
                },
            },
            xAxis: {
                splitLine: {
                    show: true,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: '#dddddd',
                    },
                },
                axisLabel: {
                    color: '#333333',
                    fontWeight: 'bolder',
                    fontSize: 13,
                    fontFamily: 'Pretendard',
                },
                data: xAxisData,
            },
            yAxis: {
                type: 'value',
                // splitNumber: 12,
                axisLabel: {
                    color: '#333333',
                    fontWeight: 'bolder',
                    fontSize: 13,
                    fontFamily: 'Pretendard',
                },
                min: 80,
                max: 100,
            },
            grid: {
                top: 20,
                left: 50,
                right: 10,
                bottom: 25,
            },
            series: [
                {
                    name: 'Placeholder',
                    type: 'bar',
                    stack: 'Total',
                    silent: true,
                    itemStyle: {
                        borderColor: 'transparent',
                        color: 'transparent',
                    },
                    data: items.map(x => x.apnea_min),
                },
                {
                    name: 'Value',
                    type: 'bar',
                    stack: 'Total',
                    itemStyle: {
                        color: oxygenColor,
                        borderRadius: 15,
                    },
                    barWidth: selectType === 'week' ? 20 : '50%',
                    data: items.map(x => parseInt(x.apnea_avg) - 1 - x.apnea_min),
                },
                {
                    name: 'Placeholder-Average',
                    type: 'bar',
                    stack: 'Total',
                    itemStyle: {
                        borderColor: 'transparent',
                        color: 'transparent',
                    },
                    data: items.map(x => 2),
                },
                {
                    name: 'Value',
                    type: 'bar',
                    stack: 'Total',
                    itemStyle: {
                        color: oxygenColor,
                        borderRadius: 15,
                    },
                    barWidth: selectType === 'week' ? 20 : '50%',
                    data: items.map(x => x.apnea_max - (parseInt(x.apnea_avg) + 1)),
                },
            ],
        };
    };

    return (
        <main className="admin-contents">
            <h2 className="sub_title flex">
                {t('회원정보 상세보기')}
                <div className="rb">
                    <button type="button" className="btn_nor md" onClick={clickDelete}>
                        {t('삭제')}
                    </button>
                    <button
                        type="button"
                        className="btn_nor md"
                        onClick={e => {
                            navigate('/member/update/' + data.member_no);
                        }}
                    >
                        {t('수정')}
                    </button>
                </div>
            </h2>

            <div className="member_detail_wrapper">
                <div className="pics">
                    <img src={data.image ? data.image : 'https://picsum.photos/500/600'} alt={t('프로필')} />
                </div>
                <div className="details">
                    <table className="tbl_detail">
                        <colgroup>
                            <col width="25%" />
                            <col width="75%" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>{t('회원그룹')}</th>
                                <td>{data.group?.name}</td>
                            </tr>
                            <tr>
                                <th>{t('회원명')}</th>
                                <td>{data.name}</td>
                            </tr>
                            <tr>
                                <th>{t('생년월일')}</th>
                                <td>{data.birth ? moment(data.birth).format(t('YYYY년 MM월 DD일')) : '-'}</td>
                            </tr>
                            <tr>
                                <th>{t('성별')}</th>
                                <td>{t(typeSex[data.sex])}</td>
                            </tr>
                            <tr>
                                <th>{t('휴대전화 번호')}</th>
                                <td>{formatPhoneNumber(data.phone)}</td>
                            </tr>
                            <tr>
                                <th>{t('주소')}</th>
                                <td>{(data.addr1 + ' ' + data.addr2).trim()}</td>
                            </tr>
                            <tr>
                                <th>{t('보호자')}</th>
                                <td>{data.guard_phone}</td>
                            </tr>
                            <tr>
                                <th>{t('인터넷 설치 여부')}</th>
                                <td>{data.device?.set_insernet === 'Y' ? t('연결 됨') : t('안됨')}</td>
                            </tr>
                            {/* <tr>
                                <th>주거형태</th>
                                <td>--- 주택 아내 딸</td>
                            </tr> */}
                            <tr>
                                <th>{t('장비명')}(ID)</th>
                                <td>{data.device_sn}</td>
                            </tr>
                            <tr>
                                <th>{t('장비설치')}</th>
                                <td>{moment(data.device?.device_dt).format('YYYY-MM-DD')}</td>
                            </tr>
                            <tr>
                                <th>{t('특이사항')}</th>
                                <td>{data.medical}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="tab_statistics_wrapper">
                <ul className="tab_statistics">
                    <li
                        className={selectType === 'week' ? 'active' : ''}
                        onClick={() => {
                            setSaerch({
                                ...search,
                                from: moment().subtract(7, 'day').format('YYYY-MM-DD'),
                                to: moment().subtract(1, 'day').format('YYYY-MM-DD'),
                            });
                            setSelectType('week');
                        }}
                    >
                        <a href="javascript:;">Weekly</a>
                    </li>
                    <li
                        className={selectType === 'month' ? 'active' : ''}
                        onClick={() => {
                            setSaerch({
                                ...search,
                                from: moment().subtract(30, 'day').format('YYYY-MM-DD'),
                                to: moment().subtract(1, 'day').format('YYYY-MM-DD'),
                            });
                            setSelectType('month');
                        }}
                    >
                        <a href="javascript:;">Monthly</a>
                    </li>
                </ul>
            </div>

            {/* weekly 그래프 */}
            {selectType === 'week' && (
                <section className="stat_tab_cont weekly_graph">
                    <article className="graph_ar">
                        <div className="top">
                            <span className="icons circle heart"></span>
                            <span className="tit">{t('심박수 범위')}</span>
                            <span className="data">
                                <b>
                                    {range1[0]}~{range1[1]}
                                </b>{' '}
                                BPM
                            </span>
                            <span className="date">
                                {moment(search.from).format(t('YYYY년 MM월 DD일'))} ~ {moment(search.to).format(t('MM월 DD일'))}
                            </span>
                        </div>
                        <div className="graph_box">
                            <ECharts option={getOption1()} opts={{ renderer: 'svg', width: 'auto', height: 'auto' }} />
                        </div>
                    </article>
                    <article className="graph_ar">
                        <div className="top">
                            <span className="icons circle breath"></span>
                            <span className="tit">{t('호흡수 범위')}</span>
                            <span className="data">
                                <b>
                                    {range2[0]}~{range2[1]}
                                </b>{' '}
                                {t('회')}/{t('분')}
                            </span>
                            <span className="date">
                                {moment(search.from).format(t('YYYY년 MM월 DD일'))} ~ {moment(search.to).format(t('MM월 DD일'))}
                            </span>
                        </div>
                        <div className="graph_box">
                            <ECharts option={getOption2()} opts={{ renderer: 'svg', width: 'auto' }} />
                        </div>
                    </article>
                    <article className="graph_ar">
                        <div className="top">
                            <span className="icons circle oxygen"></span>
                            <span className="tit">{t('산소탈포화지수 범위')}</span>
                            <span className="data">
                                <b>
                                    {range3[0]}~{range3[1]}
                                </b>{' '}
                                %
                            </span>
                            <span className="date">
                                {moment(search.from).format(t('YYYY년 MM월 DD일'))} ~ {moment(search.to).format(t('MM월 DD일'))}
                            </span>
                        </div>
                        <div className="graph_box">
                            <ECharts option={getOption3()} opts={{ renderer: 'svg', width: 'auto' }} />
                        </div>
                    </article>
                </section>
            )}

            {/* monthly 그래프 */}
            {selectType === 'month' && (
                <section className="stat_tab_cont monthly_graph">
                    <article className="graph_ar">
                        <div className="top">
                            <span className="icons circle heart"></span>
                            <div className="text_box">
                                <p className="tit">
                                    <b>
                                        {t('심박수 범위')} {range1[0]}~{range1[1]}
                                    </b>{' '}
                                    BPM
                                </p>
                                <p className="date">
                                    {moment(search.from).format(t('MM월 DD일'))} ~ {moment(search.to).format(t('MM월 DD일'))}
                                </p>
                            </div>
                        </div>
                        <div className="graph_box">
                            <ECharts option={getOption1()} opts={{ renderer: 'svg', width: 'auto' }} />
                        </div>
                    </article>
                    <article className="graph_ar">
                        <div className="top">
                            <span className="icons circle breath"></span>
                            <div className="text_box">
                                <p className="tit">
                                    <b>
                                        {t('호흡수 범위')} {range2[0]}~{range2[1]}
                                    </b>{' '}
                                    {t('회')}/{t('분')}
                                </p>
                                <p className="date">
                                    {moment(search.from).format(t('MM월 DD일'))} ~ {moment(search.to).format(t('MM월 DD일'))}
                                </p>
                            </div>
                        </div>
                        <div className="graph_box">
                            <ECharts option={getOption2()} opts={{ renderer: 'svg', width: 'auto' }} />
                        </div>
                    </article>
                    <article className="graph_ar">
                        <div className="top">
                            <span className="icons circle oxygen"></span>
                            <div className="text_box">
                                <p className="tit">
                                    <b>
                                        {t('산소탈포화지수 범위')} {range3[0]}~{range3[1]}
                                    </b>{' '}
                                    {t('회')}/{t('분')}
                                </p>
                                <p className="date">
                                    {moment(search.from).format(t('MM월 DD일'))} ~ {moment(search.to).format(t('MM월 DD일'))}
                                </p>
                            </div>
                        </div>
                        <div className="graph_box">
                            <ECharts option={getOption3()} opts={{ renderer: 'svg', width: 'auto' }} />
                        </div>
                    </article>
                </section>
            )}
        </main>
    );
};

export default MemberDetail;
