import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GetList, Create, Update, Delete } from '../lib/Group';

const Group = props => {
    const [value, setValue] = useState('');
    const [items, setItems] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = () => {
        GetList({}, res => {
            if (props.data) {
                res.map(x => {
                    x.checked = props.data.find(d => d === x.group_no) ? true : false;
                });
            }

            setItems(res);
        });
    };

    const clickAdd = () => {
        if (value === '') return;

        Create(value, res => {
            setValue('');
            refreshData();
            if (props.onRefresh) {
                props.onRefresh();
            }
        });
    };

    const clickDelete = () => {
        const checkItems = items.filter(x => x.checked === true);
        const ids = checkItems.map(x => x.group_no);

        if (ids.length === 0) return;

        Delete(ids, res => {
            refreshData();
            if (props.onRefresh) {
                props.onRefresh();
            }
        });
    };

    return (
        <div className={'pop_move_wrap show' + (props.right ? ' right' : '')}>
            <div className="checks">
                {items.map(x => {
                    return (
                        <label>
                            <input
                                type="checkbox"
                                checked={x.checked}
                                onClick={() => {
                                    x.checked = x.checked ? false : true;
                                    setItems([...items]);
                                }}
                            />
                            <span>{x.name}</span>
                        </label>
                    );
                })}

                {/* <label>
                    <input type="checkbox" />
                    <span>{t('신안동')}</span>
                </label>
                <label>
                    <input type="checkbox" />
                    <span>{t('용봉동')}</span>
                </label> */}
            </div>
            <div className="addbox">
                <input
                    type="text"
                    className="text_nor sm"
                    placeholder={t('새 그룹')}
                    value={value}
                    onChange={e => {
                        setValue(e.target.value);
                    }}
                />
                <button type="button" className="btn_nor sm" onClick={clickAdd}>
                    {t('추가')}
                </button>
            </div>
            <div className="btns">
                <button type="button" className="btn_nor sm" onClick={clickDelete}>
                    {t('삭제')}
                </button>
                <button type="button" className="btn_nor sm" onClick={props.onClose}>
                    {t('닫기')}
                </button>
                {props.onMove && (
                    <button
                        type="button"
                        className="btn_nor sm"
                        onClick={() => {
                            props.onMove(items.filter(x => x.checked === true));
                        }}
                    >
                        {t('이동')}
                    </button>
                )}

                {/* <button type="button" className="btn_nor sm">
                    {t('이동')}
                </button>
                <button type="button" className="btn_nor sm">
                    {t('복사')}
                </button> */}
            </div>
        </div>
    );
};

export default Group;
