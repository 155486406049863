import React, { useState, useEffect, useRef } from 'react';
import ECharts, { EChartsReactProps } from 'echarts-for-react';
import { Link, useNavigate } from 'react-router-dom';
import { typeSex, calculateAge, gapTime } from '../lib/Common';
import moment from 'moment';
import { GetListLast } from '../lib/DeviceData';
import useClientStore from '../stores/client';
import useWarnningStore from '../stores/warnning';
import { useTranslation } from 'react-i18next';

const lineColor = ['#F75C5D', '#3889ff', '#3fd3d4'];

export const deviceState = {
    OFF: 'off',
    NORMAL: 'normal',
    WARNNING: 'warnning',
    DANGER: 'danger',
};

const emptyData = {
    tooltip: {
        show: 'false',
    },
    xAxis: {
        show: false,
        boundaryGap: false,
        data: [],
    },
    yAxis: {
        show: false,
        type: 'value',
    },
    grid: {
        top: 10,
        left: 0,
        right: 0,
        bottom: 0,
    },
    series: [
        {
            name: 'BPM',
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
                color: '#7E7E7E',
                width: 3,
            },
            data: [],
        },
    ],
};

const MonitoringRealtimeCell = props => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { show, addPopups } = useWarnningStore();

    // const [client, setClient] = useState();
    const { client, addOnMessage, removeOnMessage } = useClientStore();

    // 심박수
    const [options1, setOptions1] = useState([]);
    const [value1, setValue1] = useState(0);

    // 호흡수
    const [options2, setOptions2] = useState([]);
    const [value2, setValue2] = useState(0);

    // 산소불포화
    const [options3, setOptions3] = useState([]);
    const [value3, setValue3] = useState(0);
    // 움직임 감지 
    const [motion_detected, setMotionDetected] = useState(0);

    let cTopic = '';
    const onMessage = (topic, message) => {
        if (cTopic !== topic) {
            return;
        }

        const data = JSON.parse(message);
        if (data.e) {
            console.log(topic, data);
            procData(data.e);
        }
    };

    useEffect(() => {
        let prevCTopic = cTopic; // 이전 토픽 저장

        if (cTopic) {
            removeOnMessage(cTopic);
        }

        cTopic = `SmartMat/${props.data.device_sn}/Autowells-server/pub_device_data`;

        if (client) {
            client.subscribe(cTopic, error => {
                if (error) {
                    console.log('Subscribe to topics error', error);
                    return;
                } else {
                    console.log('success subscribe');
                }
            });
        }

        addOnMessage(cTopic, onMessage);

        return () => {
            if (client) {
                client.unsubscribe(cTopic, error => {
                    if (error) {
                        console.log('Unsubscribe error', error);
                        return;
                    }
                });
            }

            removeOnMessage(prevCTopic);
        };
    }, [props.data.device_sn, client]);

    useEffect(() => {
        let timer = setInterval(() => {
            if (props.data.online) {
                // 30초 동안 신호가 없을때 비활성화
                const sec = moment.duration(moment().diff(props.data.recieveTime)).asSeconds();
                if (sec > 30) {
                    props.data.state = deviceState.OFF;
                    props.data.online = false;
                    props.onChange(props.data);
                    setValue1(0);
                    setValue2(0);
                    setValue3(0);
                    setOptions1([]);
                    setOptions2([]);
                    setOptions3([]);
                }
            }
        }, 1000);

        return () => clearInterval(timer);
    });

    const procData = data => {
        const heart = data[0];
        const resp = data[1];
        const apnea = data[2];
        const state = data[3];
        const fsr_sum = data[4];
        var motion_detected = 0;

        if (data.length === 12) {
            motion_detected = data[11];
            props.data.motion_detected = motion_detected;
            // console.log(props.data)
            setMotionDetected(motion_detected);
        }

        if (!props.data.online) {
            props.data.online = true;
            props.data.state = deviceState.NORMAL;
            props.data.connectTime = moment();

            const heart_ranges = props.data.heart_range?.split('~');
            if (heart_ranges?.length >= 2) {
                props.data.heart_range_from = heart_ranges[0];
                props.data.heart_range_to = heart_ranges[1];
            } else {
                props.data.heart_range_from = 60;
                props.data.heart_range_to = 100;
            }

            const resp_ranges = props.data.resp_range?.split('~');
            if (resp_ranges?.length >= 2) {
                props.data.resp_range_from = resp_ranges[0];
                props.data.resp_range_to = resp_ranges[1];
            } else {
                props.data.resp_range_from = 10;
                props.data.resp_range_to = 20;
            }

            props.onChange(props.data);
        }

        const oldHeart = props.data.warnning_heart;
        const oldResp = props.data.warnning_resp;
        props.data.warnning_heart = heart < props.data.heart_range_from || heart > props.data.heart_range_to;
        props.data.warnning_resp = resp < props.data.resp_range_from || resp > props.data.resp_range_to;
        props.data.recieveTime = moment();

        const isUse = fsr_sum >= 300;
        if (isUse) {
            const oldState = props.data.state;
            if (!props.data.warnning_heart && !props.data.warnning_resp) {
                props.data.state = deviceState.NORMAL;
            } else if (props.data.warnning_heart && props.data.warnning_resp) {
                props.data.state = deviceState.DANGER;
            } else {
                props.data.state = deviceState.WARNNING;
            }

            if (oldState !== props.data.state) {
                props.onChange(props.data);
            }

            if ((props.data.warnning_heart && !oldHeart) || (props.data.warnning_resp && !oldResp)) {
                if (show) addPopups(props.data.user.member_no);
            }
        } else {
            // props.data.state = deviceState.NORMAL;
            // props.data.online = false;
            props.data.state = deviceState.OFF;
            props.data.warnning_heart = false;
            props.data.warnning_resp = false;
        }

        setValue1(motion_detected? 0: heart);
        setValue2(motion_detected? 0: resp);
        setValue3(fsr_sum);
        setMotionDetected(motion_detected);
        options1.push(motion_detected? 0:heart);
        options2.push(motion_detected? 0:resp);
        options3.push(fsr_sum);
        setOptions1([...options1.slice(-13)]);
        setOptions2([...options2.slice(-13)]);
        setOptions3([...options3.slice(-13)]);
    };

    const getOption = index => {
        return {
            tooltip: {
                show: 'false',
            },
            xAxis: {
                show: false,
                boundaryGap: false,
                data: [
                    `${t('오전')}\n1${t('시')}`,
                    `${t('오전')}\n2${t('시')}`,
                    `${t('오전')}\n3${t('시')}`,
                    `${t('오전')}\n4${t('시')}`,
                    `${t('오전')}\n5${t('시')}`,
                    `${t('오전')}\n6${t('시')}`,
                    `${t('오전')}\n7${t('시')}`,
                    `${t('오전')}\n8${t('시')}`,
                    `${t('오전')}\n9${t('시')}`,
                    `${t('오전')}\n10${t('시')}`,
                    `${t('오전')}\n11${t('시')}`,
                    `${t('오전')}\n12${t('시')}`,
                    `${t('오후')}\n1${t('시')}`,
                ],
            },
            yAxis: {
                show: false,
                type: 'value',
            },
            grid: {
                top: 10,
                left: 0,
                right: 0,
                bottom: 0,
            },
            series: [
                {
                    name: 'BPM',
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    lineStyle: {
                        color: props.data.online ? motion_detected === 1? '#CCCCCC' :lineColor[index] : '#7E7E7E',
                        width: 3,
                    },
                    data: index === 1 ? options1 : index === 2 ? options2 : options3,
                },
            ],
        };
    };

    // <article className="real_time_graph">
    // <article className="real_time_graph off">
    // <article className="real_time_graph click">

    const isUse = value3 >= 300;
    return (
        <li onClick={props.onClick} style={{ cursor: 'pointer' }}>
            <article className={props.data.online ? 'real_time_graph' : 'real_time_graph off'}>
                <div className="tops">
                    <span className="icon wifi"></span>
                    <span className="icon sleep" style={props.data.online && isUse ? { backgroundColor: '#FF5B45' } : {}}></span>
                    <p className="info">
                        <b>{props.data.user?.member_no}</b>{' '}
                        {`${props.data.user?.name ? props.data.user?.name : t('이름없음')} [${calculateAge(props.data.user?.birth)}${t('세')}${
                            props.data.user?.sex ? '/' + t(typeSex[props.data.user?.sex]) : ''
                        }]`}
                    </p>
                    {props.data.online ? <p className="time">{gapTime(props.data.connectTime)}</p> : <p className="alert">{t('없음')}</p>}
                </div>
                <div className="graph_box">
                    {/* <div className="wide heart01">
                        <div className="sbj_box">
                            <p className="sbj heart">{t('심박변이도')}</p>
                        </div>
                        <div className="graphs">
                            <ECharts
                                option={getOption(3)}
                                notMerge={true}
                                lazyUpdate={true}
                                opts={{ renderer: 'svg', width: 'auto', height: '60px' }}
                                style={{ height: '60px' }}
                            />
                        </div>
                    </div> */}
                    <div className="wide">
                        <div className={'sbj_box' + (isUse && props.data.warnning_heart ? ' on' : '')}>
                            <p className="sbj heart">{t('심박수')}</p>
                            <p className="number heart">{props.data.online && isUse ? value1 : 0}</p>
                        </div>
                        <div className="graphs">
                            <ECharts
                                option={props.data.online && isUse ? getOption(1) : emptyData}
                                notMerge={true}
                                lazyUpdate={true}
                                opts={{ renderer: 'svg', width: 'auto', height: '60px' }}
                                style={{ height: '60px' }}
                            />
                        </div>
                    </div>
                    <div className="half">
                        <div className={'sbj_box' + (isUse && props.data.warnning_resp ? ' on' : '')}>
                            <p className="sbj breath">{t('호흡수')}</p>
                            <p className="number breath">{props.data.online && isUse ? value2 : 0}</p>
                        </div>
                        <div className="graphs">
                            <ECharts
                                option={props.data.online && isUse ? getOption(2) : emptyData}
                                notMerge={true}
                                lazyUpdate={true}
                                opts={{ renderer: 'svg', width: 'auto', height: '60px' }}
                                style={{ height: '60px' }}
                            />
                        </div>
                    </div>
                    <div className="half">
                        <div className="sbj_box">
                            <p className="sbj fsr">{t('사용여부')}</p>
                            {/* <p className="number oxygen">{value3}</p> */}
                        </div>
                        {/* <div className="graphs">
                            <ECharts
                                option={getOption(3)}
                                notMerge={true}
                                lazyUpdate={true}
                                opts={{ renderer: 'svg', width: 'auto', height: '60px' }}
                                style={{ height: '60px' }}
                            />
                        </div> */}
                        <div className="graphs">
                            <p className="text">{props.data.online ? (isUse ? motion_detected === 1? t("움직임 감지") :t('사용 중') : t('미사용')) : ''}</p>
                            <p className="subtext">{`(${t('압력값')} : ${props.data.online ? value3 : 0})`}</p>
                        </div>
                    </div>
                </div>
            </article>
        </li>
    );
};

export default MonitoringRealtimeCell;
