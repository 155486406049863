import React, { useState, useEffect } from 'react';
import ECharts, { EChartsReactProps } from 'echarts-for-react';
import { Link, useNavigate } from 'react-router-dom';
import Cell from './monitoring_realtime_cell';
import alarm from '../assets/sound/beep1.mp3';
import { useTranslation } from 'react-i18next';

import { GetList, Delete } from '../lib/Device';
import { GetList as GetListGroup } from '../lib/Group';
import { checkCanPlay } from '../lib/Common';
import { AuthMe } from '../lib/MyHttp';

const MonitoringRealtimeSimpleList = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [audio] = useState(new Audio(alarm));

    const [checkStateNormal, setCheckStateNormal] = useState(false);
    const [checkStateWarnning, setCheckStateWarnning] = useState(false);
    const [checkStateDanger, setCheckStateDanger] = useState(false);
    const [checkOnline, setCheckOnline] = useState(false);
    const [checkOffline, setCheckOffline] = useState(false);
    const [checkMan, setCheckMan] = useState(false);
    const [checkWoman, setCheckWoman] = useState(false);
    const [checkGroupAll, setCheckGroupAll] = useState(true);
    const [groups, setGroups] = useState([]);
    const [canPlay, setCanPlay] = useState(false);
    const [myInfo, setMyInfo] = useState(false);

    const [data, setData] = useState({
        page: 1,
        size: '100',
        sort: '-device_no',
    });

    const [items, setItems] = useState([]);
    const [range, setRange] = useState({ size: 10, start: 0, end: 29, total: 0 });

    useEffect(() => {
        refreshData();
        checkCanPlay().then(result => {
            if (result !== false) {
                setTimeout(() => {
                    setCanPlay(true);
                }, 1000);
            }
        });
        AuthMe(res => {
            setMyInfo(res);
        });
    }, [data]);

    useEffect(() => {
        refreshGroup();
        return () => {
            if (audio) audio.pause();
        };
    }, []);

    useEffect(() => {
        let play = false;
        for (var i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.warnning_heart) {
                play = true;
                break;
            }
            if (item.warnning_resp) {
                play = true;
                break;
            }
        }

        if (canPlay && myInfo?.on_sound === 'Y' && audio) {
            if (play) {
                if (audio.paused) {
                    audio.loop = true;
                    audio.play();
                }
            } else {
                audio.pause();
            }
        }
    }, [items]);

    const refreshGroup = () => {
        GetListGroup({}, res => {
            setGroups(res);
        });
    };

    const refreshData = () => {
        GetList(data, res => {
            setItems(res.rows);
            setRange(res.range);
        });
    };

    const [colorShow, setColorShow] = useState('');
    function colorClassSet() {
        if (!colorShow) {
            setColorShow('show');
        } else {
            setColorShow('');
        }
    }

    const clickRow = item => {
        navigate('/monitoring/detail/' + item.device_no);
    };

    const filterItems = items
        .filter(x => {
            if (checkStateNormal && checkStateWarnning && checkStateDanger) {
                return true;
            }
            if (!checkStateNormal && !checkStateWarnning && !checkStateDanger) {
                return true;
            }
            if (checkStateNormal && x.state === 'normal') return true;
            if (checkStateWarnning && x.state === 'warnning') return true;
            if (checkStateWarnning && x.state === 'danger') return true;
        })
        .filter(x => {
            if (checkOnline && checkOffline) {
                return true;
            }
            if (!checkOnline && !checkOffline) {
                return true;
            }
            if (checkOnline && x.online) return true;
            if (checkOffline && !x.online) return true;
        })
        .filter(x => {
            if (checkMan && checkWoman) {
                return true;
            }
            if (!checkMan && !checkWoman) {
                return true;
            }
            if (checkMan && x.user?.sex === 'male') return true;
            if (checkWoman && x.user?.sex === 'female') return true;
        })
        .filter(x => {
            if (checkGroupAll) return true;

            const gs = groups.filter(x => x.checked);
            const group_no = gs.map(x => x.group_no);

            if (x.user) {
                if (group_no.includes(x.user.group_no)) return true;
            }
            return false;
        })
        .sort((a, b) => {
            if (a.online && !b.online) {
                return -1;
            } else if (!a.online && b.online) {
                return 1;
            }
            return 0;
        });
    return (
        <main className="admin-contents">
            <h2 className="sub_title">{t('실시간 간편 보기')}</h2>

            <div className="realtime_checks_top">
                <dl>
                    <dt>{t('사용자 상태')}</dt>
                    <dd>
                        <label>
                            <input
                                type="checkbox"
                                value={checkStateNormal}
                                onClick={() => {
                                    setCheckStateNormal(!checkStateNormal);
                                }}
                            />{' '}
                            {t('정상')}
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                value={checkStateWarnning}
                                onClick={() => {
                                    setCheckStateWarnning(!checkStateWarnning);
                                }}
                            />{' '}
                            {t('주의')}
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                value={checkStateDanger}
                                onClick={() => {
                                    setCheckStateDanger(!checkStateDanger);
                                }}
                            />{' '}
                            {t('위험')}
                        </label>
                    </dd>
                </dl>
                <dl>
                    <dt>{t('기기상태')}</dt>
                    <dd>
                        <label>
                            <input
                                type="checkbox"
                                value={checkOnline}
                                onClick={() => {
                                    setCheckOnline(!checkOnline);
                                }}
                            />{' '}
                            {t('사용중')}
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                value={checkOffline}
                                onClick={() => {
                                    setCheckOffline(!checkOffline);
                                }}
                            />{' '}
                            {t('미사용')}
                        </label>
                    </dd>
                </dl>
                <dl>
                    <dt>{t('성별')}</dt>
                    <dd>
                        <label>
                            <input
                                type="checkbox"
                                value={checkMan}
                                onClick={() => {
                                    setCheckMan(!checkMan);
                                }}
                            />{' '}
                            {t('남자')}
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                value={checkWoman}
                                onClick={() => {
                                    setCheckWoman(!checkWoman);
                                }}
                            />{' '}
                            {t('여자')}
                        </label>
                    </dd>
                </dl>
                <dl>
                    <dt>{t('회원그룹')}</dt>
                    <dd>
                        <label>
                            <input
                                type="checkbox"
                                checked={checkGroupAll}
                                onClick={() => {
                                    groups.map(x => (x.checked = !checkGroupAll));

                                    setCheckGroupAll(!checkGroupAll);
                                    setGroups([...groups]);
                                }}
                            />{' '}
                            {t('전체보기')}
                        </label>
                        {groups.map(x => {
                            return (
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={x.checked}
                                        onClick={() => {
                                            x.checked = x.checked ? false : true;
                                            setGroups([...groups]);
                                            setCheckGroupAll(false);
                                        }}
                                    />{' '}
                                    {x.name}
                                </label>
                            );
                        })}
                    </dd>
                </dl>
            </div>

            <ul className="realtime_graph_list">
                {filterItems.map(x => {
                    return (
                        <Cell
                            data={x}
                            onClick={() => {
                                clickRow(x);
                            }}
                            onChange={data => {
                                // const sortArray = items.sort((a, b) => {
                                //     if (a.online && !b.online) {
                                //         return -1;
                                //     } else if (!a.online && b.online) {
                                //         return 1;
                                //     }
                                //     return 0;
                                // });
                                // setItems([...sortArray]);
                                setItems([...items]);
                            }}
                        />
                    );
                })}
            </ul>
        </main>
    );
};

export default MonitoringRealtimeSimpleList;
