import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GetList, Delete } from '../lib/User';
import { typeSex, calculateAge } from '../lib/Common';
import Group from '../components/group';
import { GetList as GetListGroup } from '../lib/Group';
import PageBar from '../components/pagebar';

const MemberList = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [data, setData] = useState({
        page: 1,
        size: '10',
        sort: '-member_no',
        group_no: [],
    });

    const [showMenu, setShowMenu] = useState(false);
    const [items, setItems] = useState([]);
    const [range, setRange] = useState({ size: 10, start: 0, end: 29, total: 0 });
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        refreshData();
        refreshGroup();
    }, [data]);

    const refreshGroup = () => {
        GetListGroup({}, res => {
            setGroups(res);
        });
    };

    const refreshData = () => {
        GetList(data, res => {
            setItems(res.rows);
            setRange(res.range);
        });
    };

    const clickDelete = () => {
        const checkItems = items.filter(x => x.checked);
        if (checkItems.length > 0) {
            if (window.confirm(t('삭제 하시겠습니까?'))) {
                Delete(
                    checkItems.map(x => x.member_no),
                    res => {
                        alert(t('삭제가 완료 되었습니다.'));
                        refreshData();
                    },
                );
            }
        } else {
            alert(t('목록을 선택해주세요.'));
        }
    };

    const clickRow = item => {
        navigate('/member/detail/' + item.member_no);
    };

    return (
        <main className="admin-contents">
            <h2 className="sub_title">
                {t('회원목록')} ({range.total}
                {t('명')})
            </h2>

            <div className="equipment_list_top">
                <div className="left_box">
                    <button type="button" className="btn_nor md">
                        <Link to="/member/update">{t('회원등록')}</Link>
                    </button>
                    <button type="button" className="btn_nor md" onClick={clickDelete}>
                        {t('선택삭제')}
                    </button>
                    <div className="pops_puts">
                        <button
                            type="button"
                            className="btn_nor md"
                            onClick={() => {
                                setShowMenu(!showMenu);
                            }}
                        >
                            {t('이동')}
                        </button>

                        {showMenu && (
                            <Group
                                data={data.group_no}
                                onClose={() => {
                                    setShowMenu(false);
                                }}
                                onMove={items => {
                                    setShowMenu(false);
                                    data.group_no = items.map(x => x.group_no);
                                    setData({ ...data });
                                }}
                            />
                        )}
                    </div>
                </div>
                <div className="right_box">
                    <select
                        className="select_nor md"
                        value={data.size}
                        onChange={e => {
                            data.size = e.target.value;
                            setData({ ...data });
                        }}
                    >
                        <option value="10">{t('10건')}</option>
                        <option value="20">{t('20건')}</option>
                        <option value="30">{t('30건')}</option>
                        <option value="50">{t('50건')}</option>
                    </select>
                    <select
                        className="select_nor md"
                        value={data.sort}
                        onChange={e => {
                            data.sort = e.target.value;
                            setData({ ...data });
                        }}
                    >
                        <option value="-name">{t('내림차순으로 정렬')}</option>
                        <option value="name">{t('오름차순으로 정렬')}</option>
                        <option value="-member_no">{t('최근 등록순으로 정렬')}</option>
                    </select>
                </div>
            </div>

            <div className="table_box">
                <table className="tbl_memList">
                    <caption>{t('회원목록')}</caption>
                    <colgroup>
                        <col width="5%" />
                        <col width="8%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="8%" />
                        <col width="auto" />
                        <col width="10%" />
                        <col width="10%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <input type="checkbox" />
                            </th>
                            <th>{t('구분')}</th>
                            <th>{t('회원그룹')}</th>
                            <th>{t('성명')}</th>
                            <th>{t('성별')}</th>
                            <th>{t('주소')}</th>
                            <th>{t('보호자')}</th>
                            <th>{t('장비명')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(x => {
                            return (
                                <tr>
                                    <td className="checks">
                                        <input
                                            type="checkbox"
                                            checked={x.checked}
                                            onClick={e => {
                                                x.checked = !x.checked;
                                                setItems([...items]);
                                            }}
                                        />
                                    </td>
                                    <td onClick={() => clickRow(x)} style={{ cursor: 'pointer' }} className="number">
                                        {x.member_no}
                                    </td>
                                    {x.group?.name ? (
                                        <td onClick={() => clickRow(x)} style={{ cursor: 'pointer' }} className="group">
                                            {x.group?.name}
                                        </td>
                                    ) : (
                                        <td></td>
                                    )}
                                    <td onClick={() => clickRow(x)} style={{ cursor: 'pointer' }} className="name">
                                        {x.name}
                                    </td>
                                    <td onClick={() => clickRow(x)} style={{ cursor: 'pointer' }} className="gender">
                                        {t(typeSex[x.sex])}
                                    </td>
                                    <td onClick={() => clickRow(x)} style={{ cursor: 'pointer' }} className="address">
                                        {(x.addr1 + ' ' + x.addr2).trim()}
                                    </td>
                                    <td onClick={() => clickRow(x)} style={{ cursor: 'pointer' }} className="partner">
                                        {t('보호자')}
                                    </td>
                                    <td onClick={() => clickRow(x)} style={{ cursor: 'pointer' }} className="device">
                                        {x.device_sn}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <PageBar
                nowPage={Number(data.page)}
                totalPage={Math.ceil(Number(range.total) / Number(range.size))}
                reload={page => {
                    data.page = page;
                    setData({ ...data });
                }}
            />
        </main>
    );
};

export default MemberList;
