import React from 'react';
import { useTranslation } from 'react-i18next';

const Popup = props => {
    const { t } = useTranslation();

    return (
        <section className="popup_wrap show" style={{ zIndex: '1500' }}>
            <div className="pop_base">
                <div className="icons logo"></div>
                <p className="tit">{t('가입이 완료되었습니다.')}</p>
                <div className="sub_orange">{t('사용자의 건강 모니터링을 한눈에!')}</div>
                <div className="sub_2">{t('효심의 모니터링 서비스를 지금 시작해보세요')}</div>
                <button type="button" className="btn_nor md" onClick={props.onClose}>
                    {t('확인')}
                </button>
            </div>
        </section>
    );
};

export default Popup;
