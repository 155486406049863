import React, { useState, useEffect, useRef } from 'react';
import { BsPerson, BsPhone, BsEnvelope, BsPersonVcard, BsLock, BsBuilding, BsGeoAlt } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { AuthMe, upload } from '../lib/MyHttp';
import { Update, Delete } from '../lib/User';
import { useTranslation } from 'react-i18next';

const MypageModify = () => {
    const fileForm = useRef();
    const { t } = useTranslation();
    const [data, setData] = useState({});

    useEffect(() => {
        AuthMe(res => {
            setData(res);
        });
    }, []);

    const onChangeFile = async e => {
        const file = e.target.files[0];
        if (file === undefined) return;

        upload(file, file => {
            data.image = file.location;
            setData({ ...data });
        });
    };

    const clickSave = () => {
        Update(data, res => {
            alert(t('저장이 완료 되었습니다.'));
        });
    };

    const clickDelete = () => {
        if (window.confirm(t('탈퇴 하시겠습니까?'))) {
            Delete([data.member_no], res => {
                localStorage.setItem('access_token', '');
                window.location.href = '/';
            });
        }
    };

    return (
        <main className="admin-contents">
            <h2 className="sub_title">{t('관리자 정보 수정')}</h2>

            <div className="table_box">
                <table className="tbl_join">
                    <caption>{t('회원정보수정')}</caption>
                    <colgroup>
                        <col width="25%" />
                        <col width="85%" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>
                                <label htmlFor="modify_id">
                                    <BsPersonVcard />
                                    {t('아이디')}
                                </label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input type="text" className="text_nor md w100" id="modify_id" value={data.id} disabled />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label htmlFor="modify_pwd">
                                    <BsLock />
                                    {t('비밀번호')}
                                </label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input
                                        type="password"
                                        className="text_nor md w100"
                                        id="modify_pwd"
                                        value={data.pw}
                                        onChange={e => {
                                            data.pw = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label htmlFor="modify_admin">
                                    <BsPerson />
                                    {t('담당자')}
                                </label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input
                                        type="text"
                                        className="text_nor md w100"
                                        id="modify_admin"
                                        value={data.name}
                                        onChange={e => {
                                            data.name = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label htmlFor="modify_phone">
                                    <BsPhone />
                                    {t('연락처')}
                                </label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input
                                        type="text"
                                        className="text_nor md w100"
                                        id="modify_phone"
                                        value={data.phone}
                                        onChange={e => {
                                            data.phone = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label htmlFor="modify_email">
                                    <BsEnvelope />
                                    {t('이메일')}
                                </label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input
                                        type="text"
                                        className="text_nor md w100"
                                        id="modify_email"
                                        value={data.email}
                                        onChange={e => {
                                            data.email = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label htmlFor="modify_email">
                                    <BsEnvelope />
                                    {t('사진')}
                                </label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input type="text" className="text_nor md" value={data.image?.split('/').pop()} disabled={true} />
                                    <input
                                        type="file"
                                        className="text_nor md"
                                        id="member_image"
                                        onChange={onChangeFile}
                                        ref={fileForm}
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                    />

                                    <button
                                        type="button"
                                        className="btn_nor md line"
                                        onClick={() => {
                                            fileForm.current.click();
                                        }}
                                    >
                                        {t('등록')}
                                    </button>
                                    {data.image && (
                                        <button
                                            type="button"
                                            className="btn_nor md line"
                                            onClick={() => {
                                                data.image = '';
                                                setData({ ...data });
                                            }}
                                        >
                                            {t('삭제')}
                                        </button>
                                    )}
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <th>
                                <label htmlFor="modify_company">
                                    <BsBuilding />
                                    {t('기관명')}
                                </label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input
                                        type="text"
                                        className="text_nor md w100"
                                        id="modify_company"
                                        value={data.inst_nm}
                                        onChange={e => {
                                            data.inst_nm = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label htmlFor="modify_addr">
                                    <BsGeoAlt />
                                    {t('주소')}
                                </label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input
                                        type="text"
                                        className="text_nor md w100"
                                        id="modify_addr"
                                        value={data.addr1}
                                        onChange={e => {
                                            data.addr1 = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label htmlFor="modify_addr"></label>
                            </th>
                            <td>
                                <div className="form-box maxW">
                                    <input
                                        type="text"
                                        className="text_nor md w100"
                                        id="modify_addr"
                                        value={data.addr2}
                                        onChange={e => {
                                            data.addr2 = e.target.value;
                                            setData({ ...data });
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="btn_bott_box">
                <a href="javascript:;" className="btn_nor line minW">
                    <Link to="/mypage/view">{t('취소하기')}</Link>
                </a>
                <a href="javascript:;" className="btn_nor black minW" onClick={clickSave}>
                    {t('수정하기')}
                </a>
                <a href="javascript:;" className="btn_nor gray minW" onClick={clickDelete}>
                    {t('탈퇴하기')}
                </a>
            </div>
        </main>
    );
};

export default MypageModify;
