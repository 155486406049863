import { Method, send } from './MyHttp';

/**
 * 정보
 */
export const Get = (device_no, success, fail) => {
    let paramsT = {};

    send(Method.GET, '/device/' + device_no, paramsT, success, fail);
};

/**
 * 리스트
 */
export const GetList = (params, success, fail) => {
    let paramsT = { ...params };

    send(Method.GET, '/device', paramsT, success, fail);
};

/**
 * 등록
 */
export const Create = (data, success, fail) => {
    const paramsT = {
        device_sn: data.device_sn,
        device_dt: data.device_dt,
        manager_name: data.manager_name,
        manager_phone: data.manager_phone,
        addr1: data.addr1,
        addr2: data.addr2,
        set_internet: data.set_internet,
        memo: data.memo,
        image: data.image,
    };

    send(Method.POST, '/device', paramsT, success, fail);
};

/**
 * 업데이트
 */
export const Update = (data, success, fail) => {
    const paramsT = {
        device_no: data.device_no,
        device_sn: data.device_sn,
        device_dt: data.device_dt,
        manager_name: data.manager_name,
        manager_phone: data.manager_phone,
        addr1: data.addr1,
        addr2: data.addr2,
        set_internet: data.set_internet,
        memo: data.memo,
        image: data.image,
    };

    send(Method.PUT, '/device', paramsT, success, fail);
};

/**
 * 삭제
 */
export const Delete = (ids, success, fail) => {
    let paramsT = { ids };

    send(Method.DELETE, '/device', paramsT, success, fail);
};

/**
 * 디바이스 검색
 */
export const SearchDevice = (device_sn, success, fail) => {
    let paramsT = { device_sn };

    send(Method.GET, '/device/search', paramsT, success, fail);
};
