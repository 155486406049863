import create from 'zustand';

const useThemeStore = create(set => ({
    theme: localStorage.getItem('theme'),
    changeTheme: () =>
        set(state => {
            const changvalue = state.theme === 'darken' ? '' : 'darken';
            localStorage.setItem('theme', changvalue);

            return { theme: changvalue };
        }),
}));

export default useThemeStore;
