import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Get, Update, Create, SearchDevice } from '../lib/Device';

import { upload } from '../lib/MyHttp';
import moment from 'moment';
import { formatPhoneNumber } from '../lib/Common';
import DaumAddressSearch from '../components/DaumAddressSearch';

const EquipmentUpload = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { id } = useParams();
    const fileForm = useRef();

    const [data, setData] = useState({ device_sn: '', set_internet: 'Y', device_dt: moment().format('YYYY-MM-DD') });
    const [searchDeviceSn, setSearchDeviceSn] = useState('');

    useEffect(() => {}, []);

    useEffect(() => {
        if (id) {
            Get(id, res => {
                setData(res);
            });
        }
    }, [id]);

    const onChangeFile = async e => {
        const file = e.target.files[0];
        if (file === undefined) return;

        upload(file, file => {
            data.image = file.location;
            setData({ ...data });
        });
    };

    const clickSave = () => {
        if (data.device_no) {
            Update(data, res => {
                alert(t('저장을 완료 하였습니다.'));
                navigate('/equipment/detail/' + data.device_no);
            });
        } else {
            Create(data, res => {
                setData(res);
                alert(t('저장을 완료 하였습니다.'));
                navigate('/equipment/list');
            });
        }
    };

    const clickSearch = () => {
        if (!data.device_sn) return;

        SearchDevice(
            data.device_sn,
            res => {
                if (!res.search) {
                    setSearchDeviceSn(data.device_sn);
                    alert(t('등록 가능한 장비 입니다.'));
                } else {
                    alert(t('이미 등록한 입니다.'));
                    data.device_sn = '';
                    setData({ ...data });
                }
            },
            res => {
                alert(t('등록이 불가능한 장비입니다.'));
            },
        );
    };

    const disabled = !id && (searchDeviceSn === '' || searchDeviceSn !== data.device_sn);
    return (
        <main className="admin-contents">
            <h2 className="sub_title">{t('장비등록')}</h2>

            <table className="tbl_join">
                <caption>{t('장비등록')}</caption>
                <colgroup>
                    <col width="20%" />
                    <col width="80%" />
                </colgroup>
                <tbody>
                    <tr>
                        <th>
                            <label htmlFor="eq_id">{t('장비명')}(ID)</label>
                        </th>
                        <td>
                            <div className="form-box">
                                <input
                                    type="text"
                                    className="text_nor md"
                                    id="eq_id"
                                    placeholder={t('장비명 검색')}
                                    value={data.device_sn}
                                    onChange={e => {
                                        data.device_sn = e.target.value;
                                        setData({ ...data });
                                    }}
                                />
                                <button type="button" className="btn_nor md line" onClick={clickSearch}>
                                    {t('검색')}
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label htmlFor="eq_install">{t('장비설치')}</label>
                        </th>
                        <td>
                            <div className="form-box">
                                <input
                                    type="date"
                                    className="text_nor md w100"
                                    value={data.device_dt ? data.device_dt : ''}
                                    onChange={e => {
                                        data.device_dt = e.target.value;
                                        setData({ ...data });
                                    }}
                                />
                            </div>
                            <div className="form-box">
                                <input type="text" className="text_nor md" value={data.image?.split('/').pop()} disabled={true} />
                                <input
                                    type="file"
                                    className="text_nor md"
                                    id="eq_id"
                                    onChange={onChangeFile}
                                    ref={fileForm}
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                />
                                <button
                                    type="button"
                                    className="btn_nor md line"
                                    onClick={() => {
                                        fileForm.current.click();
                                    }}
                                >
                                    {t('등록')}
                                </button>

                                {data.image && (
                                    <button
                                        type="button"
                                        className="btn_nor md line"
                                        onClick={() => {
                                            data.image = '';
                                            setData({ ...data });
                                        }}
                                    >
                                        {t('삭제')}
                                    </button>
                                )}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label htmlFor="as_charge">{t('설치담당자')}</label>
                        </th>
                        <td>
                            <div className="form-box">
                                <input
                                    type="text"
                                    className="text_nor md w100"
                                    id="as_charge"
                                    placeholder={t('이름을 입력해주세요')}
                                    value={data.manager_name}
                                    onChange={e => {
                                        data.manager_name = e.target.value;
                                        setData({ ...data });
                                    }}
                                />
                                <input
                                    type="text"
                                    className="text_nor md w100"
                                    placeholder={t('휴대전화 번호를 입력해주세요')}
                                    value={formatPhoneNumber(data.manager_phone)}
                                    maxLength={13}
                                    onChange={e => {
                                        data.manager_phone = e.target.value.replace(/-/g, '');
                                        setData({ ...data });
                                    }}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label htmlFor="eq_address">{t('설치주소')}</label>
                        </th>
                        <td>
                            <div className="form-box">
                                <input type="text" className="text_nor md" id="eq_address" value={data.zipcode} placeholder={t('우편번호를 검색하세요')} />
                                <DaumAddressSearch
                                    onSelect={addr => {
                                        data.zipcode = addr.zonecode;
                                        data.addr1 = addr.address;
                                        setData({ ...data });
                                    }}
                                />
                                <input
                                    type="text"
                                    className="text_nor md w100"
                                    placeholder=""
                                    value={data.addr1}
                                    onChange={e => {
                                        data.addr1 = e.target.value;
                                        setData({ ...data });
                                    }}
                                />
                                <input
                                    type="text"
                                    className="text_nor md w100"
                                    placeholder={t('상세주소를 입력하세요')}
                                    value={data.addr2}
                                    onChange={e => {
                                        data.addr2 = e.target.value;
                                        setData({ ...data });
                                    }}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label>{t('인터넷 설치여부')}</label>
                        </th>
                        <td>
                            <div className="f-row gap-lg text">
                                <span>
                                    <label className="f-row gap">
                                        <input
                                            type="radio"
                                            name="install"
                                            className="radio1"
                                            checked={data.set_internet === 'Y'}
                                            onClick={() => {
                                                data.set_internet = 'Y';
                                                setData({ ...data });
                                            }}
                                        />
                                        {t('설치 됨')}
                                    </label>
                                </span>
                                <span>
                                    <label className="f-row gap">
                                        <input
                                            type="radio"
                                            name="install"
                                            className="radio1"
                                            checked={data.set_internet === 'N'}
                                            onClick={() => {
                                                data.set_internet = 'N';
                                                setData({ ...data });
                                            }}
                                        />
                                        {t('안됨')}
                                    </label>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label htmlFor="eq_memo">{t('특이사항')}</label>
                        </th>
                        <td>
                            <div className="form-box">
                                <textarea
                                    className="textarea_nor w100"
                                    placeholder={t('내용을 입력하세요')}
                                    id="eq_memo"
                                    value={data.memo}
                                    onChange={e => {
                                        data.memo = e.target.value;
                                        setData({ ...data });
                                    }}
                                ></textarea>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="btn_bott_box">
                <a href="/equipment/list" className="btn_nor line">
                    {t('취소하기')}
                </a>
                <a href="javascript:;" className={disabled ? 'btn_nor gray' : 'btn_nor black'} onClick={clickSave} disabled={disabled}>
                    {t('등록하기')}
                </a>
            </div>
        </main>
    );
};

export default EquipmentUpload;
